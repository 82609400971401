import styles from './OverlayCramerInvestingGuide.module.css';
import Overlay from './Overlay.js';
import ArticleTitle from './ArticleTitle.js';
import Background from './Background.js';
import EmailCapture from './EmailCapture.js';
import OverlayCramerInvestingGuideHeader from './OverlayCramerInvestingGuideHeader.js';
import { useState } from 'react';
import Theme from './Theme.js';

const OverlayCramerInvestingGuide = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  return (
    <Theme>
      <Overlay>
        <div className={styles.overlayCramerInvestingGuideWrapper}>
          <div className={styles.overlayCramerInvestingGuideBackgroundWrapper}>
            <Background />
          </div>

          <OverlayCramerInvestingGuideHeader />

          {!isSubscribed && <ArticleTitle />}

          <div
            className={styles.overlayCramerInvestingGuideEmailCaptureContainer}
          >
            <EmailCapture
              showSubscribedSuccessMessage={(value) => setIsSubscribed(value)}
            />
          </div>
        </div>
      </Overlay>
    </Theme>
  );
};

OverlayCramerInvestingGuide.displayName = 'OverlayCramerInvestingGuide';

export default OverlayCramerInvestingGuide;
