function onBedrockSignInUser(e) {
  if (__DEV__) {
    console.log('Web.Zephr:event: onBedrockSignInUser', e);
  }

  const {
    detail: { signInUser },
  } = e;
  if (!signInUser) {
    console.error(
      'Web.Zephr:Error: onBedrockSignInUser event requires signInUser boolean to be explicitly passed.',
    );
  }

  if (signInUser === false) {
    return;
  }

  return window.dispatchEvent(new CustomEvent('onzephrunmountalloutcomes'));
}

window.addEventListener('onbedrocksigninuser', onBedrockSignInUser);

export default onBedrockSignInUser;
