import bubbleEventToParentIframe from '../utils/bubbleEventToParentPage.js';
import shouldBubbleEventToParentIframe from '../utils/shouldBubbleEventToParentPage.js';

// Each environment fronted by Akamai ("pub" must be in the URL) proxies all /zephr/*
// traffic to that environments designated Zephr origin
const ZEPHR_ANALYTICS_TOP_PAGE_PROXY_PATH =
  '/zephr/public/rule-outcomes/v1/conversions';

async function onZephrAnalyticsRuleOutcomeConversion(e) {
  if (shouldBubbleEventToParentIframe()) {
    if (__DEV__) {
      console.log(
        'Web.Zephr:event:bubble: onZephrAnalyticsRuleOutcomeConversion',
      );
    }
    return bubbleEventToParentIframe(e);
  }

  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrAnalyticsRuleOutcomeConversion', e);
  }

  const { detail: { outcomeId, outcomeSlug, conversion } = {} } = e;
  if (!outcomeId || !outcomeSlug || !conversion) {
    console.error(
      'Web.Zephr:Error: onZephrAnalyticsRuleOutcomeConversion event requires a outcomeId, outcomeSlug, and conversion arguments. Received:',
      e,
    );
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      conversion,
      ruleId: outcomeId,
      outcomeId: outcomeSlug,
    }),
  };

  if (__DEV__) {
    // For local development mock Zephr analytics callback due to /zephr/* proxy setup
    // only being available in long lived environments (e.g., production, stg01, etc)
    console.info(
      `Web.Zephr:onZephrAnalyticsRuleOutcomeConversion:mock: fetch(${ZEPHR_ANALYTICS_TOP_PAGE_PROXY_PATH}, ${JSON.stringify(
        options,
        null,
        2,
      )})`,
    );
    return;
  }

  try {
    const request = fetch(ZEPHR_ANALYTICS_TOP_PAGE_PROXY_PATH, options);

    if (__TEST__) {
      // Return request for test assertions
      return request;
    }

    const response = await request;

    return response;
  } catch (e) {
    console.error(
      `Web.Zephr:error:onZephrAnalyticsRuleOutcomeConversion: tried to POST conversion data to ${ZEPHR_ANALYTICS_TOP_PAGE_PROXY_PATH}`,
      options,
      e,
    );
  }
}

window.addEventListener(
  'onzephranalyticsruleoutcomeconversion',
  onZephrAnalyticsRuleOutcomeConversion,
);

export default onZephrAnalyticsRuleOutcomeConversion;
