function onZephrShowComponent(e) {
  const {
    detail: { selector, showDelayMilliseconds: showDelayMillisecondsProp } = {},
  } = e;

  const showDelayMilliseconds = parseInt(showDelayMillisecondsProp ?? 0, 10);

  if (typeof showDelayMilliseconds !== 'number') {
    console.error(
      `Web.Zephr:onZephrShowComponent:error: "${showDelayMillisecondsProp}" is an invalid showDelayMilliseconds`,
      e,
    );
    return;
  }

  if (!selector) {
    console.error(
      `Web.Zephr:onZephrShowComponent:error: "${selector}" is an invalid selector`,
      e,
    );
    return;
  }

  const targetElement = document.querySelector(selector);
  if (!targetElement) {
    console.error(
      `Web.Zephr:onZephrShowComponent:error: element not found for query selector "${selector}"`,
      e,
    );
    return;
  }

  if (showDelayMilliseconds > 0) {
    return setTimeout(
      () => (targetElement.style.display = 'block'),
      showDelayMilliseconds,
    );
  }

  targetElement.style.display = 'block';
}

window.addEventListener('onzephrshowcomponent', onZephrShowComponent);

export default onZephrShowComponent;
