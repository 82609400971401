import bubbleEventToParentIframe from '../utils/bubbleEventToParentPage.js';
import shouldBubbleEventToParentIframe from '../utils/shouldBubbleEventToParentPage.js';

function onZephrOutcomeHistoryAppend(e) {
  try {
    if (shouldBubbleEventToParentIframe()) {
      if (__DEV__) {
        console.log('Web.Zephr:event:bubble: onZephrOutcomeHistoryAppend');
      }
      return bubbleEventToParentIframe(e);
    }

    if (__DEV__) {
      console.log('Web.Zephr:event: onZephrOutcomeHistoryAppend', e);
    }

    const {
      detail: { outcomeHistoryEntry },
    } = e;
    if (!outcomeHistoryEntry) {
      console.error(
        'Web.Zephr:Error: onZephrOutcomeHistoryAppend event requires an "outcomeHistoryEntry" argument.',
      );
    }

    const zephrOutcomeHistoryData = localStorage.getItem('zephrOutcomeHistory');
    const zephrOutcomeHistory = zephrOutcomeHistoryData
      ? JSON.parse(zephrOutcomeHistoryData)
      : [];

    zephrOutcomeHistory.push(outcomeHistoryEntry);

    localStorage.setItem(
      'zephrOutcomeHistory',
      JSON.stringify(zephrOutcomeHistory),
    );

    return zephrOutcomeHistory;
  } catch (e) {
    console.error('Web.Zephr:Error: onZephrOutcomeHistoryAppend', e);

    return e;
  }
}

window.addEventListener(
  'onzephroutcomehistoryappend',
  onZephrOutcomeHistoryAppend,
);

export default onZephrOutcomeHistoryAppend;
