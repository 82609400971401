/**
 * `BottomBar` is a React functional component that displays a bottom bar.
 * This bottom bar can contain any child elements passed to it and features a
 * close button that can trigger any function when clicked.
 *
 * @param {Object} props - The properties that define how the bottom bar looks and behaves.
 * @param {Function} props.onClose - A function that is triggered when the close button is clicked. Defaults to an empty function if not provided.
 * @param {React.ReactNode} props.children - The children nodes to be displayed in the bottom bar.
 *
 * @returns {React.Element} The rendered `BottomBar` component.
 */
import styles from './BottomBar.module.css';
import classNames from 'classnames';
import CloseButton from './CloseButton.js';
import PropTypes from 'prop-types';
import GlobalAnalytics from './GlobalAnalytics.js';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

function BottomBar({ children }) {
  const bottomBarClass = classNames(styles.bottomBarDefaults, styles.bottomBar);
  const { minWidth, maxWidth, minHeight, maxHeight } =
    useComponentCampaignDefaultProps();

  const { articleTitle, backgroundColor, backgroundImageSrc } =
    useSearchParamOrDefaultProps();
  const style = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  const creativeStyle = {
    backgroundColor,
    backgroundImage: backgroundImageSrc,
  };

  const centerLayout = articleTitle?.length < 75;

  const flexGroupClass = classNames(styles.bottomBarFlexGroup, {
    [styles.justifyContentCenter]: centerLayout,
  });

  return (
    <>
      <GlobalAnalytics />
      <section
        id="root-component"
        style={style}
        className={bottomBarClass}
        data-testid="BottomBar"
      >
        <aside className={styles.bottomBarCreative} style={creativeStyle}>
          <div className={styles.bottomBarWrapper}>
            <CloseButton />
            <div className={flexGroupClass}>{children}</div>
          </div>
        </aside>
      </section>
    </>
  );
}

BottomBar.displayName = 'BottomBar';

BottomBar.propTypes = {
  children: PropTypes.node,
};

export default BottomBar;
