/**
 * A component for displaying a Top Bar to register for a CNBC Your Money event.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

import Theme from './Theme.js';
import TopBar from './TopBar.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './TopBarYourMoney.module.css';
import onClickClose from '../utils/onClickClose.js';
import ButtonCallToAction from './ButtonCallToAction.js';

const TopBarYourMoney = () => {
  const {
    logoImageSrc,
    logoImageAlt,
    mainText,
    dismissButtonText,
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
    articleLink,
  } = useSearchParamOrDefaultProps();

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  const onClickDismissButton = () => {
    onClickClose(analyticsParamOptions);
  };

  return (
    <Theme>
      <TopBar>
        <figure className={styles.logoContainer}>
          <img
            className={styles.logoImage}
            src={logoImageSrc}
            alt={logoImageAlt}
          />
        </figure>

        <div className={styles.mainText}>{mainText}</div>

        <div className={styles.buttonsContainer}>
          <ButtonCallToAction href={articleLink} />
          <button
            type="button"
            onClick={onClickDismissButton}
            className={styles.dismissButton}
          >
            {dismissButtonText}
          </button>
        </div>
      </TopBar>
    </Theme>
  );
};

TopBarYourMoney.displayName = 'TopBarYourMoney';

export default TopBarYourMoney;
