/**
 * A component for displaying a bottom bar to subscribe to the Stock @ Night Newsletter.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

import BottomBar from './BottomBar.js';
import Theme from './Theme.js';
import BottomBarStockAtNightEmailCapture from './BottomBarStockAtNightEmailCapture.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './BottomBarStockAtNight.module.css';

const BottomBarStockAtNight = () => {
  const { articleTitle, articleSubtitle, logoImageSrc, logoImageAlt } =
    useSearchParamOrDefaultProps();

  return (
    <Theme>
      <BottomBar>
        <div className={styles.wrapper}>
          <figure className={styles.logoWrapper}>
            <img
              className={styles.logoImage}
              src={logoImageSrc}
              alt={logoImageAlt}
            />
          </figure>

          <div className={styles.textWrapper}>
            <div className={styles.title}>{articleTitle}</div>
            <div className={styles.subtitle}>{articleSubtitle}</div>
          </div>

          <BottomBarStockAtNightEmailCapture />
        </div>
      </BottomBar>
    </Theme>
  );
};

BottomBarStockAtNight.displayName = 'BottomBarStockAtNight';

export default BottomBarStockAtNight;
