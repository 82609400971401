import PropTypes from 'prop-types';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import deriveCardsFromProps from '../utils/deriveCardsFromProps.js';
import Card from './Card.js';
import styles from './CardGroup.module.css';

const CardGroup = ({ count }) => {
  const cards = deriveCardsFromProps(useSearchParamOrDefaultProps(), count);

  return (
    <div className={styles.cardGroupWrapper}>
      {cards.map(
        ({
          key,
          showCard,
          cardImageSrc,
          cardImageAlt,
          cardTitle,
          cardSubtitle,
          cardTitleColor,
          cardSubtitleColor,
        }) => (
          <Card
            key={key}
            showCard={showCard}
            cardImageSrc={cardImageSrc}
            cardImageAlt={cardImageAlt}
            cardTitle={cardTitle}
            cardSubtitle={cardSubtitle}
            cardTitleColor={cardTitleColor}
            cardSubtitleColor={cardSubtitleColor}
          />
        ),
      )}
    </div>
  );
};

CardGroup.displayName = 'CardGroup';

CardGroup.propTypes = {
  count: PropTypes.number,
};

CardGroup.defaultProps = {
  count: 3,
};

export default CardGroup;
