import styles from './Page.module.css';
import PropTypes from 'prop-types';
import GlobalAnalytics from './GlobalAnalytics.js';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';

function Page({ children }) {
  const { minWidth, maxWidth, minHeight, maxHeight } =
    useComponentCampaignDefaultProps();

  const style = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  return (
    <>
      <GlobalAnalytics />
      <section
        id="root-component"
        style={style}
        className={styles.Page}
        data-testid="Overlay"
      >
        {children}
      </section>
    </>
  );
}

Page.displayName = 'Page';

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
