import styles from './TermsOfService.module.css';
import { useParams } from 'react-router-dom';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';

const TermsOfService = ({ isChecked, setIsChecked }) => {
  const { tosTextColor, tosCheckBox = 'true' } = useSearchParamOrDefaultProps();
  const { componentName } = useParams();
  const componentNameTermsOfServiceMap = {
    InlineWarrenBuffet: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with the{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        . Additionally, you agree to receive general marketing from CNBC.
      </>
    ),
    OverlayCramerInvestingGuide: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
    OverlayMadMoneyCramer: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
    OverlayProNews: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
    PageTheExchange: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
    OverlayProPersonalFinance: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
  };

  const termsOfServiceMarkup = componentNameTermsOfServiceMap[componentName];

  return (
    <div className={styles.termsOfServiceWrapper}>
      {tosCheckBox === 'true' && (
        <input
          type="checkbox"
          checked={isChecked}
          className={styles.termsOfServiceCheckbox}
          onChange={() => setIsChecked(!isChecked)}
        />
      )}
      <label
        className={styles.termsOfServiceLabel}
        style={{ color: tosTextColor }}
      >
        <div>{termsOfServiceMarkup}</div>
      </label>
    </div>
  );
};

TermsOfService.displayName = 'TermsOfService';

TermsOfService.defaultProps = {
  isChecked: false,
  setIsChecked: () => {},
};

TermsOfService.propTypes = {
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func,
};

export default TermsOfService;
