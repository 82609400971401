import { useLayoutEffect } from 'react';

function useMockWindowBouncex() {
  useLayoutEffect(() => {
    window.bouncex = {
      campaigns: null,
    };
  });
}

export default useMockWindowBouncex;
