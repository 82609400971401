import deriveIframeOriginFromRootPageOrigin from '../utils/deriveIframeOriginFromRootPageOrigin.js';
import deriveRootLayoutCssTextFromProps from '../utils/deriveRootLayoutCssTextFromProps.js';
import webZephrIframePropsMergeTopPageMetadata from '../utils/webZephrIframePropsMergeTopPageMetadata.js';
import getOutcomeEntrypointSelector from '../utils/getOutcomeEntrypointSelector.js';
import deriveBaseComponentNameFromComponentName from '../utils/deriveBaseComponentNameFromComponentName.js';

const RENDER_INLINE_CAMPAIGN_AFTER_PARAGRAPH_NUMBER = 8;

async function onZephrInitializeComponent(e) {
  const { detail = {} } = e;

  const { urlSearchParams: urlSearchParamsProp, componentName } = detail;
  const { product } = urlSearchParamsProp;

  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrInitializeComponent', e);
  }

  if (!componentName) {
    return console.error('Web.Zephr:Error: componentName is required', detail);
  }

  const outcomeEntrypointSelector = getOutcomeEntrypointSelector(
    componentName,
    product,
  );

  const isInlineComponent = /^inline/i.test(componentName);
  const isGateComponent = /^gate/i.test(componentName);
  const isTopBarComponent = /^topbar/i.test(componentName);
  const isPageComponent = /^page/i.test(componentName);

  function getTargetElement() {
    if (isInlineComponent) {
      const targetElement = document.querySelectorAll(
        outcomeEntrypointSelector,
      )[RENDER_INLINE_CAMPAIGN_AFTER_PARAGRAPH_NUMBER];

      return targetElement;
    } else if (isGateComponent) {
      const targetElement = [
        ...document.querySelectorAll(outcomeEntrypointSelector),
      ].pop();

      return targetElement;
    }

    const targetElement = document.querySelector(outcomeEntrypointSelector);

    if (!targetElement) {
      console.warn(
        `Web.Zephr:Warn: onZephrInitializeComponent event target element document.querySelector('${outcomeEntrypointSelector}') does not exist.`,
        detail,
      );
    }

    return targetElement;
  }

  if (!getTargetElement()) {
    return;
  }

  const {
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    position,
    bottom,
    top,
    left,
    right,
    display = 'none',
    trigger: triggerProp = null,
    intent = null,
    showDelayMilliseconds,

    ...webZephrIframeProps
  } = urlSearchParamsProp;

  const iframe = document.createElement('iframe');
  iframe.allowTransparency = true;

  const layoutProps = {
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    position,
    bottom,
    top,
    left,
    right,
    display,
  };

  iframe.style.cssText = deriveRootLayoutCssTextFromProps(layoutProps);

  // Used by integration.css
  iframe.className = `zephrIframeOutcome ${componentName}`;

  iframe.sandbox = [
    'allow-downloads', // download pdf
    'allow-forms', // submit email
    'allow-scripts', // render app
    'allow-top-navigation', // navigate to landing pages
    'allow-same-origin', // allow access to cookies and local storage for analytics $t
    'allow-popups', // allow popups links that use _blank
  ].join(' ');

  const origin = deriveIframeOriginFromRootPageOrigin();

  const webZephrIframeUrlSearchParams = new URLSearchParams(
    webZephrIframePropsMergeTopPageMetadata(webZephrIframeProps),
  );

  const src = `${origin}/components/${componentName}?${webZephrIframeUrlSearchParams.toString()}`;
  const selector = `[src^="${src}"]`;

  // Defensive
  // Since configurations are passed via URL parameters and we can use the a src attribute selector
  // to determine if an outcome is already on the page. There is an ongoing issue where onBedrockSignInUser or
  // onBedrockSignOutUser trigger onZephrUnmountAllOutcomes and teardown not happening cleanly that is believed to be due
  // to the wait for BounceX to load. Once the BounceX wait code is no longer necessary,
  // this defensive check can likely be removed.
  // See issue for more details and conversation: https://nbcnewsdigital.atlassian.net/browse/NGAR-826
  const shouldSkipDuplicateInitializeComponent = Boolean(
    document.querySelector(selector),
  );
  if (shouldSkipDuplicateInitializeComponent) {
    if (__PROD__ || __DEV__) {
      console.warn(
        `Web.Zephr:onZephrInitializeComponent: outcome matching selector '${selector}' is already on the page skipping initialization`,
      );
    }

    return;
  }

  iframe.src = src;

  const targetElement = getTargetElement();
  if (isInlineComponent || isGateComponent) {
    targetElement.insertAdjacentElement('afterend', iframe);
  } else if (isTopBarComponent) {
    targetElement.prepend(iframe);
  } else {
    targetElement.appendChild(iframe);
  }

  const trigger = triggerProp || intent; // backward-compatible with intent

  const options = {
    detail: {
      selector,
      showDelayMilliseconds,
    },
  };

  const eventType =
    trigger === 'exit' ? 'zephrobserveexittrigger' : 'onzephrshowcomponent';

  const event = new CustomEvent(eventType, options);

  return window.dispatchEvent(event);
}

window.addEventListener(
  'onzephrinitializecomponent',
  onZephrInitializeComponent,
);

export default onZephrInitializeComponent;
