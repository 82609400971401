import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './OverlayCramerInvestingGuideHeader.module.css';

const OverlayCramerInvestingGuideHeader = () => {
  const {
    bandAuthorName,
    bandGuideTitle,
    bandBackgroundColor: backgroundColor,
    bandFontColor: color,
  } = useSearchParamOrDefaultProps();

  return (
    <div className={styles.bandWrapper} style={{ backgroundColor }}>
      <div className={styles.bandTitle} style={{ color }}>
        {bandAuthorName}
        <span>{bandGuideTitle}</span>
      </div>
    </div>
  );
};

OverlayCramerInvestingGuideHeader.displayName =
  'OverlayCramerInvestingGuideHeader';

export default OverlayCramerInvestingGuideHeader;
