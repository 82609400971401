import React from 'react';
import PropTypes from 'prop-types';

const IconInvestingTools = ({ style }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path d="M3.99995 9.3999H3.19995V13.3999H3.99995V9.3999Z" fill="#E6F7EC" />
    <path d="M7.1999 10.2H6.3999V14.2H7.1999V10.2Z" fill="#E6F7EC" />
    <path d="M10.4001 8.99988H9.6001V12.3999H10.4001V8.99988Z" fill="#E6F7EC" />
    <path d="M13.6 11H12.8V14.2H13.6V11Z" fill="#E6F7EC" />
    <path d="M16.8 8.59985H16V12.7999H16.8V8.59985Z" fill="#E6F7EC" />
    <path
      d="M19.2001 3.40002H0.800049V5.40002H19.2001V3.40002Z"
      fill="#E6F7EC"
    />
    <path
      d="M3.1979 14.9999H3.9979V14.1999H4.7999V8.59993H3.9979V7.79993H3.1979V8.59993H2.3999V14.1999H3.1979V14.9999ZM3.1999 9.39993H3.9999V13.3999H3.1999V9.39993Z"
      fill="#047E2E"
    />
    <path
      d="M6.3981 15.8H7.1981V15H8.0001V9.39998H7.1981V8.59998H6.3981V9.39998H5.6001V15H6.3981V15.8ZM6.4001 10.2H7.2001V14.2H6.4001V10.2Z"
      fill="#047E2E"
    />
    <path
      d="M9.59805 13.9999H10.398V13.1999H11.2V8.1999H10.398V7.3999H9.59805V8.1999H8.80005V13.1999H9.59805V13.9999ZM9.60005 8.9999H10.4V12.3999H9.60005V8.9999Z"
      fill="#047E2E"
    />
    <path
      d="M12.798 15.8H13.598V15H14.4V10.2H13.598V9.40002H12.798V10.2H12V15H12.798V15.8ZM12.8 11H13.6V14.2H12.8V11Z"
      fill="#047E2E"
    />
    <path
      d="M15.998 14.3999H16.7979V13.5999H17.5999V7.79988H16.7979V6.99988H15.998V7.79988H15.2V13.5999H15.998V14.3999ZM16 8.59988H16.8V12.7999H16V8.59988Z"
      fill="#047E2E"
    />
    <path
      d="M0 2.59998V17.4H20V2.59998H0ZM19.2 16.6H0.8V6.19998H19.2V16.6ZM19.2 5.39998H0.8V3.39998H19.2V5.39998Z"
      fill="#047E2E"
    />
    <path
      d="M16.8179 3.99988H15.7739V4.79988H16.8179V3.99988Z"
      fill="#047E2E"
    />
    <path
      d="M18.5579 3.99988H17.5139V4.79988H18.5579V3.99988Z"
      fill="#047E2E"
    />
    <path
      d="M15.0779 3.99988H14.0339V4.79988H15.0779V3.99988Z"
      fill="#047E2E"
    />
    <path
      d="M9.30409 3.99988H1.44409V4.79988H9.30409V3.99988Z"
      fill="#047E2E"
    />
  </svg>
);

export default IconInvestingTools;

IconInvestingTools.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconInvestingTools.defaultProps = {
  style: {},
};
