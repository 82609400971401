import React from 'react';
import PropTypes from 'prop-types';

const IconExpertAccess = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.43994 7.31504V3.83984H13.9199V7.67984H9.43994V7.31504Z"
      fill="#E6F7EC"
    />
    <path
      d="M7.20158 3.35697C7.37758 3.35697 7.51998 3.21457 7.51998 3.03697V2.24017C7.51998 2.06417 7.37758 1.92017 7.20158 1.92017C7.02558 1.92017 6.88318 2.06257 6.88318 2.24017V3.03857C6.88318 3.21297 7.02558 3.35697 7.20158 3.35697Z"
      fill="#047E2E"
    />
    <path
      d="M4.04805 4.16959C4.11045 4.25599 4.20805 4.30079 4.30565 4.30079C4.37125 4.30079 4.43525 4.28159 4.49285 4.23999C4.63525 4.13599 4.66565 3.93599 4.56325 3.79359L4.09605 3.14719C3.99365 3.00479 3.79365 2.97279 3.65125 3.07679C3.50885 3.18079 3.47845 3.38079 3.58085 3.52319L4.04805 4.16959Z"
      fill="#047E2E"
    />
    <path
      d="M2.61283 6.15202L1.85603 5.90562C1.68963 5.85122 1.51043 5.94242 1.45603 6.11042C1.40163 6.27842 1.49283 6.45762 1.66083 6.51202L2.41763 6.75842C2.44963 6.76962 2.48323 6.77442 2.51523 6.77442C2.64963 6.77442 2.77443 6.68802 2.81763 6.55362C2.87043 6.38722 2.77923 6.20642 2.61283 6.15202Z"
      fill="#047E2E"
    />
    <path
      d="M2.41602 9.21104L1.65922 9.45744C1.49282 9.51184 1.40002 9.69264 1.45442 9.85904C1.49922 9.99344 1.62402 10.0798 1.75842 10.0798C1.79042 10.0798 1.82402 10.075 1.85602 10.0638L2.61282 9.81744C2.77922 9.76304 2.87202 9.58224 2.81762 9.41584C2.76162 9.24784 2.58242 9.15664 2.41602 9.21104Z"
      fill="#047E2E"
    />
    <path
      d="M8.64006 13.9199H5.76006C5.58406 13.9199 5.44006 14.0639 5.44006 14.2399C5.44006 14.4159 5.58406 14.5599 5.76006 14.5599H8.64006C8.81606 14.5599 8.96006 14.4159 8.96006 14.2399C8.96006 14.0639 8.81606 13.9199 8.64006 13.9199Z"
      fill="#047E2E"
    />
    <path
      d="M8.32001 15.04H6.08001C5.90401 15.04 5.76001 15.184 5.76001 15.36C5.76001 15.536 5.90401 15.68 6.08001 15.68H8.32001C8.49601 15.68 8.64001 15.536 8.64001 15.36C8.64001 15.184 8.49601 15.04 8.32001 15.04Z"
      fill="#047E2E"
    />
    <path
      d="M14.56 8.32007V3.20007H10.4V2.24007C10.4 1.53447 10.9744 0.960068 11.68 0.960068C12.3856 0.960068 12.96 1.53447 12.96 2.24007C12.96 2.41607 13.104 2.56007 13.28 2.56007C13.456 2.56007 13.6 2.41607 13.6 2.24007C13.6 1.18087 12.7392 0.320068 11.68 0.320068C10.6208 0.320068 9.76002 1.18087 9.76002 2.24007V3.20007H8.80002V4.52167C8.31682 4.28967 7.77282 4.16007 7.20002 4.16007C5.16802 4.16007 3.52002 5.78887 3.52002 7.78407C3.52002 9.00967 3.87042 9.49927 4.55362 10.4801C4.71202 10.6897 4.86882 10.9361 5.04482 11.1985C5.41282 11.7585 5.41282 12.3889 5.41282 12.7569V12.7921V13.1249C5.41282 13.2993 5.55362 13.4401 5.72802 13.4401H8.69122C8.86562 13.4401 9.00642 13.2993 9.00642 13.1249V12.7921V12.7569C9.00642 12.3889 9.00642 11.7585 9.37442 11.1985C9.55042 10.9185 9.72482 10.6913 9.86562 10.4801C10.4256 9.65607 10.76 9.17767 10.8544 8.31847H14.56V8.32007ZM9.44002 7.31527V3.84007H13.92V7.68007H9.44002V7.31527ZM9.37442 10.0609L9.33122 10.1249C9.28162 10.2001 9.22722 10.2769 9.16962 10.3585C9.06722 10.5025 8.95202 10.6657 8.83522 10.8529C8.36642 11.5713 8.36642 12.3425 8.36642 12.7569V12.7921V12.8001H6.05282V12.7921V12.7569C6.05282 12.3409 6.05282 11.5681 5.57762 10.8433L5.47202 10.6849C5.33602 10.4801 5.20642 10.2849 5.07362 10.1057L5.05922 10.0849C4.40482 9.14727 4.16002 8.79687 4.16002 7.78407C4.16002 6.13927 5.52322 4.80007 7.20002 4.80007C7.78722 4.80007 8.33442 4.96487 8.80002 5.24967V7.19047V8.32007H10.208C10.1232 8.95847 9.86402 9.34087 9.37442 10.0609Z"
      fill="#047E2E"
    />
    <path
      d="M11.68 4.80005C11.3264 4.80005 11.04 5.08645 11.04 5.44005C11.04 5.67685 11.1696 5.88005 11.36 5.99205V6.40005C11.36 6.57605 11.504 6.72005 11.68 6.72005C11.856 6.72005 12 6.57605 12 6.40005V5.99205C12.1904 5.88165 12.32 5.67685 12.32 5.44005C12.32 5.08645 12.0336 4.80005 11.68 4.80005Z"
      fill="#047E2E"
    />
  </svg>
);

export default IconExpertAccess;

IconExpertAccess.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconExpertAccess.defaultProps = {
  style: {},
};
