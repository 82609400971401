/**
 * `TopBar` is a React functional component that displays a top bar.
 * This top bar can contain any child elements passed to it and features a
 * close button that can trigger any function when clicked.
 *
 * @param {Object} props - The properties that define how the top bar looks and behaves.
 * @param {Function} props.onClose - A function that is triggered when the close button is clicked. Defaults to an empty function if not provided.
 * @param {React.ReactNode} props.children - The children nodes to be displayed in the top bar.
 *
 * @returns {React.Element} The rendered `TopBar` component.
 */

import CloseButton from './CloseButton.js';
import styles from './TopBar.module.css';
import PropTypes from 'prop-types';
import GlobalAnalytics from './GlobalAnalytics.js';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';

function TopBar({ children }) {
  const { minWidth, maxWidth, minHeight, maxHeight, showCloseButton } =
    useComponentCampaignDefaultProps();

  const style = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  return (
    <>
      <GlobalAnalytics />
      <div
        id="root-component"
        style={style}
        className={styles.container}
        data-testid="top-bar"
      >
        <div className={styles.contentLayout}>{children}</div>
        {showCloseButton && <CloseButton />}
      </div>
    </>
  );
}

TopBar.displayName = 'TopBar';

TopBar.propTypes = {
  children: PropTypes.node,
};

export default TopBar;
