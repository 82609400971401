import BottomBar from './BottomBar.js';
import Logo from './Logo.js';
import ArticleTitle from './ArticleTitle.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import Theme from './Theme.js';
import { useState, useEffect } from 'react';
import ArticleSubtitle from './ArticleSubtitle.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import IconArrow from './IconArrow.js';
import styles from './BottomBarExpandable.module.css';
import classNames from 'classnames';
import $t from '../utils/analyticsEventBus.js';

/**
 * This component renders a BottomBar with an expandable section. The bar initially displays in a
 * compact form with an arrow icon to indicate expansion capability. When the arrow is clicked,
 * the BottomBar expands to reveal additional content, including an expanded article title and
 * subtitle, which are dynamically obtained from `useSearchParamOrDefaultProps` hook.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

const BottomBarExpandable = () => {
  const {
    articleTitleExpanded,
    articleSubTitleExpanded,
    iconArrowBackgroundColor,
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
    articleLink,
  } = useSearchParamOrDefaultProps();

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);

    $t('track', 'phx_zephr_expand', {
      ...analyticsParamOptions,
      action: 'moduleexpand',
    });
  };

  useEffect(() => {
    $t('register', 'phx_zephr_expand', {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  const arrowDirection = 'up'; // Can expose this or not depending on the use case
  const iconArrowClassNames = classNames(styles.iconArrow, {
    [styles.iconArrowDown]: arrowDirection === 'down',
    [styles.iconArrowLeft]: arrowDirection === 'left',
    [styles.iconArrowRight]: arrowDirection === 'right',
  });

  const style = {
    backgroundColor: iconArrowBackgroundColor,
  };

  return (
    <Theme>
      <BottomBar>
        {isExpanded ? (
          <>
            <Logo />
            <div className={styles.bottomBarExpandedArticleTitleWrapper}>
              <ArticleSubtitle>{articleTitleExpanded}</ArticleSubtitle>
              <ArticleTitle>{articleSubTitleExpanded}</ArticleTitle>
            </div>
            <ButtonCallToAction href={articleLink} />
          </>
        ) : (
          <>
            <div className={styles.iconArrowWrapper}>
              <div
                data-testid="icon-arrow"
                className={iconArrowClassNames}
                style={style}
                onClick={handleExpand}
              >
                <IconArrow />
              </div>
            </div>
            <div
              onClick={handleExpand}
              className={styles.bottomBarUnexpandArticleTitleWrapper}
            >
              <ArticleTitle />
            </div>
          </>
        )}
      </BottomBar>
    </Theme>
  );
};

BottomBarExpandable.displayName = 'BottomBarExpandable';

export default BottomBarExpandable;
