function TestMockButtonSignOutUser() {
  const onBedrockSignOutUserEvent = new CustomEvent('onbedrocksignoutuser', {
    detail: {
      signOutUser: true,
    },
  });

  return (
    <button onClick={() => dispatchEvent(onBedrockSignOutUserEvent)}>
      Sign Out
    </button>
  );
}

export default TestMockButtonSignOutUser;
