function TestMockButtonSignInUser() {
  const onBedrockSignInUserEvent = new CustomEvent('onbedrocksigninuser', {
    detail: {
      signInUser: true,
    },
  });

  return (
    <button onClick={() => dispatchEvent(onBedrockSignInUserEvent)}>
      Sign In
    </button>
  );
}

export default TestMockButtonSignInUser;
