import PropTypes from 'prop-types';

function PrettyPrintJsonPage({ data }) {
  return (
    <pre
      style={{
        backgroundColor: '#f5f5f5',
        padding: '10px',
        width: 'fit-content',
      }}
    >
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
}

PrettyPrintJsonPage.defaultProps = {
  data: {},
};

PrettyPrintJsonPage.propTypes = {
  data: PropTypes.object,
};

export default PrettyPrintJsonPage;
