function analyticsZephrRuleOutcomeHandler(analyticsEventBusMessage) {
  const { eventType: conversion, options: { outcomeId, outcomeSlug } = {} } =
    analyticsEventBusMessage;

  if (__DEV__) {
    console.info(
      'Web.Zephr:analytics:zephr-rule-outcomes:',
      analyticsEventBusMessage,
    );
  }

  const shouldAnalyticsPostbackBail = !outcomeId || !outcomeSlug;
  if (shouldAnalyticsPostbackBail) {
    if (__DEV__ || __PROD__) {
      if (!outcomeId) {
        console.error(
          'Web.Zephr:analyticsZephrRuleOutcomeHandler: missing required parameter outcomeId. Please contact marketing to populate the analyticsOutcomeId variable in the Zephr Console for the outcome.',
          analyticsEventBusMessage,
        );
      }

      if (!outcomeSlug) {
        console.error(
          'Web.Zephr:analyticsZephrRuleOutcomeHandler: missing required parameter outcomeSlug. analyticsOutcomeSlug is derived from the window.Zephr.outcomes object on the top page after Adobe Launch code executes Zephr.run(...) call and the deriveOutcomeSlugFromWindowZephr lookup has failed. This is likely due to an empty or duplicate analyticsOutcomeId being set in the Zephr Console. analyticsOutcomeId is a required field for each outcome and must be unique across all features/journeys, please contact marketing to have this field correctly populated. Tracking for this outcome inside of Zephr Analytics will not populate until this is done.',
          analyticsEventBusMessage,
        );
      }
    }

    return;
  }

  const onZephrAnalyticsRuleOutcomeConversionEvent = new CustomEvent(
    'onzephranalyticsruleoutcomeconversion',
    {
      detail: {
        conversion,
        outcomeId,
        outcomeSlug,
      },
    },
  );

  window.dispatchEvent(onZephrAnalyticsRuleOutcomeConversionEvent);
}

export default analyticsZephrRuleOutcomeHandler;
