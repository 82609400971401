import outcomeEntrypointSelectorData from './getOutcomeEntrypointSelector.json';

function testGetOutcomeEntrypointGateSelectors() {
  const {
    Gate: {
      Video: {
        registeredOnly: GateVideoRegisteredOnlySelector,
        pro: GateVideoProSelector,
        investingClub: GateVideoInvestingClubSelector,
      },
      Article: {
        registeredOnly: GateArticleRegisteredOnlySelector,
        pro: GateArticleProSelector,
        investingClub: GateArticleInvestingClubSelector,
      },
    },
  } = outcomeEntrypointSelectorData;
  const gateClassNames = [
    GateVideoRegisteredOnlySelector,
    GateArticleRegisteredOnlySelector,
    GateVideoProSelector,
    GateVideoInvestingClubSelector,
    GateArticleProSelector,
    GateArticleInvestingClubSelector,
  ]
    .join(' ')
    .replace(/\./g, '');

  return gateClassNames;
}

export default testGetOutcomeEntrypointGateSelectors;
