/**
 * `BottomBarStockAtNightTermsOfService` is a functional React component that renders a checkbox with
 * the terms of service for an email capture form.
 * @returns {JSX.Element} The rendered JSX element.
 */

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './BottomBarStockAtNightTermsOfService.module.css';

const BottomBarStockAtNightTermsOfService = ({ isChecked, setIsChecked }) => {
  const { componentName } = useParams();
  const termsOfServiceMarkups = {
    BottomBarStockAtNight: (
      <>
        By subscribing to this newsletter, you agree to our{' '}
        <a
          href="https://www.cnbc.com/nbcuniversal-terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="https://www.nbcuniversal.com/privacy?intake=CNBC"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        , together with this{' '}
        <a
          href="https://www.cnbc.com/investingclub/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          disclaimer
        </a>
        , and agree to receive general marketing emails from CNBC.
      </>
    ),
  };

  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        checked={isChecked}
        className={styles.checkbox}
        onChange={() => setIsChecked(!isChecked)}
      />
      <label className={styles.label}>
        {termsOfServiceMarkups[componentName]}
      </label>
    </div>
  );
};

BottomBarStockAtNightTermsOfService.displayName =
  'BottomBarStockAtNightTermsOfService';

BottomBarStockAtNightTermsOfService.defaultProps = {
  isChecked: false,
  setIsChecked: () => {},
};

BottomBarStockAtNightTermsOfService.propTypes = {
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func,
};

export default BottomBarStockAtNightTermsOfService;
