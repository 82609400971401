import { useRssFeedContext } from './RssFeedDataComponent.js';
import Link from './Link.js';
import urlJoinParameters from '../utils/urlJoinParameters.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const TopRssArticleLink = () => {
  const { loading, error, headlines, link } = useRssFeedContext();
  const { __source, tpcc, rssFeedArticleLinkColor } =
    useSearchParamOrDefaultProps();

  const to = urlJoinParameters(link, { __source, tpcc });

  return (
    <>
      {error && <div>{error}</div>}
      {loading && <div>loading...</div>}
      {!loading && headlines && to && (
        <Link to={to} color={rssFeedArticleLinkColor}>
          {headlines}
        </Link>
      )}
    </>
  );
};

TopRssArticleLink.displayName = 'TopRssArticleLink';

export default TopRssArticleLink;
