import { useLayoutEffect } from 'react';
import useBodyHeight from '../hooks/useBodyHeight.js';
import getRootSelector from '../utils/getRootSelector.js';

function ComponentResizer() {
  const height = useBodyHeight();

  useLayoutEffect(() => {
    function resize() {
      if (!height) {
        return;
      }

      const event = new CustomEvent('onzephrresize', {
        detail: {
          selector: getRootSelector(),
          heightPixels: height,
        },
      });

      window.dispatchEvent(event);
    }

    resize();
  }, [height]);

  return null;
}

export default ComponentResizer;
