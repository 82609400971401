/**
 * `CallToActionButton` is a functional React component that renders a button
 * which can be used as a call to action (CTA).
 *
 * The component utilizes the `useSearchParamOrDefaultProps` hook to extract
 * and prioritize certain properties either from search parameters or from
 * the provided default options.
 *
 * The rendered button is wrapped within a `Link` component, which enables navigation
 * to a specified `articleLink`, appended with analytics parameters.
 *
 * The component can be styled using the provided `classNames` prop and the default
 * styles from the 'CallToActionButton.module.css' file. Additionally, an optional icon
 * can be provided to be rendered alongside the button's label.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} [props.icon=null] - An optional icon to display alongside the button label.
 * @param {React.ReactNode} [props.children=null] - Child elements or components to be rendered as the button label.
 *
 * @returns {JSX.Element} - The rendered call to action button component wrapped in a link.
 */

import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ButtonCallToAction.module.css';
import PropTypes from 'prop-types';
import Link from './Link.js';
import urlJoinParameters from '../utils/urlJoinParameters.js';

const ButtonCallToAction = ({
  icon,
  text: textProp,
  href,
  color: colorProp,
}) => {
  const {
    callToActionButtonText: textParam,
    __source,
    tpcc,
    callToActionButtonFontFamily: fontFamily,
    callToActionButtonTextColor: color,
    callToActionButtonBackgroundColor: backgroundColor,
    callToActionButtonBorderRadius: borderRadius,
    callToActionButtonBorderWidth: borderWidth,
    callToActionButtonBorderStyle: borderStyle,
    callToActionButtonBorderColor: borderColor,
  } = useSearchParamOrDefaultProps();

  // Parent controlled text prop is more specific than default prop
  // or overwridden default prop so textProp takes priority similar to
  // CSS selector specificity
  const text = textProp || textParam;

  const to = urlJoinParameters(href, {
    __source,
    tpcc,
  });

  return (
    <div className={styles.callToActionButtonWrapper}>
      <Link
        to={to}
        fontFamily={fontFamily}
        color={colorProp || color}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderStyle={borderStyle}
        borderColor={borderColor}
        text={text}
      >
        {icon}
        {text}
      </Link>
    </div>
  );
};

ButtonCallToAction.displayName = 'ButtonCallToAction';

ButtonCallToAction.defaultProps = {
  icon: null,
  children: null,
};

ButtonCallToAction.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  href: PropTypes.string,
  color: PropTypes.string,
};

export default ButtonCallToAction;
