import React, { useEffect } from 'react';
import Theme from './Theme.js';
import Gate from './Gate.js';
import LogoPeacockLandscape from './LogoPeacockLandscape.js';
// hooks and util
import cx from 'classnames';
import $t from '../utils/analyticsEventBus.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
// styles and logos
import styles from './GateArticleCondensed.module.css';
import GateLogo from './GateLogo.js';

/**
 * A component for displaying a Gate for user not allowed to view content.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */
const GateArticleCondensed = () => {
  const {
    backgroundImageSrc,
    theme,
    h2Message,
    h3Message,
    analyticsOutcomeDetail,
    analyticsOutcomeId,
    analyticsOutcomeType,
    analyticsPageId,
    analyticsTrackingId,
    analyticsOutcomeSlug,
  } = useSearchParamOrDefaultProps();

  // auto-send analytics upon user viewing condensed gate
  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('track', 'phx_zephr_load', {
      ...analyticsParamOptions,
      action: 'condensed-gate',
    });
  }, []);

  return (
    <Theme>
      <Gate>
        <div
          className={cx(theme, styles.GateCondensed)}
          style={{ backgroundImage: backgroundImageSrc }}
        >
          <LogoPeacockLandscape
            top={'33px'}
            left={'-137px'}
            strokeColor={'var(--primaryColor)'}
          />
          <header className={styles.topContainer}>
            <GateLogo />
          </header>
          <section className={styles.middleContainer}>
            <div className={styles.message}>
              <h2>{h2Message}</h2>
            </div>
          </section>
          <section className={styles.bottomContainer}>
            <div className={styles.additionalMessage}>
              <h3>{h3Message}</h3>
            </div>
          </section>
        </div>
      </Gate>
    </Theme>
  );
};

GateArticleCondensed.displayName = 'GateArticleCondensed';

export default GateArticleCondensed;
