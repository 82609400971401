import styles from './EmailCapture.module.css';
import { useState, useEffect } from 'react';
import TermsOfService from './TermsOfService.js';
import { validateEmail } from '../utils/validateEmail.js';
import classNames from 'classnames';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';
import getRootSelector from '../utils/getRootSelector.js';
import EmailCaptureInput from './EmailCaptureInput.js';
import EmailCaptureButton from './EmailCaptureButton.js';
import EmailCaptureSuccess from './EmailCaptureSuccess.js';
import useFetchRecaptchaToken from '../hooks/useFetchRecaptchaToken.js';
import { useMultiStepContext } from './MultiStepComponent.js';
import emailCaptureConfig from './EmailCapture.config.json';
import $t from '../utils/analyticsEventBus.js';
import deriveRuntimeEnvironment from '../utils/deriveRuntimeEnvironment.js';
import ButtonDecline from './ButtonDecline.js';

const defaultData = {
  brand: 'cnbc',
  pid: '33', // Partner id
};

const EmailCapture = ({
  defaultEmail,
  onEmailChange,
  goToNextSteps,
  showEmailCaptureButton,
  newsletterSubscriptions,
  showSubscribedSuccessMessage,
  shouldInlineSubmitButton = false,
  showButtonDecline = false,
  analyticsSubmitEventName,
}) => {
  const runtimeEnv = deriveRuntimeEnvironment();
  const { CNBC_REGISTER_BASE_URL, EMAIL_CAPTURE_ENDPOINT } =
    emailCaptureConfig[runtimeEnv];
  const {
    newsletters,
    emailCaptureSource,
    emailCaptureLabel,
    emailCaptureLabelFontColor: color,
    emailCaptureErrorText,
    emailCaptureDelayCloseMilliseconds,
    callToActionButtonDownloadText,
    tos = 'true',
    tosCheckBox = 'true',
    callToActionButtonText,
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  } = useSearchParamOrDefaultProps();

  const fetchRecaptchaToken = useFetchRecaptchaToken();

  const [email, setEmail] = useState(defaultEmail);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [uiError, setUiError] = useState('');

  const { isMultiStep, goToNextStep, currentStep } = useMultiStepContext();
  const isFirstStep = currentStep === 0;

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', analyticsSubmitEventName, {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  const stepNames = {
    0: 'enter email',
    1: 'select newsletter',
  };

  const triggerAnalyticsEmailSignUpEvent = () => {
    const stepName = stepNames[currentStep];
    const action = `${callToActionButtonText}${
      isMultiStep ? `: step ${currentStep + 1} ${stepName}` : ''
    }`;

    $t('track', analyticsSubmitEventName, {
      ...analyticsParamOptions,
      action,
    });
  };

  const onClickCloseEvent = new CustomEvent('onzephrclose', {
    detail: {
      selector: getRootSelector(),
    },
  });

  const delayClose = Number(emailCaptureDelayCloseMilliseconds);

  const handleEmailInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (onEmailChange) {
      onEmailChange(newEmail);
    }
  };

  const isTosRequiredAndUnchecked =
    !isChecked && tosCheckBox === 'true' && tos === 'true';

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid = e.target.checkValidity();

    if (isTosRequiredAndUnchecked) {
      setUiError('Please agree to the terms of service.');
      return;
    }

    if (!isValid) {
      e.target.reportValidity();
      return;
    }

    if (!validateEmail(email)) {
      setUiError('Please enter a valid email address.');
      return;
    }

    if (uiError) setUiError('');

    setIsLoading(true);

    const finalSubscriptions = {
      [newsletters]: true,
      ...newsletterSubscriptions,
    };

    const data = {
      ...defaultData,
      email: email,
      source: emailCaptureSource,
      subscriptions: finalSubscriptions,
    };

    try {
      const recaptchaToken = await fetchRecaptchaToken();
      const url = `${CNBC_REGISTER_BASE_URL}${EMAIL_CAPTURE_ENDPOINT}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'recaptcha-token': recaptchaToken,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseData = await response.json();
        const errorMessage =
          responseData?.user_error_message ?? emailCaptureErrorText;
        console.error(responseData, errorMessage);
        setUiError(errorMessage ?? responseError.message);
        setIsLoading(false);
      }

      triggerAnalyticsEmailSignUpEvent();

      setIsSubscribed(true);
      showSubscribedSuccessMessage(true);
      setIsLoading(false);

      // call the goToNextSteps callback if provided
      if (goToNextSteps) {
        goToNextStep();
      }
      // Some overlays have a delayed close after the user subscribes
      if (!callToActionButtonDownloadText && !goToNextSteps) {
        setTimeout(() => window.dispatchEvent(onClickCloseEvent), delayClose);
      }
    } catch (error) {
      setUiError(error.message ?? emailCaptureErrorText);
      setIsLoading(false);
    }
  };

  const isButtonDisabled = isLoading;

  const emailCaptureLabelClassname = classNames(styles.emailCaptureLabel, {
    [styles.emailCaptureLabelPaddingBottom]: emailCaptureLabel,
  });

  if (isSubscribed) {
    return (
      <div className={styles.emailCaptureContainer}>
        <EmailCaptureSuccess />
      </div>
    );
  }

  return (
    <div className={styles.emailCaptureContainer}>
      <form onSubmit={handleFormSubmit} className={styles.emailCaptureForm}>
        <div className={emailCaptureLabelClassname} style={{ color }}>
          {emailCaptureLabel}
        </div>
        <div className={styles.emailCaptureWrapper}>
          <div className={styles.inputWrapper}>
            {isFirstStep && (
              <EmailCaptureInput
                value={email}
                onChange={handleEmailInputChange}
              />
            )}

            {shouldInlineSubmitButton && showEmailCaptureButton && (
              <EmailCaptureButton
                isInlineButton={true}
                useIconForMobileDevices={true}
                isLoading={isLoading}
                isDisabled={isButtonDisabled}
              />
            )}
          </div>

          {tos === 'true' && isMultiStep && showEmailCaptureButton && (
            <TermsOfService isChecked={isChecked} setIsChecked={setIsChecked} />
          )}

          {!shouldInlineSubmitButton && showEmailCaptureButton && (
            <EmailCaptureButton
              isInlineButton={false}
              useIconForMobileDevices={false}
              isLoading={isLoading}
              isDisabled={isButtonDisabled}
            />
          )}
          {!shouldInlineSubmitButton &&
            showEmailCaptureButton &&
            showButtonDecline && (
              <center>
                <ButtonDecline onClickDecline={goToNextStep} />
              </center>
            )}
        </div>
        {tos === 'true' && !isMultiStep && showEmailCaptureButton && (
          <TermsOfService isChecked={isChecked} setIsChecked={setIsChecked} />
        )}
        {uiError && <div className={styles.emailCaptureError}>{uiError}</div>}
      </form>
    </div>
  );
};

EmailCapture.displayName = 'EmailCapture';

EmailCapture.defaultProps = {
  defaultEmail: '',
  goToNextSteps: false,
  newsletterSubscriptions: {},
  onEmailChange: () => {},
  showEmailCaptureButton: true,
  showSubscribedSuccessMessage: () => {},
  shouldInlineSubmitButton: false,
  showButtonDecline: false,
  analyticsSubmitEventName: 'phx_zephr_email_submit',
};

EmailCapture.propTypes = {
  defaultEmail: PropTypes.string,
  goToNextSteps: PropTypes.bool,
  newsletterSubscriptions: PropTypes.object,
  onEmailChange: PropTypes.func,
  showEmailCaptureButton: PropTypes.bool,
  showButtonDecline: PropTypes.bool,
  showSubscribedSuccessMessage: PropTypes.func,
  shouldInlineSubmitButton: PropTypes.bool,
  analyticsSubmitEventName: PropTypes.string,
};

export default EmailCapture;
