import { Link } from 'react-router-dom';

function HomePage() {
  const campaignComponentNames = [
    'TopBarYourMoney',
    'BottomBarProRSSFeed',
    'BottomBarProTargeting',
    'BottomBarExpandable',
    'BottomBarStockAtNight',
    'OverlayHomeStretch',
    'OverlayApplicationPro',
    'OverlayMadMoneyCramer',
    'OverlayCramerInvestingGuide',
    'OverlayApplicationProRss',
    'OverlayProPersonalFinance',
    'OverlayProNews',
    'OverlayThreeUp',
    'InlineAnimatedPodcast',
    'InlineWarrenBuffet',
    'GateArticleRegisteredOnly',
    'GateVideo',
    'PageTheExchange',
    'GateArticle',
    'GateArticleCondensed',
  ];

  return (
    <div
      className="HomePage"
      data-testid="HomePage"
      style={{ padding: '0 20px' }}
    >
      <h1>Component Page</h1>
      <p>Links to pages that render available components:</p>
      <ul>
        {campaignComponentNames.map((componentName) => (
          <li key={componentName} style={{ fontSize: '30px' }}>
            <Link to={`/components/${componentName}`}>{componentName}</Link>
          </li>
        ))}
      </ul>

      <h1>Test Page</h1>
      <p>
        Links to pages that render available components inside an iframe
        (similar to production):
      </p>
      <ul>
        {campaignComponentNames.map((componentName) => (
          <li key={componentName} style={{ fontSize: '30px' }}>
            <Link to={`/test/${componentName}`}>{componentName}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HomePage;
