function removeAnalyticsPrefixFromKeyNames(options) {
  const prefix = 'analytics';
  return Object.fromEntries(
    Object.entries(options).map(([key, value]) => {
      // Check if the key contains the specified prefix
      if (key.includes(prefix)) {
        const transformedKey = key.replace(new RegExp(`^${prefix}`), '');
        const newKey =
          transformedKey.charAt(0).toLowerCase() + transformedKey.slice(1);
        return [newKey, value];
      }
      // If not, return the key as is
      return [key, value];
    }),
  );
}

export default removeAnalyticsPrefixFromKeyNames;
