import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import ArticleTitleUnderlineVariant from './ArticleTitleUnderlineVariant.js';
import styles from './OverlayHomeStretchBanner.module.css';

const OverlayHomeStretchBanner = () => {
  const {
    bannerTitle,
    bannerTitleVariant,
    bannerTitleFontColor,
    bannerSubtitle,
    bannerSubtitleFontColor: color,
    bannerSubtitleBackgroundColor,
    bannerLeftImageSrc,
    bannerRightImageSrc,
    bannerBackgroundColor: backgroundColor,
  } = useSearchParamOrDefaultProps();

  const bannerSubtitleStyle = {
    color,
    backgroundColor: bannerSubtitleBackgroundColor,
  };

  const hasBackgroundImageSrc = bannerLeftImageSrc && bannerRightImageSrc;

  const backgroundImgStyles = hasBackgroundImageSrc
    ? {
        backgroundImage: `url(${bannerLeftImageSrc}), url(${bannerRightImageSrc})`,
        backgroundColor,
      }
    : {
        backgroundColor,
      };

  return (
    <div className={styles.bannerWrapper} style={backgroundImgStyles}>
      <div className={styles.bannerTitle}>
        <ArticleTitleUnderlineVariant
          title={bannerTitle}
          variant={bannerTitleVariant}
          fontColor={bannerTitleFontColor}
        />
      </div>
      <div className={styles.bannerSubtitle} style={bannerSubtitleStyle}>
        {bannerSubtitle}
      </div>
    </div>
  );
};

OverlayHomeStretchBanner.displayName = 'OverlayHomeStretchBanner';

export default OverlayHomeStretchBanner;
