import deriveClientSideOutcomeIdentifierFromWindowZephr from './deriveOutcomeSlugFromWindowZephr.js';

function webZephrIframePropsMergeTopPageMetadata(webZephrIframeProps) {
  const { analyticsOutcomeId } = webZephrIframeProps;

  // Derive metadata
  const analyticsOutcomeSlug =
    deriveClientSideOutcomeIdentifierFromWindowZephr(analyticsOutcomeId);

  // Merge into params that will be passed Web.Zephr app through <iframe src="...?key1=value1&key2=value2" />
  const newSearchParams = {
    ...webZephrIframeProps,
    analyticsOutcomeSlug,
  };

  return newSearchParams;
}

export default webZephrIframePropsMergeTopPageMetadata;
