import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ButtonDecline.module.css';
import PropTypes from 'prop-types';
import { useMultiStepContext } from './MultiStepComponent.js';
import { useEffect } from 'react';

function ButtonDecline({ onClickDecline, declineText }) {
  const {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
    declineText: declineTextOverride,
  } = useSearchParamOrDefaultProps();

  const { isMultiStep, currentStep } = useMultiStepContext();

  const analyticsAdditionalCampaignDetail = `${analyticsOutcomeDetail} ${
    isMultiStep ? '| Step: ' + (currentStep + 1) : ''
  }`;

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail: analyticsAdditionalCampaignDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', 'phx_zephr_close', {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  function onClick() {
    $t('track', 'phx_zephr_close', {
      ...analyticsParamOptions,
      action: 'close x',
    });

    onClickDecline();
  }

  return (
    <div className={styles.ButtonDecline} onClick={onClick}>
      <span className={styles.declineText}>
        {declineTextOverride || declineText || 'NO THANKS'}
      </span>
    </div>
  );
}

ButtonDecline.displayName = 'ButtonDecline';

ButtonDecline.propTypes = {
  onClickDecline: PropTypes.func.isRequired,
  declineText: PropTypes.string,
};

export default ButtonDecline;
