import PropTypes from 'prop-types';
// import styles from './VideoGateLogoPeacock.module.css';

function VideoGateLogoPeacock({
  strokeColor = 'red',
  strokeOpacity = 0.1,
  objectFit = 'cover',
  position = 'absolute',
  left = 0,
  top = 0,
  width = '100%',
  height = '100%',
  zIndex = 3,
}) {
  return (
    <svg
      width="325"
      height="535"
      viewBox="0 0 325 535"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        objectFit,
        position,
        left,
        top,
        width,
        height,
        zIndex,
      }}
    >
      <path
        d="M373.243 138.949L233.177 459.289L193.103 180.918C187.973 143.117 252.343 119.758 252.343 119.758C246.438 106.673 226.111 106.963 226.111 106.963H182.649H182.552C175.776 38.4368 237.727 -11.3831 304.807 3.83426C370.726 18.567 394.635 90.2922 373.243 138.949Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
      <path
        d="M113.052 563L-160.014 380.779C-229.514 334.255 -298.24 381.651 -312.857 430.599C-334.346 502.809 -286.238 563 -210.639 563H113.052Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
      <path
        d="M148.866 111.325C159.03 40.5692 95.6278 -11.7708 27.192 3.73736C-38.727 18.4701 -62.6359 90.1953 -41.2437 138.852L98.8221 459.289L148.866 111.325Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
      <path
        d="M87.5947 511.92L-46.7601 205.052C-78.2192 133.908 -161.562 125.088 -207.637 166.766C-240.064 196.135 -265.232 276.293 -192.731 324.756L87.5947 511.92Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
      <path
        d="M542.639 563C618.238 563 666.346 502.809 644.857 430.599C630.24 381.651 561.514 334.255 492.014 380.779L218.948 563H542.639Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
      <path
        d="M524.731 324.756C597.232 276.293 572.064 196.232 539.637 166.766C493.562 125.088 410.219 133.908 378.76 205.052L244.405 511.92L524.731 324.756Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
        strokeWidth="0.743818"
      />
    </svg>
  );
}

VideoGateLogoPeacock.propTypes = {
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  objectFit: PropTypes.string,
  position: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  zIndex: PropTypes.number,
};

export default VideoGateLogoPeacock;
