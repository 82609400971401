import { useParams } from 'react-router-dom';
import TopBarYourMoneyDefaultProps from '../components/TopBarYourMoney.defaultProps.json';
import BottomBarProRSSFeedDefaultProps from '../components/BottomBarProRSSFeed.defaultProps.json';
import BottomBarProTargetingDefaultProps from '../components/BottomBarProTargeting.defaultProps.json';
import BottomBarExpandableDefaultProps from '../components/BottomBarExpandable.defaultProps.json';
import BottomBarStockAtNightDefaultProps from '../components/BottomBarStockAtNight.defaultProps.json';
import OverlayHomeStretchDefaultProps from '../components/OverlayHomeStretch.defaultProps.json';
import OverlayApplicationProDefaultProps from '../components/OverlayApplicationPro.defaultProps.json';
import OverlayMadMoneyCramerDefaultProps from '../components/OverlayMadMoneyCramer.defaultProps.json';
import OverlayCramerInvestingGuideDefaultProps from '../components/OverlayCramerInvestingGuide.defaultProps.json';
import OverlayApplicationProRssDefaultProps from '../components/OverlayApplicationProRss.defaultProps.json';
import InlineAnimatedPodcastDefaultProps from '../components/InlineAnimatedPodcast.defaultProps.json';
import InlineWarrenBuffetDefaultProps from '../components/InlineWarrenBuffet.defaultProps.json';
import OverlayProPersonalFinanceDefaultProps from '../components/OverlayProPersonalFinance.defaultProps.json';
import OverlayProNewsDefaultProps from '../components/OverlayProNews.defaultProps.json';
import OverlayThreeUpDefaultProps from '../components/OverlayThreeUp.defaultProps.json';
import GateArticleRegisteredOnlyDefaultProps from '../components/GateArticleRegisteredOnly.defaultProps.json';
import GateArticleDefaultProps from '../components/GateArticle.defaultProps.json';
import GateArticleCondensedDefaultProps from '../components/GateArticleCondensed.defaultProps.json';
import GateVideoDefaultProps from '../components/GateVideo.defaultProps.json';
import PageTheExchangeDefaultProps from '../components/PageTheExchange.defaultProps.json';

function useComponentCampaignDefaultProps() {
  const { componentName } = useParams();

  const defaultProps =
    {
      TopBarYourMoney: TopBarYourMoneyDefaultProps,
      BottomBarProRSSFeed: BottomBarProRSSFeedDefaultProps,
      BottomBarProTargeting: BottomBarProTargetingDefaultProps,
      BottomBarExpandable: BottomBarExpandableDefaultProps,
      BottomBarStockAtNight: BottomBarStockAtNightDefaultProps,
      OverlayHomeStretch: OverlayHomeStretchDefaultProps,
      OverlayApplicationPro: OverlayApplicationProDefaultProps,
      OverlayMadMoneyCramer: OverlayMadMoneyCramerDefaultProps,
      OverlayCramerInvestingGuide: OverlayCramerInvestingGuideDefaultProps,
      OverlayApplicationProRss: OverlayApplicationProRssDefaultProps,
      OverlayProPersonalFinance: OverlayProPersonalFinanceDefaultProps,
      OverlayProNews: OverlayProNewsDefaultProps,
      OverlayThreeUp: OverlayThreeUpDefaultProps,
      InlineAnimatedPodcast: InlineAnimatedPodcastDefaultProps,
      InlineWarrenBuffet: InlineWarrenBuffetDefaultProps,
      GateArticleRegisteredOnly: GateArticleRegisteredOnlyDefaultProps,
      GateArticle: GateArticleDefaultProps,
      GateArticleCondensed: GateArticleCondensedDefaultProps,
      GateVideo: GateVideoDefaultProps,
      PageTheExchange: PageTheExchangeDefaultProps,
    }[componentName] || {};

  return defaultProps;
}

export default useComponentCampaignDefaultProps;
