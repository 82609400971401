import styles from './OverlayProNews.module.css';
import Overlay from './Overlay.js';
import ArticleTitle from './ArticleTitle.js';
import Background from './Background.js';
import Logo from './Logo.js';
import EmailCapture from './EmailCapture.js';
import Theme from './Theme.js';
import React, { useState } from 'react';
import MultiStepComponent from './MultiStepComponent.js';
import ArticleHeadline from './ArticleHeadline.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import NewsletterSelection from './NewsletterSelection.js';

const OverlayProNews = () => {
  const {
    initialArticleHeadline,
    finalArticleHeadline,
    allArticleHeadlineFontColor,
    initialArticleTitle,
    midArticleTitle,
    finalArticleTitle,
    allArticleTitleFontColor,
    finalCallToActionButtonText,
    articleLink,
  } = useSearchParamOrDefaultProps();

  const [newsletterSubscriptions, setNewsletterSubscriptions] = useState({});
  const [email, setEmail] = useState('');

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleCheckboxChange = (id) => {
    setNewsletterSubscriptions((prevState) => {
      const { [id]: shouldRemoveNewsletterSubscription, ...otherProps } =
        prevState;

      if (shouldRemoveNewsletterSubscription) {
        const newState = { ...otherProps };

        return newState;
      }

      const newState = {
        [id]: true,
        ...otherProps,
      };

      return newState;
    });
  };

  const showEmailCaptureButton =
    Object.keys(newsletterSubscriptions).length >= 1;

  return (
    <Theme>
      <Overlay>
        <Background>
          <Logo />
          <MultiStepComponent>
            {/* step 1 */}
            <div className={styles.overlayProNewsWrapper}>
              <ArticleHeadline fontColor={allArticleHeadlineFontColor}>
                {initialArticleHeadline}
              </ArticleHeadline>
              <ArticleTitle fontColor={allArticleTitleFontColor}>
                {initialArticleTitle}
              </ArticleTitle>
              <EmailCapture
                defaultEmail={email}
                goToNextSteps={true}
                onEmailChange={handleEmailChange}
              />
            </div>

            {/* step 2 */}
            <div className={styles.overlayProNewsWrapper}>
              <ArticleTitle fontColor={allArticleTitleFontColor}>
                <div className={styles.overlayProNewsArticleTitle}>
                  {midArticleTitle}
                </div>
              </ArticleTitle>

              <NewsletterSelection
                newsletterSubscriptions={newsletterSubscriptions}
                handleCheckboxChange={handleCheckboxChange}
              />
              <EmailCapture
                defaultEmail={email}
                goToNextSteps={true}
                onEmailChange={handleEmailChange}
                showEmailCaptureButton={showEmailCaptureButton}
                newsletterSubscriptions={newsletterSubscriptions}
              />
            </div>

            {/* step 3 */}
            <div className={styles.overlayProNewsWrapper}>
              <ArticleHeadline fontColor={allArticleHeadlineFontColor}>
                {finalArticleHeadline}
              </ArticleHeadline>
              <ArticleTitle fontColor={allArticleTitleFontColor}>
                {finalArticleTitle}
              </ArticleTitle>
              <ButtonCallToAction
                href={articleLink}
                text={finalCallToActionButtonText}
              />
            </div>
          </MultiStepComponent>
        </Background>
      </Overlay>
    </Theme>
  );
};

OverlayProNews.displayName = 'OverlayProNews';

export default OverlayProNews;
