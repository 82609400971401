import PropTypes from 'prop-types';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';
import GlobalAnalytics from './GlobalAnalytics.js';
import styles from './Gate.module.css';

/**
 * `Gate` is a React functional component that displays an article gate.
 * This gate can contain any child elements passed to it.
 *
 * @param {Object} props - The properties that define how the gate looks and behaves.
 * @param {React.ReactNode} props.children - The children nodes to be displayed in the gate.
 *
 * @returns {React.Element} The rendered `Gate` component.
 */

function Gate({ children }) {
  const { minWidth, maxWidth, minHeight, maxHeight } =
    useComponentCampaignDefaultProps();

  const style = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  return (
    <>
      <GlobalAnalytics />
      <section
        id="root-component"
        style={style}
        className={styles.container}
        data-testid="Gate"
      >
        <div className={styles.childrenContainer}>{children}</div>
      </section>
    </>
  );
}

Gate.displayName = 'Gate';

Gate.propTypes = {
  children: PropTypes.node,
};

export default Gate;
