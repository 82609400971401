import BottomBar from './BottomBar.js';
import Logo from './Logo.js';
import ArticleTitleRssFeed from './ArticleTitleRssFeed.js';
import CallToActionRssFeedButton from './CallToActionRssFeedButton.js';
import RssFeedDataComponent from './RssFeedDataComponent.js';
import Theme from './Theme.js';

/**
 * A component for displaying a BottomBar for Pro RSS Feed.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

const BottomBarProRSSFeed = () => (
  <Theme>
    <BottomBar>
      <Logo />
      <RssFeedDataComponent>
        <ArticleTitleRssFeed />
        <CallToActionRssFeedButton />
      </RssFeedDataComponent>
    </BottomBar>
  </Theme>
);

BottomBarProRSSFeed.displayName = 'BottomBarProRSSFeed';

export default BottomBarProRSSFeed;
