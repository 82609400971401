function onZephrUnmountAllOutcomes(e) {
  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrUnmountAllOutcomes', e);
  }

  const zephrOutcomes = document.querySelectorAll('.zephrIframeOutcome');

  return zephrOutcomes.forEach((element) => element.remove());
}

window.addEventListener('onzephrunmountalloutcomes', onZephrUnmountAllOutcomes);

export default onZephrUnmountAllOutcomes;
