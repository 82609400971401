import PropTypes from 'prop-types';

function IconPortfolio({ fill = 'var(--primaryColor)' }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M8.32007 6.26074C9.56487 6.40794 10.5521 7.39674 10.6993 8.63994H8.32007V6.26074Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M8.14566 9.28003H10.6993C10.5409 10.6288 9.39206 11.68 8.00006 11.68C7.43366 11.68 6.90566 11.5056 6.47046 11.2064L8.14566 9.28003Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M7.68 6.26074V8.28954L6.104 7.01434C6.5216 6.60634 7.0704 6.33274 7.68 6.26074Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M7.54083 8.99996L5.98883 10.784C5.55043 10.3008 5.28003 9.66236 5.28003 8.95996C5.28003 8.42716 5.43523 7.93276 5.70083 7.51196L7.54083 8.99996Z"
        fill={fill}
      />
      <path
        d="M4.63989 8.95998C4.63989 10.816 6.14389 12.32 7.99989 12.32C9.85589 12.32 11.3599 10.816 11.3599 8.95998C11.3599 7.10398 9.85589 5.59998 7.99989 5.59998C6.14389 5.59998 4.63989 7.10398 4.63989 8.95998ZM8.31989 6.26078C9.56469 6.40798 10.5519 7.39678 10.6991 8.63998H8.31989V6.26078ZM8.14549 9.27998H10.6991C10.5407 10.6288 9.39189 11.68 7.99989 11.68C7.43349 11.68 6.90549 11.5056 6.47029 11.2064L8.14549 9.27998ZM7.67989 6.26078V8.28958L6.10389 7.01438C6.52149 6.60638 7.07029 6.33278 7.67989 6.26078ZM7.54069 8.99998L5.98869 10.784C5.55029 10.3008 5.27989 9.66238 5.27989 8.95998C5.27989 8.42718 5.43509 7.93278 5.70069 7.51198L7.54069 8.99998Z"
        fill={fill}
      />
      <path
        d="M15.04 1.91998H5.2896L4.96 1.59998H0.96C0.4304 1.59998 0 2.03038 0 2.55998V14.4H16V4.15998V3.51998V2.87998C16 2.35038 15.5696 1.91998 15.04 1.91998ZM15.36 13.76H0.64V2.55998C0.64 2.38398 0.784 2.23998 0.96 2.23998H4.7008L6.4912 3.97918L6.6768 4.15998H6.72H6.936H15.36V13.76ZM7.36 3.51998H6.936L5.9472 2.55998H15.04C15.216 2.55998 15.36 2.70398 15.36 2.87998V3.51998H7.36Z"
        fill={fill}
      />
    </svg>
  );
}

IconPortfolio.propTypes = {
  fill: PropTypes.string,
};

export default IconPortfolio;
