import bubbleEventToParentIframe from '../utils/bubbleEventToParentPage.js';
import shouldBubbleEventToParentIframe from '../utils/shouldBubbleEventToParentPage.js';

function onZephrClose(e) {
  if (shouldBubbleEventToParentIframe()) {
    if (__DEV__) {
      console.log('Web.Zephr:event:bubble: onZephrClose');
    }
    return bubbleEventToParentIframe(e);
  }

  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrClose', e);
  }

  const {
    detail: { selector },
  } = e;
  if (!selector) {
    console.error(
      'Web.Zephr:Error: onZephrClose event requires a selector to be passed.',
    );
  }

  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    console.error(
      `Web.Zephr:Error: onZephrClose event target element "${selector}" does not exist.`,
    );
    return;
  }

  return targetElement.remove();
}

window.addEventListener('onzephrclose', onZephrClose);

export default onZephrClose;
