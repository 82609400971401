import React from 'react';
import PropTypes from 'prop-types';

const IconTrustPortfolio = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      opacity="0.2"
      d="M8.31995 6.26099C9.56475 6.40819 10.5519 7.39699 10.6991 8.64019H8.31995V6.26099Z"
      fill="#0053CF"
    />
    <path
      opacity="0.2"
      d="M8.14566 9.28003H10.6993C10.5409 10.6288 9.39206 11.68 8.00006 11.68C7.43366 11.68 6.90566 11.5056 6.47046 11.2064L8.14566 9.28003Z"
      fill="#0053CF"
    />
    <path
      opacity="0.2"
      d="M7.68 6.26099V8.28979L6.104 7.01459C6.5216 6.60659 7.0704 6.33299 7.68 6.26099Z"
      fill="#0053CF"
    />
    <path
      opacity="0.2"
      d="M7.54083 9.00021L5.98883 10.7842C5.55043 10.301 5.28003 9.66261 5.28003 8.96021C5.28003 8.42741 5.43523 7.93301 5.70083 7.51221L7.54083 9.00021Z"
      fill="#0053CF"
    />
    <path
      d="M4.64001 8.9601C4.64001 10.8161 6.14401 12.3201 8.00001 12.3201C9.85601 12.3201 11.36 10.8161 11.36 8.9601C11.36 7.1041 9.85601 5.6001 8.00001 5.6001C6.14401 5.6001 4.64001 7.1041 4.64001 8.9601ZM8.32001 6.2609C9.56481 6.4081 10.552 7.3969 10.6992 8.6401H8.32001V6.2609ZM8.14561 9.2801H10.6992C10.5408 10.6289 9.39201 11.6801 8.00001 11.6801C7.43361 11.6801 6.90561 11.5057 6.47041 11.2065L8.14561 9.2801ZM7.68001 6.2609V8.2897L6.10401 7.0145C6.52161 6.6065 7.07041 6.3329 7.68001 6.2609ZM7.54081 9.0001L5.98881 10.7841C5.55041 10.3009 5.28001 9.6625 5.28001 8.9601C5.28001 8.4273 5.43521 7.9329 5.70081 7.5121L7.54081 9.0001Z"
      fill="#0053CF"
    />
    <path
      d="M15.04 1.9201H5.2896L4.96 1.6001H0.96C0.4304 1.6001 0 2.0305 0 2.5601V14.4001H16V4.1601V3.5201V2.8801C16 2.3505 15.5696 1.9201 15.04 1.9201ZM15.36 13.7601H0.64V2.5601C0.64 2.3841 0.784 2.2401 0.96 2.2401H4.7008L6.4912 3.9793L6.6768 4.1601H6.72H6.936H15.36V13.7601ZM7.36 3.5201H6.936L5.9472 2.5601H15.04C15.216 2.5601 15.36 2.7041 15.36 2.8801V3.5201H7.36Z"
      fill="#0053CF"
    />
  </svg>
);

export default IconTrustPortfolio;

IconTrustPortfolio.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconTrustPortfolio.defaultProps = {
  style: {},
};
