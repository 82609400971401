import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './Profile.module.css';

const Profile = () => {
  const { profileImageSrc, profileImageAltText } =
    useSearchParamOrDefaultProps();

  return (
    <div className={styles.profileWrapper}>
      <img
        className={styles.profileImage}
        src={profileImageSrc}
        alt={profileImageAltText}
      />
    </div>
  );
};

Profile.displayName = 'Profile';

export default Profile;
