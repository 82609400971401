import React from 'react';
import PropTypes from 'prop-types';

const IconLivestream = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M11.2 6.23996V9.67996H0.640015V2.95996H13.12V5.27996H12.16C11.6304 5.27996 11.2 5.71036 11.2 6.23996Z"
      fill="#E6F7EC"
    />
    <path d="M15.36 7.19995H11.84V11.76H15.36V7.19995Z" fill="#E6F7EC" />
    <path
      d="M15.04 5.28007H13.76V2.64007C13.76 2.46407 13.616 2.32007 13.44 2.32007H0.32C0.144 2.32007 0 2.46407 0 2.64007V11.2801C0 11.4561 0.144 11.6001 0.32 11.6001H6.56V13.0401H5.12C4.944 13.0401 4.8 13.1841 4.8 13.3601C4.8 13.5361 4.944 13.6801 5.12 13.6801H6.56H7.2H8.64C8.816 13.6801 8.96 13.5361 8.96 13.3601C8.96 13.1841 8.816 13.0401 8.64 13.0401H7.2V11.6001H11.2V12.7201C11.2 13.2497 11.6304 13.6801 12.16 13.6801H15.04C15.5696 13.6801 16 13.2497 16 12.7201V6.24007C16 5.71047 15.5696 5.28007 15.04 5.28007ZM11.2 10.9601H0.64V10.3201H11.2V10.9601ZM11.2 6.24007V9.68007H0.64V2.96007H13.12V5.28007H12.16C11.6304 5.28007 11.2 5.71047 11.2 6.24007ZM15.36 12.7201C15.36 12.8961 15.216 13.0401 15.04 13.0401H12.16C11.984 13.0401 11.84 12.8961 11.84 12.7201V12.4001H15.36V12.7201ZM15.36 11.7601H11.84V7.20007H15.36V11.7601ZM15.36 6.56007H11.84V6.24007C11.84 6.06407 11.984 5.92007 12.16 5.92007H15.04C15.216 5.92007 15.36 6.06407 15.36 6.24007V6.56007Z"
      fill="#047E2E"
    />
    <path
      d="M13.12 10.1808C13.144 10.1808 13.168 10.176 13.192 10.1632L14.2736 9.62239C14.392 9.56319 14.392 9.39519 14.2736 9.33599L13.192 8.79519C13.168 8.78399 13.144 8.77759 13.12 8.77759C13.0368 8.77759 12.96 8.84479 12.96 8.93759V10.0192C12.96 10.1136 13.0368 10.1808 13.12 10.1808Z"
      fill="#047E2E"
    />
    <path
      d="M8.70721 6.19379L6.22241 4.95219C6.19681 4.93939 6.17121 4.93139 6.14561 4.92499C6.13761 4.92339 6.12801 4.92339 6.12001 4.92179C6.10561 4.92019 6.09281 4.91699 6.08001 4.91699C6.07681 4.91699 6.07201 4.91859 6.06881 4.91859C6.06081 4.91859 6.05441 4.92019 6.04641 4.92019C6.02881 4.92179 6.01281 4.92499 5.99681 4.92979C5.98881 4.93139 5.98241 4.93459 5.97441 4.93619C5.95681 4.94259 5.94081 4.95059 5.92481 4.95859C5.92001 4.96179 5.91521 4.96339 5.91041 4.96659C5.88961 4.97939 5.87201 4.99379 5.85441 5.01139C5.84961 5.01619 5.84641 5.02099 5.84321 5.02419C5.83041 5.03699 5.81921 5.05139 5.80961 5.06739C5.80481 5.07379 5.80161 5.08179 5.79841 5.08819C5.79041 5.10419 5.78401 5.12019 5.77761 5.13619C5.77441 5.14419 5.77281 5.15059 5.77121 5.15859C5.76481 5.18579 5.76001 5.21139 5.76001 5.23859V7.72339C5.76001 7.75059 5.76481 7.77619 5.76961 7.80019C5.77121 7.80819 5.77441 7.81459 5.77601 7.82259C5.78081 7.84019 5.78881 7.85619 5.79681 7.87059C5.80001 7.87699 5.80481 7.88499 5.80801 7.89139C5.81761 7.90739 5.82881 7.92179 5.84161 7.93459C5.84481 7.93939 5.84801 7.94419 5.85281 7.94739C5.87041 7.96499 5.88801 7.97939 5.90881 7.99219C5.91361 7.99539 5.91841 7.99699 5.92321 8.00019C5.93921 8.00979 5.95681 8.01779 5.97441 8.02419C5.98241 8.02739 5.98881 8.02899 5.99681 8.03059C6.01281 8.03539 6.03041 8.03859 6.04641 8.04019C6.05441 8.04179 6.06081 8.04179 6.06881 8.04179C6.07201 8.04179 6.07681 8.04339 6.08001 8.04339C6.09281 8.04339 6.10721 8.04019 6.12001 8.03859C6.12801 8.03699 6.13761 8.03699 6.14561 8.03539C6.17121 8.03059 6.19681 8.02099 6.22241 8.00819L8.70721 6.76659C8.79521 6.72179 8.85121 6.64819 8.87361 6.56499C8.91041 6.42739 8.85441 6.26739 8.70721 6.19379Z"
      fill="#047E2E"
    />
  </svg>
);

export default IconLivestream;

IconLivestream.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconLivestream.defaultProps = {
  style: {},
};
