function onBedrockSignOutUser(e) {
  if (__DEV__) {
    console.log('Web.Zephr:event: onBedrockSignOutUser', e);
  }

  const {
    detail: { signOutUser },
  } = e;
  if (!signOutUser) {
    console.error(
      'Web.Zephr:Error: onBedrockSignOutUser event requires signOutUser boolean to be explicitly passed.',
    );
  }

  if (signOutUser === false) {
    return;
  }

  return window.dispatchEvent(new CustomEvent('onzephrunmountalloutcomes'));
}

window.addEventListener('onbedrocksignoutuser', onBedrockSignOutUser);

export default onBedrockSignOutUser;
