import Theme from './Theme.js';
import Overlay from './Overlay.js';
import Background from './Background.js';
import styles from './OverlayApplicationPro.module.css';
import Logo from './Logo.js';
import ArticleTitle from './ArticleTitle.js';
import ArticleTitlePrice from './ArticleTitlePrice.js';
import ArticleSubtitle from './ArticleSubtitle.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const OverlayApplicationPro = () => {
  const { articleLink } = useSearchParamOrDefaultProps();

  return (
    <Theme>
      <Overlay>
        <Background>
          <div className={styles.overlayApplicationProWrapper}>
            <Logo />
            <ArticleTitle />
            <ArticleTitlePrice />
            <ArticleSubtitle />
            <ButtonCallToAction href={articleLink} />
          </div>
        </Background>
      </Overlay>
    </Theme>
  );
};

OverlayApplicationPro.displayName = 'OverlayApplicationPro';

export default OverlayApplicationPro;
