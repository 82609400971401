import { useParams } from 'react-router-dom';
import TopBarYourMoney from './TopBarYourMoney.template.js';
import BottomBarProTargeting from './BottomBarProTargeting.template.js';
import BottomBarProRSSFeed from './BottomBarProRSSFeed.template.js';
import BottomBarExpandable from './BottomBarExpandable.template.js';
import BottomBarStockAtNight from './BottomBarStockAtNight.template.js';
import OverlayHomeStretch from './OverlayHomeStretch.template.js';
import OverlayApplicationPro from './OverlayApplicationPro.template.js';
import OverlayMadMoneyCramer from './OverlayMadMoneyCramer.template.js';
import OverlayCramerInvestingGuide from './OverlayCramerInvestingGuide.template.js';
import OverlayApplicationProRss from './OverlayApplicationProRss.template.js';
import OverlayProPersonalFinance from './OverlayProPersonalFinance.template.js';
import OverlayProNews from './OverlayProNews.template.js';
import OverlayThreeUp from './OverlayThreeUp.template.js';
import InlineAnimatedPodcast from './InlineAnimatedPodcast.template.js';
import InlineWarrenBuffet from './InlineWarrenBuffet.template.js';
import GateArticleRegisteredOnly from './GateArticleRegisteredOnly.template.js';
import GateArticle from './GateArticle.template.js';
import GateArticleCondensed from './GateArticleCondensed.template.js';
import GateVideo from './GateVideo.template.js';
import PageTheExchange from './PageTheExchange.template.js';
import ComponentResizer from './ComponentResizer.js';

function ComponentPage() {
  const { componentName } = useParams();

  const Component = {
    TopBarYourMoney,
    BottomBarProTargeting,
    BottomBarProRSSFeed,
    BottomBarExpandable,
    BottomBarStockAtNight,
    OverlayHomeStretch,
    OverlayApplicationPro,
    OverlayMadMoneyCramer,
    OverlayCramerInvestingGuide,
    OverlayApplicationProRss,
    OverlayProPersonalFinance,
    OverlayProNews,
    OverlayThreeUp,
    InlineAnimatedPodcast,
    InlineWarrenBuffet,
    GateArticleRegisteredOnly,
    GateVideo,
    PageTheExchange,
    GateArticle,
    GateArticleCondensed,
  }[componentName];

  if (!Component) {
    return <>Error: /components/{componentName} does not exist</>;
  }

  return (
    <>
      <ComponentResizer />
      <Component />
    </>
  );
}

export default ComponentPage;
