import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';
import styles from './Logo.module.css';

function Logo({ logoMaxWidthProp }) {
  const {
    logoImageSrc,
    logoImageAlt,
    showLogoImage,
    logoImageMaxHeight,
    logoImageMaxWidth,
  } = useSearchParamOrDefaultProps();

  const imgStyle = {
    maxHeight: logoImageMaxHeight,
    maxWidth: logoMaxWidthProp || logoImageMaxWidth,
  };

  return (
    showLogoImage === 'true' && (
      <figure className={styles.logoWrapper}>
        <img
          style={imgStyle}
          className={styles.logo}
          src={logoImageSrc}
          alt={logoImageAlt}
        />
      </figure>
    )
  );
}

Logo.displayName = 'Logo';

Logo.propTypes = {
  logoMaxWidthProp: PropTypes.string,
};

export default Logo;
