import { useSearchParams } from 'react-router-dom';
import useComponentCampaignDefaultProps from './useComponentCampaignDefaultProps.js';
import removeZeroLengthObjectProperties from '../utils/removeZeroLengthObjectProperties.js';
import removeExplicitEmptyOrNullValues from '../utils/removeExplicitEmptyOrNullValues.js';
import resolveAliasedURLSearchParamOverrides from '../utils/resolveAliasedURLSearchParamOverrides.js';

/**
 * A custom React hook that merges default properties with URL search parameters.
 *
 * Retrieves URL search parameters and provides a default value if a parameter is missing.
 * If a parameter exists in the URL, its value will overwrite the default.
 *
 * Useful for controlling component configuration based on the URL with default fallbacks.
 *
 * @returns {Object.<string, (string|number|boolean)>} An object mapping search parameters to their values.
 * Values come from the URL or default to predefined ones if missing from the URL.
 */
const useSearchParamOrDefaultProps = () => {
  const [urlSearchParamOverrides] = useSearchParams();
  const defaultProps = useComponentCampaignDefaultProps();

  const searchParams = removeZeroLengthObjectProperties(
    Object.fromEntries(urlSearchParamOverrides),
  );

  const mergedDefaultPropsAndParams = {
    ...defaultProps,
    ...searchParams,
  };

  const props = removeExplicitEmptyOrNullValues(
    // Order matters this transform must occur before removing empty or null values
    resolveAliasedURLSearchParamOverrides(mergedDefaultPropsAndParams),
  );

  return props;
};

export default useSearchParamOrDefaultProps;
