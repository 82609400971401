import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './TextH2.module.css';
import PropTypes from 'prop-types';

const TextH2 = () => {
  const {
    textH2Title,
    textH2Align: textAlign,
    textH2FontWeight: fontWeight,
    textH2FontColor: color,
    textH2FontFamily: fontFamily,
  } = useSearchParamOrDefaultProps();

  const style = {
    textAlign,
    fontWeight,
    color,
    fontFamily,
  };

  return (
    <div className={styles.textH2Wrapper}>
      <h2 className={styles.textH2Title} style={style}>
        {textH2Title}
      </h2>
    </div>
  );
};

TextH2.displayName = 'TextH2';

export default TextH2;
