import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './TextH1.module.css';
import PropTypes from 'prop-types';

const TextH1 = () => {
  const {
    textH1Title,
    textH1Align: textAlign,
    textH1FontWeight: fontWeight,
    textH1FontColor: color,
    textH1FontFamily: fontFamily,
  } = useSearchParamOrDefaultProps();

  const style = {
    textAlign,
    fontWeight,
    color,
    fontFamily,
  };

  return (
    <div className={styles.textH1Wrapper}>
      <h1 className={styles.textH1Title} style={style}>
        {textH1Title}
      </h1>
    </div>
  );
};

TextH1.displayName = 'TextH1';

export default TextH1;
