/**
 * `Link` is a wrapper component around `react-router-dom`'s Link.
 * It supports all the original properties and additionally sends an analytics tracking event when clicked.
 *
 * It uses the `useSearchParamOrDefaultProps` hook to extract analytics-related parameters, either from the search params or from the provided default options.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.to - The destination URL or route.
 * @param {string} [props.target='_top'] - The target frame for the link. Default is '_top'.
 * @param {string|JSX.Element} props.children - The text or elements to be rendered within the link.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */

import { useEffect } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './Link.module.css';
import $t from '../utils/analyticsEventBus.js';

const Link = ({
  to,
  target,
  children,
  fontFamily,
  fontWeight,
  color,
  backgroundColor,
  borderRadius,
  borderWidth,
  borderStyle,
  borderColor,
  text,
  margin,
  padding,
  whiteSpace,
  letterSpacing,
  minWidth,
  alignSelf,
  eventType = 'phx_zephr_click',
}) => {
  const {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  } = useSearchParamOrDefaultProps();

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', eventType, {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  const onClick = () => {
    $t('track', eventType, {
      ...analyticsParamOptions,
      action: text,
    });
  };

  const style = {
    color,
    backgroundColor,
    borderRadius,
    borderColor,
    borderStyle,
    borderWidth,
    fontFamily,
    margin,
    padding,
    whiteSpace,
    fontWeight,
    letterSpacing,
    minWidth,
    alignSelf,
  };

  return (
    <ReactRouterDomLink
      to={to}
      target={target}
      onClick={onClick}
      className={styles.link}
      style={style}
    >
      {children}
    </ReactRouterDomLink>
  );
};

Link.displayName = 'Link';

Link.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  fontFamily: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColor: PropTypes.string,
  text: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  whiteSpace: PropTypes.string,
  fontWeight: PropTypes.number,
  letterSpacing: PropTypes.string,
  minWidth: PropTypes.string,
  alignSelf: PropTypes.string,
  eventType: PropTypes.string,
};

Link.defaultProps = {
  to: '',
  target: '_top',
  children: null,
};

export default Link;
