/**
 * Appends specified query parameters to the given URL, ensuring they do not duplicate
 * existing parameters and maintaining the order of parameters where necessary.
 * It is specifically tailored to append `__source` and `tpcc` parameters for analytics purposes.
 *
 * @param {string} baseUrl - The base URL to which parameters will be appended.
 * @param {Object} params - An object containing the parameters to append.
 * @param {string} [params.__source] - The source parameter for analytics tracking.
 * @param {string} [params.tpcc] - The tpcc parameter for analytics tracking.
 * @returns {string} The full URL with the appended parameters or the base URL if an error occurs.
 */
export function urlJoinParameters(baseUrl, params) {
  if (!baseUrl) return '';
  const { __source, tpcc } = params;

  try {
    // Attempt to create a new URL object from the provided base URL.
    const url = new URL(baseUrl);

    // Before appending new parameters, delete any existing ones to avoid duplication.
    // This ensures that the latest values are used for __source and tpcc.
    url.searchParams.delete('__source');
    url.searchParams.delete('tpcc');

    // Analytics requires parameters in a specific order, with __source preceding tpcc.
    // The following conditionals append the parameters if they are provided.
    if (__source) {
      url.searchParams.append('__source', __source);
    }
    if (tpcc) {
      url.searchParams.append('tpcc', tpcc);
    }

    // Convert the URL object back to a string, which includes all query parameters
    // and the hash fragment, if present.
    return url.toString();
  } catch (error) {
    // In case of an error, such as an invalid URL, log the error.
    // Logging the error will provide visibility without disrupting the flow of the application.
    if (__DEV__ || __PROD__) {
      console.error(`Web.Zephr:urlJoinParameters: ${error.message}`);
    }

    // Returning the base URL allows the application to continue running with the original URL.
    return baseUrl;
  }
}

export default urlJoinParameters;
