import styles from './EmailCaptureInput.module.css';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';

const EmailCaptureInput = ({ value, onChange }) => {
  const { emailCapturePlaceholder } = useSearchParamOrDefaultProps();

  return (
    <input
      type="email"
      value={value}
      onChange={onChange}
      placeholder={emailCapturePlaceholder}
      aria-label={emailCapturePlaceholder}
      className={styles.emailCaptureInput}
      required
    />
  );
};

EmailCaptureInput.displayName = 'EmailCaptureInput';

EmailCaptureInput.defaultProps = {
  value: '',
  onChange: () => {},
};

EmailCaptureInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmailCaptureInput;
