function removeExplicitEmptyOrNullValues(object) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, propertyValue]) => {
      const shouldFilterPropertyOut =
        propertyValue === 'null' || propertyValue === 'empty';
      if (shouldFilterPropertyOut) {
        return false;
      }

      return true;
    }),
  );
}

export default removeExplicitEmptyOrNullValues;
