function camelCaseToKebabCase(camelCaseString) {
  const kebabCaseString = camelCaseString.replace(
    /[A-Z]/g,
    (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase(),
  );

  return kebabCaseString;
}

export default camelCaseToKebabCase;
