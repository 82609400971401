import outcomeEntrypointSelectorMap from './getOutcomeEntrypointSelector.json';

function deriveBaseComponentNameFromComponentName(componentName) {
  const baseComponentNames = Object.keys(outcomeEntrypointSelectorMap);
  const baseComponentNamePattern = new RegExp(
    baseComponentNames.join('|'),
    'i',
  );
  const baseComponentNameMatches = componentName.match(
    baseComponentNamePattern,
  );

  if (!baseComponentNameMatches) {
    console.error(
      `Web.Zephr:deriveBaseComponentNameFromComponentName: could not derive base component name from ${componentName}`,
    );

    return null;
  }

  const [baseComponentName] = baseComponentNameMatches;

  return baseComponentName;
}

export default deriveBaseComponentNameFromComponentName;
