import Gate from './Gate.js';
import GateLogo from './GateLogo.js';
import Theme from './Theme.js';
import styles from './GateVideo.module.css';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import GateValuePropositions from './GateValuePropositions.js';
import IconPlayButton from './IconPlayButton.js';
import Link from './Link.js';
import VideoGateLogoPeacock from './VideoGateLogoPeacock.js';
import classNames from 'classnames';

/**
 * A component for displaying a Gate for video content.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

const GateVideo = () => {
  const {
    h1Text,
    h2Text,
    primaryButtonText,
    primaryButtonLink,
    secondaryButtonText,
    secondaryButtonLink,
    backgroundImageSrc,
    theme,
  } = useSearchParamOrDefaultProps();

  const maxCharacterWidth = Math.max(
    primaryButtonText.length,
    secondaryButtonText.length,
  );
  const minWidth = `calc(${maxCharacterWidth}ch + 16px)`;

  return (
    <Theme>
      <Gate>
        <div
          className={classNames(theme, styles.GateVideo)}
          style={{ backgroundImage: backgroundImageSrc }}
        >
          <header className={styles.header}>
            <GateLogo />
            <Link
              color={'var(--primaryColor)'}
              eventType={'phx_zephr_click_sign_in'}
              fontWeight={600}
              alignSelf={'start'}
              to={'#sign-in'}
            >
              SIGN IN
            </Link>
          </header>

          <div className={styles.iconPlayButtonContainer}>
            <IconPlayButton
              stroke={'#E3E3E3'}
              fill={'#E3E3E3'}
              maxHeight={'100%'}
            />
          </div>

          <section className={styles.valuePropositionSection}>
            <div className={styles.h1Container}>
              <IconPlayButton
                stroke={'var(--primaryColor)'}
                fill={'var(--primaryColor)'}
              />
              <h1 className={styles.h1}>{h1Text}</h1>
            </div>

            <hr className={styles.hr} />
            <h2 className={styles.h2}>{h2Text}</h2>
            <div className={styles.logoPeacockContainer}>
              <VideoGateLogoPeacock strokeColor={'var(--primaryColor)'} />
            </div>
            <GateValuePropositions />
            <div className={styles.buttonContainer}>
              <div className={styles.buttonItem}>
                <Link
                  padding={'17px 26px'}
                  color={'white'}
                  backgroundColor={'var(--primaryColor)'}
                  borderWidth={'1px'}
                  borderStyle={'solid'}
                  borderColor={'var(--primaryColor)'}
                  borderRadius={'3px'}
                  margin={'0 0 10px 0'}
                  whiteSpace={'nowrap'}
                  fontWeight={600}
                  fontFamily={'proxima-nova, sans-serif'}
                  eventType={'phx_zephr_click_call_to_action'}
                  minWidth={minWidth}
                  to={primaryButtonLink}
                >
                  {primaryButtonText}
                </Link>
              </div>

              <div className={styles.buttonItem}>
                <Link
                  borderWidth={'1px'}
                  borderStyle={'solid'}
                  borderColor={'var(--primaryColor)'}
                  borderRadius={'3px'}
                  padding={'17px 26px'}
                  margin={'0 0 10px 0'}
                  color={'var(--primaryColor)'}
                  whiteSpace={'nowrap'}
                  fontWeight={600}
                  fontFamily={'proxima-nova, sans-serif'}
                  eventType={'phx_zephr_click_learn_more'}
                  minWidth={minWidth}
                  to={secondaryButtonLink}
                >
                  {secondaryButtonText}
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Gate>
    </Theme>
  );
};

GateVideo.displayName = 'GateVideo';

export default GateVideo;
