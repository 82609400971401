import PropTypes from 'prop-types';
import styles from './Card.module.css';

const Card = (props) => {
  const {
    showCard,
    cardImageSrc,
    cardImageAlt,
    cardTitle,
    cardTitleColor,
    cardSubtitle,
    cardSubtitleColor,
  } = props;

  if (!showCard || showCard === 'false') {
    return null;
  }

  const titleStyle = {
    color: cardTitleColor,
  };

  const subtitleStyle = {
    color: cardSubtitleColor,
  };

  return (
    <div className={styles.cardWrapper}>
      {cardImageSrc ? (
        <img
          src={cardImageSrc}
          alt={cardImageAlt}
          className={styles.cardImage}
        />
      ) : (
        <div className={styles.imagePlaceholder} />
      )}
      <div className={styles.cardBody}>
        <div className={styles.cardTitle} style={titleStyle}>
          {cardTitle}
        </div>
        <div className={styles.cardSubTitle} style={subtitleStyle}>
          {cardSubtitle}
        </div>
      </div>
    </div>
  );
};

Card.displayName = 'Card';

Card.propTypes = {
  showCard: PropTypes.string,
  cardImageSrc: PropTypes.string,
  cardImageAlt: PropTypes.string,
  cardTitle: PropTypes.string,
  cardTitleColor: PropTypes.string,
  cardSubtitle: PropTypes.string,
  cardSubtitleColor: PropTypes.string,
};

Card.defaultProps = {
  showCard: 'true',
  cardTitleColor: '#fff',
  cardSubtitleColor: '#fff',
};

export default Card;
