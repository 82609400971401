import styles from './OverlayHomeStretch.module.css';
import Overlay from './Overlay.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import Background from './Background.js';
import Profile from './Profile.js';
import OverlayHomeStretchBanner from './OverlayHomeStretchBanner.js';
import PlayIcon from './PlayIcon.js';
import ArticleTitle from './ArticleTitle.js';
import ArticleTitleDashVariant from './ArticleTitleDashVariant.js';
import Theme from './Theme.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const OverlayHomeStretch = () => {
  const { articleLink } = useSearchParamOrDefaultProps();

  return (
    <Theme>
      <Overlay>
        <Background>
          <div className={styles.overlayHomeStretchWrapper}>
            <Profile />
            <OverlayHomeStretchBanner />
            <ArticleTitle />
            <ArticleTitleDashVariant />
            <ButtonCallToAction href={articleLink} icon={<PlayIcon />} />
          </div>
        </Background>
      </Overlay>
    </Theme>
  );
};

OverlayHomeStretch.displayName = 'OverlayHomeStretch';

export default OverlayHomeStretch;
