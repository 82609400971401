import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './Background.module.css';
import PropTypes from 'prop-types';

const Background = ({ children }) => {
  const { backgroundColor, backgroundImageSrc, backgroundPosition } =
    useSearchParamOrDefaultProps();

  const style = {
    backgroundColor,
    backgroundImage: backgroundImageSrc,
    backgroundPosition,
  };

  return (
    <div className={styles.backgroundWrapper} style={style}>
      {children}
    </div>
  );
};

Background.displayName = 'Background';

Background.defaultProps = {
  children: null,
};

Background.propTypes = {
  children: PropTypes.node,
};

export default Background;
