import styles from './PageTheExchange.module.css';
import Page from './Page.js';
import ArticleTitle from './ArticleTitle.js';
import Background from './Background.js';
import Logo from './Logo.js';
import EmailCapture from './EmailCapture.js';
import Theme from './Theme.js';
import React, { useState } from 'react';
import MultiStepComponent from './MultiStepComponent.js';
import ArticleHeadline from './ArticleHeadline.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import NewsletterSelection from './NewsletterSelection.js';
import { useMultiStepContext } from './MultiStepComponent.js';

const PageTheExchange = () => {
  const {
    initialArticleHeadline,
    finalArticleHeadline,
    initialArticleTitle,
    midArticleTitle,
    finalArticleTitle,
    finalCallToActionButtonText,
    heroImageSrc,
    articleLink,
  } = useSearchParamOrDefaultProps();

  const [newsletterSubscriptions, setNewsletterSubscriptions] = useState({});
  const [email, setEmail] = useState('');
  const { goToNextStep } = useMultiStepContext();

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleCheckboxChange = (id) => {
    setNewsletterSubscriptions((prevState) => {
      const { [id]: shouldRemoveNewsletterSubscription, ...otherProps } =
        prevState;

      if (shouldRemoveNewsletterSubscription) {
        const newState = { ...otherProps };

        return newState;
      }

      const newState = {
        [id]: true,
        ...otherProps,
      };

      return newState;
    });
  };

  return (
    <Theme>
      <Page>
        <Background backgroundColor={'#F2F5F8'}>
          <div className={styles.root}>
            <div className={styles.portraitWrapper}>
              <img className={styles.portraitImage} src={heroImageSrc} />
            </div>

            <div className={styles.multistepWrapper}>
              <MultiStepComponent>
                {/* step 1 */}
                <div className={styles.stepWrapper}>
                  <ArticleTitle>{initialArticleTitle}</ArticleTitle>
                  <ArticleHeadline>{initialArticleHeadline}</ArticleHeadline>

                  <EmailCapture
                    shouldInlineSubmitButton={true}
                    defaultEmail={email}
                    goToNextSteps={true}
                    onEmailChange={handleEmailChange}
                    showEmailCaptureButton={true}
                  />
                </div>

                {/* step 2 */}
                <div className={styles.stepWrapper}>
                  <div className={styles.middleStepLogoWrapper}>
                    <Logo />
                  </div>

                  <ArticleTitle>
                    <div className={styles.overlayProNewsArticleTitle}>
                      {midArticleTitle}
                    </div>
                  </ArticleTitle>

                  <NewsletterSelection
                    newsletterSubscriptions={newsletterSubscriptions}
                    handleCheckboxChange={handleCheckboxChange}
                    shouldHideDescriptionsForSmallDevices={true}
                  />
                  <EmailCapture
                    defaultEmail={email}
                    goToNextSteps={true}
                    onEmailChange={handleEmailChange}
                    showEmailCaptureButton={true}
                    showButtonDecline={true}
                    newsletterSubscriptions={newsletterSubscriptions}
                    analyticsSubmitEventName={
                      'phx_zephr_multinewsletter_submit'
                    }
                  />
                </div>

                {/* step 3 */}
                <div className={styles.stepWrapper}>
                  <div className={styles.finalStepLogoWrapper}>
                    <Logo />
                  </div>
                  <ArticleHeadline>{finalArticleHeadline}</ArticleHeadline>
                  <ArticleTitle>{finalArticleTitle}</ArticleTitle>
                  <ButtonCallToAction
                    href={articleLink}
                    text={finalCallToActionButtonText}
                  />
                </div>
              </MultiStepComponent>
            </div>
          </div>
        </Background>
      </Page>
    </Theme>
  );
};

PageTheExchange.displayName = 'PageTheExchange';

export default PageTheExchange;
