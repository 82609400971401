import classNames from 'classnames';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './OverlayLabel.module.css';

const OverlayLabel = () => {
  const {
    labelText,
    labelColor,
    labelBackgroundColor,
    labelFontWeight,
    showLabel,
    labelPosition,
  } = useSearchParamOrDefaultProps();
  const labelWrapperClass = classNames(
    styles.labelWrapper,
    styles[labelPosition || 'topLeft'],
  );

  const style = {
    color: labelColor,
    backgroundColor: labelBackgroundColor,
    fontWeight: labelFontWeight,
  };

  return (
    showLabel === 'true' && (
      <div className={labelWrapperClass} style={style}>
        <label className={styles.label}>{labelText}</label>
      </div>
    )
  );
};

OverlayLabel.displayName = 'OverlayLabel';

export default OverlayLabel;
