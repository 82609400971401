import styles from './NewsletterSelection.module.css';
import CheckBox from './CheckBox.js';
import PropTypes from 'prop-types';
import newsletters from './NewsletterSelection.defaultProps.json';

const NewsletterSelection = ({
  newsletterSubscriptions,
  handleCheckboxChange,
  shouldHideDescriptionsForSmallDevices = false,
}) => {
  return (
    <div className={styles.newsletterSelectionWrapper}>
      {newsletters.map(({ id, title, description }) => (
        <CheckBox
          shouldHideDescriptionsForSmallDevices={
            shouldHideDescriptionsForSmallDevices
          }
          key={id}
          title={title}
          description={description}
          isChecked={newsletterSubscriptions[id]}
          handleCheckboxChange={() => handleCheckboxChange(id)}
        />
      ))}
    </div>
  );
};

NewsletterSelection.displayName = 'NewsletterSelection';

NewsletterSelection.defaultProps = {
  newsletterSubscriptions: {},
  handleCheckboxChange: () => {},
  shouldHideDescriptionsForSmallDevices: false,
};

NewsletterSelection.propTypes = {
  newsletterSubscriptions: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  shouldHideDescriptionsForSmallDevices: PropTypes.bool,
};

export default NewsletterSelection;
