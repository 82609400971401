import React, { useContext, createContext } from 'react';
import useFetch from 'use-http';
import PropTypes from 'prop-types';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const initialContext = {
  loading: null,
  error: null,
  headlines: null,
  link: null,
};

const RssFeedContext = createContext(initialContext);

export const useRssFeedContext = () => {
  return useContext(RssFeedContext);
};

const RssFeedDataComponent = ({ children }) => {
  const { rssFeedUrl } = useSearchParamOrDefaultProps();
  const {
    loading,
    error,
    data: results,
  } = useFetch(rssFeedUrl, { retries: 3 }, []);

  const headlines = (results?.data && results.data[0].title) || '';
  const link = (results?.data && results.data[0].link) || '';

  const contextValue = {
    loading,
    error,
    headlines,
    link,
  };

  return (
    <RssFeedContext.Provider value={contextValue}>
      <>{children}</>
    </RssFeedContext.Provider>
  );
};

RssFeedDataComponent.displayName = 'RssFeedDataComponent';

RssFeedDataComponent.propTypes = {
  children: PropTypes.node,
};

export default RssFeedDataComponent;
