import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
// components
import Theme from './Theme.js';
import ValuePropositions from './ValuePropositions.js';
import $t from '../utils/analyticsEventBus.js';
// hooks
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
// styles
import styles from './GateArticle.module.css';
import Gate from './Gate.js';
// icons + logos
import logoPro from '../assets/icon-pro.svg';
import logoInvestingClub from '../assets/icon-investing-club.svg';
import LogoBrand from './Logo.js';
import IconNewsletter from './IconNewsletter.js';
import IconWatchlist from './IconWatchlist.js';
import IconTrustPortfolio from './IconTrustPortfolio.js';
import IconLiveMeeting from './IconMeeting.js';
import IconExpertAccess from './IconExpertAccess.js';
import IconDailyCheck from './IconDailyCheck.js';
import IconMarketNews from './IconMarketNews.js';
import IconInvestingTools from './IconInvestingTools.js';
import IconLivestream from './IconLivestream.js';
import IconAccess from './IconAccess.js';

/**
 * GateArticle: A component for displaying a Article Gate, a paywall for articles that requires a subscription.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */
const GateArticle = () => {
  const {
    theme: themeParam,
    headerTitle,
    primaryButtonText,
    primaryButtonAction,
    secondaryButtonText,
    secondaryButtonURL,
    showValuePropsList: showValuePropsParam,
    valuePropTitle,
    // analytics
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  } = useSearchParamOrDefaultProps();

  // convert showValueProps to proper boolean type
  const showValueProps = showValuePropsParam === 'true';

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', EVENT_TYPE, {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  const EVENT_TYPE = 'phx_zephr_click';
  const onPrimaryButtonClick = () => {
    $t('track', EVENT_TYPE, {
      ...analyticsParamOptions,
      action: primaryButtonText,
    });
  };

  const onSecondaryButtonClick = () => {
    $t('track', EVENT_TYPE, {
      ...analyticsParamOptions,
      action: secondaryButtonText,
    });
  };

  const onSignInClick = () => {
    $t('track', EVENT_TYPE, {
      ...analyticsParamOptions,
      action: 'Sign in',
    });
  };

  const themes = {
    default: {
      brand: 'default',
      colorTheme: '#001e5a',
      valuePropItems: [
        { text: 'Create watchlists', icon: <IconWatchlist /> },
        { text: 'Exclusive Newsletters', icon: <IconNewsletter /> },
      ],
      logo: <LogoBrand logoMaxWidthProp={'90px'} />,
    },
    pro: {
      brand: 'pro',
      colorTheme: '#047E2E',
      valuePropItems: [
        { text: 'Market News & Analysis', icon: <IconMarketNews /> },
        { text: 'Essential Investing Tools', icon: <IconInvestingTools /> },
        { text: 'Stream CNBC Live', icon: <IconLivestream /> },
        { text: 'Exclusive Access to Experts', icon: <IconExpertAccess /> },
      ],
      logo: <img src={logoPro} alt="pro-logo" />,
    },
    investingClub: {
      brand: 'investingClub',
      colorTheme: '#0053CF',
      valuePropItems: [
        { text: 'Jim Cramer Trust Portfolio', icon: <IconTrustPortfolio /> },
        { text: 'Live Meetings & Events', icon: <IconLiveMeeting /> },
        { text: 'Access Anywhere', icon: <IconAccess /> },
        { text: 'Check In Daily with Jim', icon: <IconDailyCheck /> },
      ],
      logo: <img src={logoInvestingClub} alt="investing-club-logo" />,
    },
  };

  const theme = themes[themeParam] || themes['default'];

  const { brand, colorTheme, valuePropItems, logo } = theme;

  const isRegularUser = brand === 'default';
  const brandColor = { color: colorTheme, borderColor: colorTheme };
  const brandBtnColor = { backgroundColor: colorTheme };
  const brandBorderColor = { color: colorTheme, borderColor: colorTheme };

  return (
    <Theme>
      <Gate>
        <section
          className={cx(styles.gateContainer, {
            [styles.hideVerticalLine]: !showValueProps,
            [styles.verticaLineRegularUser]: isRegularUser,
          })}
        >
          <header
            className={cx(styles.topNavigation, {
              [styles.topNavigationValuePropsDesktop]: showValueProps,
            })}
          >
            <div>{logo}</div>
            <div
              className={styles.signInButton}
              style={brandColor}
              onClick={onSignInClick}
            >
              Sign In
            </div>
          </header>
          <div className={styles.contentWrapper}>
            <div
              className={cx(styles.headlineWrapper, {
                [styles.headlineWrapperNoValueProps]: !showValueProps,
                [styles.headlineWrapperInvestingClub]: showValueProps,
              })}
            >
              <h1>{headerTitle}</h1>
              {showValueProps && (
                <div className={styles.valuePropsTitleMobile}>
                  <h2
                    className={cx({
                      [styles.headerStylesValueProps]: showValueProps,
                    })}
                  >
                    {valuePropTitle}
                  </h2>
                  <div
                    style={brandColor}
                    className={cx({
                      [styles.valuePropsWrapperMobile]: showValueProps,
                    })}
                  >
                    <ValuePropositions items={valuePropItems} />
                  </div>
                </div>
              )}
              <div className={styles.primaryButtonWrapper}>
                <Link to={primaryButtonAction}>
                  <button
                    style={brandBtnColor}
                    className={styles.primaryButton}
                    onClick={onPrimaryButtonClick}
                  >
                    {primaryButtonText}
                  </button>
                </Link>
                <Link to={secondaryButtonURL}>
                  <button
                    style={brandBorderColor}
                    className={cx(styles.secondaryButton, {
                      [styles.hideSecondaryBtnDesktop]: showValueProps,
                    })}
                    onClick={onSecondaryButtonClick}
                  >
                    {secondaryButtonText}
                  </button>
                </Link>
              </div>
            </div>
            <main
              className={cx(styles.main, {
                [styles.hideDivider]: !showValueProps,
                [styles.mainValueProps]: showValueProps,
              })}
            >
              {showValueProps && (
                <div className={styles.valuePropsTitleDesktop}>
                  <h2
                    className={cx({
                      [styles.headerStylesValueProps]: showValueProps,
                    })}
                  >
                    {valuePropTitle}
                  </h2>
                  <div style={brandColor}>
                    <ValuePropositions items={valuePropItems} />
                  </div>
                </div>
              )}
              {showValueProps && (
                <div className={styles.secondaryBtnWrapperWithValueProps}>
                  <Link to={secondaryButtonURL}>
                    <button
                      style={brandBorderColor}
                      className={cx(
                        styles.secondaryButton,
                        styles.hideSecondaryBtnRightPaneMobile,
                      )}
                      onClick={onSecondaryButtonClick}
                    >
                      {secondaryButtonText}
                    </button>
                  </Link>
                </div>
              )}
            </main>
          </div>
        </section>
      </Gate>
    </Theme>
  );
};

GateArticle.displayName = 'GateArticle';

export default GateArticle;
