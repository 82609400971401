import { useParams } from 'react-router-dom';
import theme from './Theme.module.css';
import PropTypes from 'prop-types';

const Theme = ({ children }) => {
  const { componentName } = useParams();
  return <main className={theme[componentName]}>{children}</main>;
};

Theme.displayName = 'Theme';

Theme.defaultProps = {
  children: null,
};

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
