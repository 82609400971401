// In Zephr Console outcome slug is in the pathname when an outcome is selected inside of
// a feature. Outcome slug is also used in a browser context to identify an outcome rendered on
// the page.
function deriveOutcomeSlugFromWindowZephr(analyticsOutcomeId) {
  const didRunWithoutZephrReady = !window || !window.Zephr;
  if (didRunWithoutZephrReady) {
    if (!__TEST__) {
      console.error(
        'Web.Zephr:error:deriveOutcomeMetadataFromWindowZephr: window.Zephr does not exist',
      );
    }

    return null;
  }

  const { outcomes } = window.Zephr;

  const outcomeValues = Object.values(outcomes);

  // Unfortunately there could be name collision
  const outcomeValuesWhereAnalyticsOutcomeIdMatches = outcomeValues.filter(
    ({ outcomeLabel }) => outcomeLabel === analyticsOutcomeId,
  );

  // Assumes better to bail than send bad data
  const shouldBailDueToNoOutcomeId =
    outcomeValuesWhereAnalyticsOutcomeIdMatches.length === 0;
  if (shouldBailDueToNoOutcomeId) {
    console.error(
      `Web.Zephr:deriveOutcomeMetadataFromWindowZephr: attempted to use analyticsOutcomeId "${analyticsOutcomeId}" to look up the associated Zephr outcome.
      When adding an outcome to a feature a title is required to be defined, this title is the analyticsOutcomeId and must be unique across all defined outcomes. 
      This gives us enough data to lookup the outcome in the Cn`,
    );

    return null;
  }

  const shouldBailDueToOutcomeIdCollision =
    outcomeValuesWhereAnalyticsOutcomeIdMatches.length > 1;
  if (shouldBailDueToOutcomeIdCollision) {
    console.error(
      `Web.Zephr:deriveOutcomeMetadataFromWindowZephr: analyticsOutcomeId "${analyticsOutcomeId}" is used in multiple Zephr features and must be unique.
      Please contact marketing to update the value to be unique across all Zephr Features. Until this is update Zephr's internal Analytics panel will not receive data.`,
    );

    return null;
  }

  const [outcomeMetadata] = outcomeValuesWhereAnalyticsOutcomeIdMatches;
  const { outcomeId: outcomeSlug } = outcomeMetadata;

  return outcomeSlug;
}

export default deriveOutcomeSlugFromWindowZephr;
