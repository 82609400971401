let onZephrObserveExitTriggerMouseEvent;
let onZephrObserveExitTriggerScrollEvent;

function onZephrObserveExitTrigger(e) {
  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrObserveExitTrigger', e);
  }

  const {
    detail: { selector, showDelayMilliseconds },
  } = e;

  if (!selector) {
    console.error(
      'Web.Zephr:Error: onZephrObserveExitTrigger event requires a selector to be passed.',
    );
  }

  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    console.error(
      `Web.Zephr:Error: onZephrObserveExitTrigger event target element "${selector}" does not exist.`,
    );
    return;
  }

  // remove the previous event listeners, if any
  if (onZephrObserveExitTriggerMouseEvent) {
    window.removeEventListener(
      'mousemove',
      onZephrObserveExitTriggerMouseEvent,
    );
  }
  if (onZephrObserveExitTriggerScrollEvent) {
    window.removeEventListener('scroll', onZephrObserveExitTriggerScrollEvent);
  }

  const isMobileOrTablet = window.matchMedia('(max-width: 767px)').matches;
  if (isMobileOrTablet) {
    onZephrObserveExitTriggerScrollEvent = (e) => {
      const customEventDetail = {
        selector,
        showDelayMilliseconds,
        onZephrObserveExitTriggerScrollEvent,
      };

      const customEvent = new CustomEvent('onzephrobserveexittriggerscroll', {
        detail: customEventDetail,
      });

      window.dispatchEvent(customEvent);
    };

    window.addEventListener('scroll', onZephrObserveExitTriggerScrollEvent);
  }

  // listen for mouse movements on the window
  onZephrObserveExitTriggerMouseEvent = (e) => {
    const customEventDetail = {
      clientX: e.clientX,
      clientY: e.clientY,
      selector,
      showDelayMilliseconds,
      onZephrObserveExitTriggerMouseEvent,
    };

    const customEvent = new CustomEvent('onzephrobserveexittriggermouse', {
      detail: customEventDetail,
    });

    window.dispatchEvent(customEvent);
  };

  window.addEventListener('mousemove', onZephrObserveExitTriggerMouseEvent);
}

function cleanupEventListeners() {
  if (onZephrObserveExitTriggerMouseEvent) {
    window.removeEventListener(
      'mousemove',
      onZephrObserveExitTriggerMouseEvent,
    );
  }
  if (onZephrObserveExitTriggerScrollEvent) {
    window.removeEventListener('scroll', onZephrObserveExitTriggerScrollEvent);
  }
}

// run before unloading the page
window.addEventListener('beforeunload', cleanupEventListeners);

// run after sign in event
window.addEventListener('onbedrocksigninuser', cleanupEventListeners);

// run after sign out event
window.addEventListener('onbedrocksignoutuser', cleanupEventListeners);

// listen for exit trigger
window.addEventListener('zephrobserveexittrigger', onZephrObserveExitTrigger);

export default onZephrObserveExitTrigger;
