/**
 * `Inline` is a React functional component that displays a bottom bar styled according to campaign defaults.
 *
 * The component makes use of the `useComponentCampaignDefaultProps` hook to derive style properties like
 * minimum and maximum widths and heights, ensuring consistency with campaign styles.
 *
 * The component also includes the `GlobalAnalytics` component to integrate global analytics for the rendered content.
 *
 * Inside the bottom bar, any child elements passed to the `Inline` component are displayed.
 *
 * @param {Object} props - The properties that define the content of the bottom bar.
 * @param {React.ReactNode} props.children - The children nodes to be displayed in the bottom bar.
 *
 * @returns {React.Element} The rendered `Inline` component.
 */

import styles from './Inline.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GlobalAnalytics from './GlobalAnalytics.js';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';

function Inline({ children }) {
  const inlineClass = classNames(styles.inlineDefaults, styles.inline);
  const { maxWidth, minHeight, maxHeight } = useComponentCampaignDefaultProps();

  const style = {
    maxWidth,
    minHeight,
    maxHeight,
  };

  return (
    <>
      <GlobalAnalytics />
      <section
        id="root-component"
        style={style}
        className={inlineClass}
        data-testid="Inline"
      >
        <aside className={styles.inlineFlexGroup}>{children}</aside>
      </section>
    </>
  );
}

Inline.displayName = 'Inline';

Inline.propTypes = {
  children: PropTypes.node,
};

export default Inline;
