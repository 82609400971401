import Gate from './Gate.js';
import Logo from './Logo.js';
import Theme from './Theme.js';
import styles from './GateArticleRegisteredOnly.module.css';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
/**
 * A component for displaying a Gate for Registered Only content.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

const GateArticleRegisteredOnly = () => {
  const { leadText, primaryButtonText, secondaryButtonText } =
    useSearchParamOrDefaultProps();

  return (
    <Theme>
      <Gate>
        <Logo />
        <div className={styles.leadText}>{leadText}</div>
        <div className={styles.accessButtonsContainer}>
          <button type="button" className={styles.primaryButton}>
            {primaryButtonText}
          </button>
          <button type="button" className={styles.secondaryButton}>
            {secondaryButtonText}
          </button>
        </div>
      </Gate>
    </Theme>
  );
};

GateArticleRegisteredOnly.displayName = 'GateArticleRegisteredOnly';

export default GateArticleRegisteredOnly;
