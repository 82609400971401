import getRootPageOrigin from './getRootPageOrigin.js';

function deriveRuntimeEnvironment() {
  const origin = getRootPageOrigin();

  const isDev = /localhost/i.test(origin);
  const isSandBox = /sandbox/i.test(origin);
  const isStage =
    /stg/i.test(origin) &&
    !/stg-03/i.test(origin) &&
    !/stg-aws03/i.test(origin);

  // Special Case: Override to production for "stg-03" & "stg-aws03" origins.
  // This specific setup is due to Zephr vendor's architectural limitations where "stg-03" & "stg-aws03" is configured
  // to point to the production environment. This exception is necessary to ensure that the application
  // behaves consistently with the underlying infrastructure.
  // Important: This setup should be reviewed periodically to ensure it aligns with any infrastructural
  // changes. Any updates to the Zephr vendor configuration or the staging environment naming conventions
  // may require revisiting this logic to maintain correct environment detection.
  const isExceptionOverrideToProduction =
    /stg-03/i.test(origin) || /stg-aws03/i.test(origin);

  if (isDev) {
    return 'development';
  } else if (isSandBox) {
    return 'sandbox';
  } else if (isStage) {
    return 'stage';
  } else if (isExceptionOverrideToProduction) {
    return 'production';
  }

  return 'production';
}

export default deriveRuntimeEnvironment;
