import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleSubtitle.module.css';
import PropTypes from 'prop-types';

const ArticleSubtitle = ({ children, fontColor }) => {
  const { articleSubtitle, articleSubtitleFontColor } =
    useSearchParamOrDefaultProps();

  // In the event of an article subtitle inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleSubtitleFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleSubtitleFontColor;

  return (
    <article className={styles.articleSubtitleWrapper}>
      <div className={styles.articleSubtitle} style={{ color }}>
        {children ? children : articleSubtitle}
      </div>
    </article>
  );
};

ArticleSubtitle.displayName = 'ArticleSubtitle';

ArticleSubtitle.defaultProps = {
  children: null,
  fontColor: '',
};

ArticleSubtitle.propTypes = {
  children: PropTypes.node,
  fontColor: PropTypes.string,
};

export default ArticleSubtitle;
