import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleTitlePrice.module.css';
import PropTypes from 'prop-types';

const ArticleTitlePrice = ({ fontColor }) => {
  const { articleTitlePrice, articleTitlePriceFontColor } =
    useSearchParamOrDefaultProps();

  // In the event of an article price inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleTitlePriceFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleTitlePriceFontColor;

  const priceLength = articleTitlePrice?.length;
  const firstHalf = articleTitlePrice?.substr(0, priceLength - 2);
  const backHalf = articleTitlePrice?.substr(priceLength - 2);

  return (
    <article className={styles.articleTitlePriceWrapper}>
      <div className={styles.articleTitlePrice} style={{ color }}>
        ${firstHalf}
        <sup className={styles.articleTitlePriceCustomCents}>{backHalf}</sup>
      </div>
    </article>
  );
};

ArticleTitlePrice.displayName = 'ArticleTitlePrice';

ArticleTitlePrice.propTypes = {
  fontColor: PropTypes.string,
};

ArticleTitlePrice.defaultProps = {
  fontColor: '',
};

export default ArticleTitlePrice;
