import styles from './TestMockGlobalBottomNav.module.css';

function TestMockGlobalBottomNav() {
  return (
    <nav className={styles.nav}>
      <center>TestMockGlobalBottomNav</center>
    </nav>
  );
}

export default TestMockGlobalBottomNav;
