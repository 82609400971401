import styles from './OverlayMadMoneyCramer.module.css';
import Overlay from './Overlay.js';
import ArticleTitle from './ArticleTitle.js';
import Background from './Background.js';
import Logo from './Logo.js';
import EmailCapture from './EmailCapture.js';
import Theme from './Theme.js';

const OverlayMadMoneyCramer = () => (
  <Theme>
    <Overlay>
      <Background>
        <div className={styles.overlayMadMoneyCramerWrapper}>
          <div className={styles.overlayMadMoneyCramerLogoWrapper}>
            <Logo />
          </div>
          <ArticleTitle />
          <EmailCapture />
        </div>
      </Background>
    </Overlay>
  </Theme>
);

OverlayMadMoneyCramer.displayName = 'OverlayMadMoneyCramer';

export default OverlayMadMoneyCramer;
