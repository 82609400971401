import $t from './analyticsEventBus.js';
import getRootSelector from './getRootSelector.js';

function onClickClose(analyticsParamOptions) {
  if (!analyticsParamOptions) {
    console.error('Web.Zephr:Error: analyticsParamOptions is required.');
    return;
  }

  $t('register', 'phx_zephr_close', {
    allowDuplicate: true,
    remote: true,
  });

  $t('track', 'phx_zephr_close', {
    ...analyticsParamOptions,
    action: 'close x',
  });

  const onClickCloseEvent = new CustomEvent('onzephrclose', {
    detail: {
      selector: getRootSelector(),
    },
  });

  window.dispatchEvent(onClickCloseEvent);
}

export default onClickClose;
