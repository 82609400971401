import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleHeadline.module.css';
import PropTypes from 'prop-types';

const ArticleHeadline = ({ children, fontColor }) => {
  const { articleHeadline, articleHeadlineFontColor } =
    useSearchParamOrDefaultProps();

  // In the event of an article headline inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleHeadlineFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleHeadlineFontColor;

  return (
    <article className={styles.articleHeadlineWrapper}>
      <div className={styles.articleHeadline} style={{ color }}>
        {children ? children : articleHeadline}
      </div>
    </article>
  );
};

ArticleHeadline.displayName = 'ArticleHeadline';

ArticleHeadline.propTypes = {
  children: PropTypes.node,
  fontColor: PropTypes.string,
};

ArticleHeadline.defaultProps = {
  children: null,
  fontColor: '',
};

export default ArticleHeadline;
