import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import Link from './Link.js';
import styles from './ArticleTitle.module.css';
import TopRssArticleLink from './TopRssArticleLink.js';
import urlJoinParameters from '../utils/urlJoinParameters.js';

const ArticleTitleRssFeed = () => {
  // TO DO: This uses the same prop names as the standard articleTitle component.
  // This will need to be adjusted to "articleRSSTitle", "articleRSSTitleFontColor", etc.
  // when making changes to the BottomBarProRSSFeed component.

  // TO DO 2: This only accepts Param or Default Props. Implement the following in the future:
  // In the event of an article title (rss feed) inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleTitleFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.
  // cf: artileTitle, articleHeadline, articleSubtitle, etc.

  const {
    tpcc,
    __source,
    articleLink,
    articleTitle,
    articleTitleTextAlign: textAlign,
    articleTitleFontWeight: fontWeight,
    articleTitleFontColor: color,
  } = useSearchParamOrDefaultProps();

  const style = {
    textAlign,
    fontWeight,
    color,
  };

  const shouldOverrideRSSArticleLink =
    articleTitle.length && articleLink.length;

  const to = urlJoinParameters(articleLink, { __source, tpcc });

  return (
    <article className={styles.articleTitleWrapper}>
      <div className={styles.articleTitle} style={style}>
        {shouldOverrideRSSArticleLink ? (
          <Link to={to}>{articleTitle}</Link>
        ) : (
          <TopRssArticleLink />
        )}
      </div>
    </article>
  );
};

ArticleTitleRssFeed.displayName = 'ArticleTitleRssFeed';

export default ArticleTitleRssFeed;
