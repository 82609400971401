import styles from './OverlayApplicationProRss.module.css';
import Overlay from './Overlay.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import ArticleTitle from './ArticleTitle.js';
import Background from './Background.js';
import Logo from './Logo.js';
import RssArticleLinks from './RssArticleLinks.js';
import RssFeedArticleTitle from './RssFeedArticleTitle.js';
import Theme from './Theme.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const OverlayApplicationProRss = () => {
  const { rssFeedBackgroundColor: backgroundColor, articleLink } =
    useSearchParamOrDefaultProps();

  return (
    <Theme>
      <Overlay>
        <div className={styles.overlayApplicationProRssWrapper}>
          <div
            className={styles.overlayApplicationProRssLeftWrapper}
            style={{ backgroundColor }}
          >
            <RssFeedArticleTitle />
            <RssArticleLinks />
          </div>

          <Background>
            <div className={styles.overlayApplicationProRssRightWrapper}>
              <Logo />
              <ArticleTitle />
              <ButtonCallToAction href={articleLink} />
            </div>
          </Background>
        </div>
      </Overlay>
    </Theme>
  );
};

OverlayApplicationProRss.displayName = 'OverlayApplicationProRss';

export default OverlayApplicationProRss;
