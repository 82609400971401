import React from 'react';
import styles from './EmailCaptureSuccess.module.css';
import CallToActionButtonDownload from './CallToActionButtonDownload.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import classNames from 'classnames';

const EmailCaptureSuccess = () => {
  const {
    callToActionButtonDownloadText,
    emailCaptureSuccessFontColor: color,
    emailCaptureSuccessSignUpText,
    emailCaptureSuccessEmailText,
    emailCaptureSuccessDownloadText,
  } = useSearchParamOrDefaultProps();

  const emailCaptureSuccessSignUpTextClassname = classNames(
    styles.emailCaptureSuccessSignUpText,
    {
      [styles.emailCaptureSuccessSignUpTextPaddingBottom]:
        emailCaptureSuccessDownloadText,
    },
  );

  return (
    <div className={styles.emailCaptureSuccessWrapper} style={{ color }}>
      {emailCaptureSuccessSignUpText && (
        <div className={emailCaptureSuccessSignUpTextClassname}>
          {emailCaptureSuccessSignUpText}
        </div>
      )}
      {emailCaptureSuccessEmailText && (
        <div>{emailCaptureSuccessEmailText}</div>
      )}
      {emailCaptureSuccessDownloadText && (
        <div className={styles.emailCaptureSuccessDownloadText}>
          {emailCaptureSuccessDownloadText}
        </div>
      )}
      {callToActionButtonDownloadText && <CallToActionButtonDownload />}
    </div>
  );
};

EmailCaptureSuccess.displayName = 'EmailCaptureSuccess';

export default EmailCaptureSuccess;
