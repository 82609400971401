/**
 * A component for managing global analytics.
 *
 * This component uses `useSearchParamOrDefaultProps` hook to extract analytics-related
 * parameters, either from the search params or from the provided default options.
 *
 * Upon mounting, it registers a `phx_zephr_load` event with the analytics
 * framework, and includes the analytics parameters in the event details.
 *
 * @returns {null} This component does not render anything, hence it returns null.
 */
import { useEffect } from 'react';
import $t from '../utils/analyticsEventBus.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const GlobalAnalytics = () => {
  const {
    analyticsPageId,
    analyticsTrackingId,
    analyticsOutcomeId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  } = useSearchParamOrDefaultProps();

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', 'phx_zephr_load', {
      allowDuplicate: true,
      remote: true,
    });

    $t('track', 'phx_zephr_load', {
      ...analyticsParamOptions,
      action: 'zephr module loaded',
    });
  }, []);

  return null;
};

export default GlobalAnalytics;
