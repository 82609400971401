import PropTypes from 'prop-types';
import styles from './EmailCaptureButton.module.css';
import classNames from 'classnames';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import IconArrowSubmitEmail from './IconArrowSubmitEmail.js';

const EmailCaptureButton = ({
  isDisabled,
  isLoading,
  useIconForMobileDevices = false,
  isInlineButton = false,
}) => {
  const {
    callToActionButtonText,
    callToActionButtonLoadingText,
    callToActionButtonTextColor: color,
    callToActionButtonBackgroundColor: backgroundColor,
    callToActionButtonBorderRadius: borderRadius,
    callToActionButtonBorderWidth: borderWidth,
    callToActionButtonBorderStyle: borderStyle,
    callToActionButtonBorderColor: borderColor,
  } = useSearchParamOrDefaultProps();

  const emailCaptureButton = classNames(styles.emailCaptureButton, {
    [styles.emailCaptureButtonDisabled]: isDisabled,
  });

  const style = {
    color,
    backgroundColor,
    borderRadius,
    borderColor,
    borderStyle,
    borderWidth,
  };

  const buttonText = isLoading
    ? callToActionButtonLoadingText
    : callToActionButtonText;

  return (
    <button
      type="submit"
      style={style}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      className={classNames(emailCaptureButton, {
        [styles.useIconForMobileDevices]: useIconForMobileDevices,
        [styles.inlined]: isInlineButton,
      })}
      aria-label={callToActionButtonText}
    >
      <span
        className={classNames(styles.buttonTextSpan, {
          [styles.useIconForMobileDevices]: useIconForMobileDevices,
        })}
      >
        {buttonText}
      </span>
      {useIconForMobileDevices && (
        <span
          className={classNames(styles.buttonIconSpan, {
            [styles.useIconForMobileDevices]: useIconForMobileDevices,
          })}
        >
          <IconArrowSubmitEmail stroke={color} />
        </span>
      )}
    </button>
  );
};

EmailCaptureButton.displayName = 'EmailCaptureButton';

EmailCaptureButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  useIconForMobileDevices: false,
  isInlineButton: false,
};

EmailCaptureButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  useIconForMobileDevices: PropTypes.bool,
  isInlineButton: PropTypes.bool,
};

export default EmailCaptureButton;
