import HomePage from './components/HomePage.js';

import ComponentPage from './components/ComponentPage.js';

import TopBarYourMoneyDefaultProps from './components/TopBarYourMoney.defaultProps.json';
import BottomBarProTargetingDefaultProps from './components/BottomBarProTargeting.defaultProps.json';
import BottomBarProRSSFeedDefaultProps from './components/BottomBarProRSSFeed.defaultProps.json';
import BottomBarExpandableDefaultProps from './components/BottomBarExpandable.defaultProps.json';
import BottomBarStockAtNightDefaultProps from './components/BottomBarStockAtNight.defaultProps.json';
import OverlayHomeStretchDefaultProps from './components/OverlayHomeStretch.defaultProps.json';
import OverlayApplicationProProps from './components/OverlayApplicationPro.defaultProps.json';
import OverlayMadMoneyCramerProps from './components/OverlayMadMoneyCramer.defaultProps.json';
import OverlayCramerInvestingGuideProps from './components/OverlayCramerInvestingGuide.defaultProps.json';
import OverlayApplicationProRssProps from './components/OverlayApplicationProRss.defaultProps.json';
import OverlayProPersonalFinanceProps from './components/OverlayProPersonalFinance.defaultProps.json';
import OverlayThreeUpProps from './components/OverlayThreeUp.defaultProps.json';
import OverlayProNewsProps from './components/OverlayProNews.defaultProps.json';
import InlineAnimatedPodcastProps from './components/InlineAnimatedPodcast.defaultProps.json';
import InlineWarrenBuffetProps from './components/InlineWarrenBuffet.defaultProps.json';
import GateArticleRegisteredOnlyProps from './components/GateArticleRegisteredOnly.defaultProps.json';
import GateArticleProps from './components/GateArticle.defaultProps.json';
import GateArticleCondensedProps from './components/GateArticleCondensed.defaultProps.json';
import PageTheExchangeProps from './components/PageTheExchange.defaultProps.json';

import TestPage from './components/TestPage.js';
import PrettyPrintJsonPage from './components/PrettyPrintJsonPage.js';
import StatusPage from './components/StatusPage.js';

const routes = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/components/TopBarYourMoneyDefaultProps.json',
    element: <PrettyPrintJsonPage data={TopBarYourMoneyDefaultProps} />,
  },
  {
    path: '/components/BottomBarProTargeting.json',
    element: <PrettyPrintJsonPage data={BottomBarProTargetingDefaultProps} />,
  },
  {
    path: '/components/BottomBarProRSSFeed.json',
    element: <PrettyPrintJsonPage data={BottomBarProRSSFeedDefaultProps} />,
  },
  {
    path: '/components/BottomBarExpandable.json',
    element: <PrettyPrintJsonPage data={BottomBarExpandableDefaultProps} />,
  },
  {
    path: '/components/BottomBarStockAtNight.json',
    element: <PrettyPrintJsonPage data={BottomBarStockAtNightDefaultProps} />,
  },
  {
    path: '/components/OverlayHomeStretch.json',
    element: <PrettyPrintJsonPage data={OverlayHomeStretchDefaultProps} />,
  },
  {
    path: '/components/OverlayApplicationPro.json',
    element: <PrettyPrintJsonPage data={OverlayApplicationProProps} />,
  },
  {
    path: '/components/OverlayMadMoneyCramer.json',
    element: <PrettyPrintJsonPage data={OverlayMadMoneyCramerProps} />,
  },
  {
    path: '/components/OverlayCramerInvestingGuide.json',
    element: <PrettyPrintJsonPage data={OverlayCramerInvestingGuideProps} />,
  },
  {
    path: '/components/OverlayApplicationProRss.json',
    element: <PrettyPrintJsonPage data={OverlayApplicationProRssProps} />,
  },
  {
    path: '/components/OverlayProPersonalFinance.json',
    element: <PrettyPrintJsonPage data={OverlayProPersonalFinanceProps} />,
  },
  {
    path: '/components/OverlayProNews.json',
    element: <PrettyPrintJsonPage data={OverlayProNewsProps} />,
  },
  {
    path: '/components/OverlayThreeUp.json',
    element: <PrettyPrintJsonPage data={OverlayThreeUpProps} />,
  },
  {
    path: '/components/InlineWarrenBuffet.json',
    element: <PrettyPrintJsonPage data={InlineWarrenBuffetProps} />,
  },
  {
    path: '/components/GateArticleRegisteredOnly.json',
    element: <PrettyPrintJsonPage data={GateArticleRegisteredOnlyProps} />,
  },
  {
    path: '/components/ArticleGate.json',
    element: <PrettyPrintJsonPage data={GateArticleProps} />,
  },
  {
    path: '/components/GateArticleCondensed.json',
    element: <PrettyPrintJsonPage data={GateArticleCondensedProps} />,
  },
  {
    path: '/components/InlineAnimatedPodcast.json',
    element: <PrettyPrintJsonPage data={InlineAnimatedPodcastProps} />,
  },
  {
    path: '/components/PageTheExchange.json',
    element: <PrettyPrintJsonPage data={PageTheExchangeProps} />,
  },
  {
    path: '/components/:componentName',
    element: <ComponentPage />,
  },
  {
    path: '/test/:componentName',
    element: <TestPage />,
  },
  {
    path: '/status',
    element: <StatusPage />,
  },
];

export default routes;
