import getPostMessageTargetOrigin from './getPostMessageTargetOrigin.js';

function bubbleEventToParentPage(e) {
  const { type, detail } = e;

  const message = {
    type,
    detail,
  };
  const targetOrigin = getPostMessageTargetOrigin();

  window.parent.postMessage(message, targetOrigin);
}

export default bubbleEventToParentPage;
