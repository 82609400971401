import { useLayoutEffect } from 'react';

function useMockWindowZephr(analyticsOutcomeId) {
  useLayoutEffect(() => {
    window.Zephr = {
      outcomes: {
        'test-page-mock-campaign': {
          featureLabel: 'Test Page Mock Campaign',
          outcomeId: 'transformation/1',
          outcomeLabel: analyticsOutcomeId,
        },
      },
      accessDetails: {
        accessDecisions: {},
        entitlements: {},
        credits: {},
        meters: {},
        trials: {
          JVjM7K: {
            totalCredits: 1,
            remainingCredits: 0,
            tracker: 'ic_pro_anon',
            reportInDataLayer: false,
            dataLayerCreditsUsedKey: 'trial-JVjM7K-maxCreditsUsed',
            dataLayerCreditsRemainingKey: 'trial-JVjM7K-maxCreditsRemaining',
            usedInDecision: true,
            decrementedInDecision: false,
          },
          QNKLiA: {
            usedInDecision: false,
          },
          JUCCwM: {
            usedInDecision: false,
          },
          VXuzJn: {
            usedInDecision: false,
          },
          Mhj6vy: {
            usedInDecision: false,
          },
          dNJhUb: {
            totalCredits: 1,
            remainingCredits: 0,
            tracker: 'pro_ic_reg',
            reportInDataLayer: false,
            dataLayerCreditsUsedKey: 'trial-dNJhUb-maxCreditsUsed',
            dataLayerCreditsRemainingKey: 'trial-dNJhUb-maxCreditsRemaining',
            usedInDecision: true,
            decrementedInDecision: true,
          },
          SjWO5Q: {
            totalCredits: 4,
            remainingCredits: 3,
            tracker: 'ic_pro_anon',
            reportInDataLayer: false,
            dataLayerCreditsUsedKey: 'trial-SjWO5Q-creditsUsed',
            dataLayerCreditsRemainingKey: 'trial-SjWO5Q-creditsRemaining',
            usedInDecision: true,
            decrementedInDecision: false,
          },
          SFQZli: {
            usedInDecision: false,
          },
          '24Xf0F': {
            totalCredits: 4,
            remainingCredits: 3,
            tracker: 'pro_ic_reg',
            reportInDataLayer: false,
            dataLayerCreditsUsedKey: 'trial-24Xf0F-creditsUsed',
            dataLayerCreditsRemainingKey: 'trial-24Xf0F-creditsRemaining',
            usedInDecision: true,
            decrementedInDecision: true,
          },
          cgNWT6: {
            usedInDecision: false,
          },
        },
        timeTrials: {},
        testGroups: {},
        activeProducts: [],
        authenticated: false,
        trialTrackingDetails: [],
      },
    };
  });
}

export default useMockWindowZephr;
