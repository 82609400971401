import PropTypes from 'prop-types';
// import styles from './LogoPeacockLandscape.module.css';

const LogoPeacockLandscape = ({
  strokeColor = 'red',
  strokeOpacity = 0.1,
  objectFit = 'cover',
  position = 'absolute',
  left = 0,
  top = 0,
  width = '100%',
  height = '100%',
  zIndex = 3,
  className,
}) => (
  <div
    style={{
      objectFit,
      position,
      left,
      top,
      width,
      height,
      zIndex,
    }}
    className={className}
  >
    <svg
      width={943}
      height={548}
      viewBox="0 0 943 548"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M673.208 134.653L536.999 445.824L498.029 175.421C493.04 138.702 555.637 116.011 555.637 116.011C549.895 103.301 530.128 103.583 530.128 103.583H487.862H487.768C481.179 37.0182 541.423 -11.3757 606.657 3.40606C670.761 17.7171 694.011 87.3892 673.208 134.653Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
      <path
        d="M420.181 546.566L154.636 369.561C87.049 324.368 20.2154 370.408 6.00154 417.955C-14.8957 488.098 31.8878 546.566 105.405 546.566H420.181Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
      <path
        d="M455.01 107.82C464.894 39.0894 403.237 -11.7524 336.686 3.31184C272.582 17.6229 249.332 87.295 270.135 134.559L406.344 445.824L455.01 107.82Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
      <path
        d="M395.425 496.948L264.771 198.864C234.178 129.757 153.13 121.189 108.323 161.674C76.7893 190.202 52.315 268.065 122.82 315.141L395.425 496.948Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
      <path
        d="M837.939 546.566C911.456 546.566 958.239 488.098 937.342 417.955C923.128 370.408 856.295 324.368 788.708 369.561L523.162 546.566H837.939Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
      <path
        d="M820.524 315.141C891.028 268.065 866.554 190.296 835.02 161.674C790.213 121.189 709.166 129.757 678.573 198.864L547.918 496.948L820.524 315.141Z"
        stroke={strokeColor}
        strokeOpacity={strokeOpacity}
      />
    </svg>
  </div>
);

LogoPeacockLandscape.propTypes = {
  strokeColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  objectFit: PropTypes.string,
  position: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.string,
  height: PropTypes.string,
  zIndex: PropTypes.number,
  className: PropTypes.string,
};

export default LogoPeacockLandscape;
