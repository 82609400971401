import React from 'react';
import PropTypes from 'prop-types';

const IconAccess = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_741_1285)">
      <path
        d="M11.8401 5.28C13.6593 5.28 15.2321 4.2064 16.0001 2.64C15.2321 1.0736 13.6593 0 11.8401 0C10.0209 0 8.44805 1.0736 7.68005 2.64C8.44805 4.2064 10.0209 5.28 11.8401 5.28Z"
        fill="white"
      />
      <path
        d="M11.8401 0.64C13.2577 0.64 14.5473 1.3984 15.2737 2.64C14.5473 3.8816 13.2577 4.64 11.8401 4.64C10.4225 4.64 9.13285 3.8816 8.40645 2.64C9.13285 1.3984 10.4225 0.64 11.8401 0.64ZM11.8401 0C10.0209 0 8.44805 1.0736 7.68005 2.64C8.44805 4.2064 10.0209 5.28 11.8401 5.28C13.6593 5.28 15.2321 4.2064 16.0001 2.64C15.2321 1.0736 13.6593 0 11.8401 0Z"
        fill="#001E5A"
      />
      <path
        d="M11.84 1.83995C12.2816 1.83995 12.64 2.19835 12.64 2.63995C12.64 3.08155 12.2816 3.43995 11.84 3.43995C11.3984 3.43995 11.04 3.08155 11.04 2.63995C11.04 2.19835 11.3984 1.83995 11.84 1.83995ZM11.84 1.19995C11.0448 1.19995 10.4 1.84475 10.4 2.63995C10.4 3.43515 11.0448 4.07995 11.84 4.07995C12.6352 4.07995 13.28 3.43515 13.28 2.63995C13.28 1.84475 12.6352 1.19995 11.84 1.19995Z"
        fill="#001E5A"
      />
      <path
        d="M11.84 1.84009C12.2816 1.84009 12.64 2.19849 12.64 2.64009C12.64 3.08169 12.2816 3.44009 11.84 3.44009C11.3984 3.44009 11.04 3.08169 11.04 2.64009C11.04 2.19849 11.3984 1.84009 11.84 1.84009Z"
        fill="#D6E1EC"
      />
      <path
        d="M15.04 5.44007C14.864 5.44007 14.72 5.58407 14.72 5.76007V12.1601C14.72 12.3361 14.576 12.4801 14.4 12.4801H3.84C3.664 12.4801 3.52 12.3361 3.52 12.1601V1.28007C3.52 1.10407 3.664 0.960068 3.84 0.960068H7.36C7.536 0.960068 7.68 0.816068 7.68 0.640068C7.68 0.464068 7.536 0.320068 7.36 0.320068H3.84C3.3104 0.320068 2.88 0.750468 2.88 1.28007V1.44007H2.4C1.8704 1.44007 1.44 1.87047 1.44 2.40007V2.88007H0.96C0.4304 2.88007 0 3.31047 0 3.84007V15.0401C0 15.5697 0.4304 16.0001 0.96 16.0001H11.84C12.3696 16.0001 12.8 15.5697 12.8 15.0401V14.5601H13.28C13.8096 14.5601 14.24 14.1297 14.24 13.6001V13.1201H14.4C14.9296 13.1201 15.36 12.6897 15.36 12.1601V5.76007C15.36 5.58407 15.216 5.44007 15.04 5.44007ZM12.16 15.0401C12.16 15.2161 12.016 15.3601 11.84 15.3601H0.96C0.784 15.3601 0.64 15.2161 0.64 15.0401V3.84007C0.64 3.66407 0.784 3.52007 0.96 3.52007H1.44V13.6001C1.44 14.1297 1.8704 14.5601 2.4 14.5601H12.16V15.0401ZM13.6 13.6001C13.6 13.7761 13.456 13.9201 13.28 13.9201H2.4C2.224 13.9201 2.08 13.7761 2.08 13.6001V2.40007C2.08 2.22407 2.224 2.08007 2.4 2.08007H2.88V12.1601C2.88 12.6897 3.3104 13.1201 3.84 13.1201H13.6V13.6001Z"
        fill="#001E5A"
      />
      <path
        d="M12.64 8.95996H5.60003C5.42403 8.95996 5.28003 9.10396 5.28003 9.27996C5.28003 9.45596 5.42403 9.59996 5.60003 9.59996H12.64C12.816 9.59996 12.96 9.45596 12.96 9.27996C12.96 9.10396 12.816 8.95996 12.64 8.95996Z"
        fill="#001E5A"
      />
      <path
        d="M5.28003 6.79998C5.28003 6.97598 5.42403 7.11998 5.60003 7.11998H12.64C12.816 7.11998 12.96 6.97598 12.96 6.79998C12.96 6.62398 12.816 6.47998 12.64 6.47998H5.60003C5.42403 6.47998 5.28003 6.62398 5.28003 6.79998Z"
        fill="#001E5A"
      />
      <path
        d="M5.60003 4.64H7.36003C7.53603 4.64 7.68003 4.496 7.68003 4.32C7.68003 4.144 7.53603 4 7.36003 4H5.60003C5.42403 4 5.28003 4.144 5.28003 4.32C5.28003 4.496 5.42403 4.64 5.60003 4.64Z"
        fill="#001E5A"
      />
      <path
        d="M12.16 15.04C12.16 15.216 12.016 15.36 11.84 15.36H0.960015C0.784015 15.36 0.640015 15.216 0.640015 15.04V3.84002C0.640015 3.66402 0.784015 3.52002 0.960015 3.52002H1.44001V13.6C1.44001 14.1296 1.87041 14.56 2.40001 14.56H12.16V15.04Z"
        fill="#CFD8E2"
      />
      <path
        d="M13.6 13.6001C13.6 13.7761 13.456 13.9201 13.28 13.9201H2.39996C2.22396 13.9201 2.07996 13.7761 2.07996 13.6001V2.40008C2.07996 2.22408 2.22396 2.08008 2.39996 2.08008H2.87996V12.1601C2.87996 12.6897 3.31036 13.1201 3.83996 13.1201H13.6V13.6001Z"
        fill="#CFD8E2"
      />
    </g>
    <defs>
      <clipPath id="clip0_741_1285">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconAccess;

IconAccess.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconAccess.defaultProps = {
  style: {},
};
