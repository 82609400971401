import Theme from './Theme.js';
import Overlay from './Overlay.js';
import Background from './Background.js';
import styles from './OverlayThreeUp.module.css';
import OverlayLabel from './OverlayLabel.js';
import Logo from './Logo.js';
import TextH1 from './TextH1.js';
import TextH2 from './TextH2.js';
import CardGroup from './CardGroup.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const OverlayThreeUp = () => {
  const { callToActionButtonLink } = useSearchParamOrDefaultProps();

  return (
    <Theme>
      <Overlay>
        <Background>
          <div className={styles.overlayThreeUpWrapper}>
            <OverlayLabel />
            <Logo />
            <TextH1 />
            <CardGroup />
            <TextH2 />
            <ButtonCallToAction href={callToActionButtonLink} />
          </div>
        </Background>
      </Overlay>
    </Theme>
  );
};

OverlayThreeUp.displayName = 'OverlayThreeUp';

export default OverlayThreeUp;
