const onZephrObserveExitTriggerScroll = (() => {
  let lastScrollTop = window.scrollY || document.documentElement.scrollTop;
  let lastTimestamp = Date.now();
  const thresholdTimeInMilliseconds = 150;

  return (e) => {
    const {
      detail: {
        selector,
        showDelayMilliseconds,
        onZephrObserveExitTriggerScrollEvent,
      },
    } = e;
    const targetElement = document.querySelector(selector);

    if (!targetElement) {
      if (onZephrObserveExitTriggerScrollEvent) {
        window.removeEventListener(
          'scroll',
          onZephrObserveExitTriggerScrollEvent,
        );
      }
      // should we throw an error here only in __DEV__?
      console.error(
        'Web.Zephr:Error: onZephrObserveExitTriggerScroll targetElement does not exist.',
      );
      return;
    }

    const customEvent = new CustomEvent('onzephrshowcomponent', {
      detail: { selector, showDelayMilliseconds },
    });

    const currentScrollTop =
      window.scrollY || document.documentElement.scrollTop;
    const currentTimestamp = Date.now();
    const timeDiff = currentTimestamp - lastTimestamp;

    if (
      currentScrollTop < lastScrollTop &&
      timeDiff < thresholdTimeInMilliseconds
    ) {
      window.dispatchEvent(customEvent);
    }

    lastScrollTop = currentScrollTop;
    lastTimestamp = currentTimestamp;
  };
})();

window.addEventListener(
  'onzephrobserveexittriggerscroll',
  onZephrObserveExitTriggerScroll,
);

export default onZephrObserveExitTriggerScroll;
