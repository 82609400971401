/**
 * Retrieves the origin of the root page. If the current page is embedded as an iframe, it will return the referrer's origin (if available). Otherwise, it will return the current page's origin.
 *
 * @param {Window} [windowTop=window.top] - A reference to the current window's top object. Defaults to the global `window.top`.
 * @param {Window} [windowSelf=window.self] - A reference to the current window's self object. Defaults to the global `window.self`.
 * @returns {string} The origin of the root page, either from the referrer or the current page's location origin.
 *
 * @see {@link https://stackoverflow.com/a/7739035|StackOverflow reference}
 *
 * @example
 * // If the function is called inside an iframe with document.referrer as 'https://www.cnbc.com/'
 * getRootPageOrigin(); // returns 'https://www.cnbc.com'
 *
 * // If the function is called in the top window (not inside an iframe)
 * getRootPageOrigin(); // returns the current page's location origin (e.g., 'https://www.example.com')
 */
function getRootPageOrigin(windowTop = window.top, windowSelf = window.self) {
  // For iframes this is just the origin like https://www.cnbc.com
  // Browser drop the path and query string params
  // https://stackoverflow.com/a/7739035
  const isCurrentPageIframed = windowTop !== windowSelf;

  // Determine which origin controls the page content
  if (isCurrentPageIframed && document && document.referrer) {
    try {
      const { origin } = new URL(document.referrer);

      return origin;
    } catch (e) {
      console.error('Web.Zephr:Error:getRootPageOrigin', e);

      // TODO: is there a better return in an error state?
      return null;
    }
  }

  const { origin } = window.location;

  return origin;
}

export default getRootPageOrigin;
