import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './PlayIcon.module.css';

const PlayIcon = () => {
  const { playIconImageSrc } = useSearchParamOrDefaultProps();

  return (
    <div className={styles.playIconWrapper}>
      <img alt="Play Icon" src={playIconImageSrc} className={styles.playIcon} />
    </div>
  );
};

PlayIcon.displayName = 'PlayIcon';

export default PlayIcon;
