const threshold = 50;

function onZephrObserveExitTriggerMouse(e) {
  const {
    detail: {
      clientY,
      clientX,
      selector,
      showDelayMilliseconds,
      onZephrObserveExitTriggerMouseEvent,
    },
  } = e;

  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    if (onZephrObserveExitTriggerMouseEvent) {
      window.removeEventListener(
        'mousemove',
        onZephrObserveExitTriggerMouseEvent,
      );
    }
    // should we throw an error here only in __DEV__?
    console.error(
      'Web.Zephr:Error: onZephrObserveExitTriggerMouse targetElement element does not exist.',
    );
    return;
  }

  // detect all four sides of the screen
  const shouldShowOverlay =
    clientY < threshold ||
    clientX < threshold ||
    clientX > window.innerWidth - threshold ||
    clientY > window.innerHeight - threshold;

  const customEvent = new CustomEvent('onzephrshowcomponent', {
    detail: { selector, showDelayMilliseconds },
  });

  if (shouldShowOverlay) {
    window.dispatchEvent(customEvent);
  }
}

window.addEventListener(
  'onzephrobserveexittriggermouse',
  onZephrObserveExitTriggerMouse,
);

export default onZephrObserveExitTriggerMouse;
