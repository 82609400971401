/**
 * `CloseButton` is a functional React component that renders a close button element.
 *
 * This button includes an SVG graphic for the close 'X' and an accessible label.
 * When the button is clicked, it invokes the `onClose` function passed in as a prop
 * and also triggers a global analytics tracking event.
 *
 * The component uses CSS styles from the 'CloseButton.module.css' file.
 * Additionally, it uses the `useSearchParamOrDefaultProps` hook to extract
 * analytics-related parameters, either from the search params or from the provided default options.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.closeButtonColor - The color of the close button.
 * @param {string} props.zephrTrackingId - The tracking ID for Zephr analytics.
 * @param {string} props.id - The campaign ID for analytics.
 * @param {string} props.campaignType - The type of the campaign for analytics.
 * @param {string} props.campaignDetail - Additional campaign details for analytics.
 * @param {string} props.pageId - The page ID for analytics.
 * @param {string} props.closeButton - Indicates if the close button should be rendered (values: 'true' or 'false').
 *
 * @returns {JSX.Element|null} - The rendered close button component or null if `closeButton` prop is 'false'.
 */

import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './CloseButton.module.css';
import onClickClose from '../utils/onClickClose.js';

const CloseButton = () => {
  const {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
    showCloseButton,
    closeButtonColor,
  } = useSearchParamOrDefaultProps();

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  const onClickCloseButton = () => {
    onClickClose(analyticsParamOptions);
  };

  return (
    showCloseButton === 'true' && (
      <button
        data-testid="analytics-click"
        className={styles.closeButton}
        onClick={onClickCloseButton}
      >
        <svg
          className="close-xsvg"
          viewBox="240 240 20 20"
          aria-hidden="true"
          width="20"
          height="20"
          stroke={closeButtonColor}
        >
          <g className="close-xstroke">
            <path
              d="M255.6 255.6l-11.2-11.2"
              vectorEffect="non-scaling-stroke"
            ></path>
            <path
              d="M255.6 244.4l-11.2 11.2"
              vectorEffect="non-scaling-stroke"
            ></path>
          </g>
        </svg>
        <div className={styles.closeButtonAllyLabel}>close dialog</div>
      </button>
    )
  );
};

export default CloseButton;
