import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const initialContext = {
  currentStep: 0,
  goToNextStep: () => {},
  isMultiStep: false,
};

const MultiStepContext = createContext(initialContext);

export const useMultiStepContext = () => {
  return useContext(MultiStepContext);
};

const MultiStepComponent = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isMultiStep, setIsMultiStep] = useState(true);

  // React.Children.toArray() converts the children prop into a flat array
  const steps = React.Children.toArray(children);

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const contextValue = {
    currentStep,
    goToNextStep,
    isMultiStep,
  };

  return (
    <MultiStepContext.Provider value={contextValue}>
      <div>{steps[currentStep]}</div>
    </MultiStepContext.Provider>
  );
};

MultiStepComponent.displayName = 'MultiStepComponent';

MultiStepComponent.propTypes = {
  children: PropTypes.node,
};

export default MultiStepComponent;
