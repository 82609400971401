import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleTitleDashVariant.module.css';
import PropTypes from 'prop-types';

const ArticleTitleDashVariant = ({ fontColor }) => {
  const { articleTitleDashVariant, articleTitleDashVariantFontColor } =
    useSearchParamOrDefaultProps();

  // In the event of an article title (dash variant) inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleTitleDashVariantFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleTitleDashVariantFontColor;

  const backgroundColor = color;

  return (
    <article className={styles.articleTitleDashVariantWrapper}>
      <div className={styles.dash} style={{ backgroundColor }}></div>
      <div className={styles.articleTitleDashVariantTitle} style={{ color }}>
        {articleTitleDashVariant}
      </div>
      <div className={styles.dash} style={{ backgroundColor }}></div>
    </article>
  );
};

ArticleTitleDashVariant.displayName = 'ArticleTitleDashVariant';

ArticleTitleDashVariant.propTypes = {
  fontColor: PropTypes.string,
};

ArticleTitleDashVariant.defaultProps = {
  fontColor: '',
};

export default ArticleTitleDashVariant;
