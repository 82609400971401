import getRootPageOrigin from './getRootPageOrigin.js';

function getPostMessageTargetOrigin() {
  const rootPageOrigin = getRootPageOrigin();

  const safeDomainPattern = /cnbc\.com$/i;
  const isSafeTargetOrigin =
    rootPageOrigin.match(safeDomainPattern) ||
    rootPageOrigin === 'http://localhost:3000';

  // IMPORTANT: Only safe post message target origins should be returned
  if (!isSafeTargetOrigin) {
    return null;
  }

  return rootPageOrigin;
}

export default getPostMessageTargetOrigin;
