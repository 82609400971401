import './globals.js';
import './events/onBedrockSignInUser.js';
import './events/onBedrockSignOutUser.js';
import './events/onZephrAnalyticsRuleOutcomeConversion.js';
import './events/onZephrOutcomeHistoryAppend.js';
import './events/onZephrClose.js';
import './events/onZephrInitializeComponent.js';
import './events/onZephrObserveDelayedTrigger.js';
import './events/onZephrObserveExitTrigger.js';
import './events/onZephrObserveExitTriggerMouse.js';
import './events/onZephrObserveExitTriggerScroll.js';
import './events/onZephrResize.js';
import './events/onZephrShowComponent.js';
import './events/onZephrUnmountAllOutcomes.js';
import isTrustedOrigin from './utils/isTrustedOrigin.js';

/**
 * Handles incoming postMessage events, dispatching them as CustomEvents if they originate from a trusted source.
 *
 * @param {MessageEvent} e - The message event object received from the postMessage API.
 * @returns {boolean|undefined} Returns false if the origin is untrusted or the event type is not provided, otherwise undefined.
 */
function message(e) {
  const {
    origin,
    data: { type: eventType, detail },
  } = e;

  // !IMPORTANT: For security reasons post message origin must be checked against an allowlist
  if (!isTrustedOrigin(origin) || eventType === 'webpackOk' || !eventType) {
    // noop
    return;
  }

  if (__DEV__) {
    console.info(`Web.Zephr:info: post message received for ${eventType}`);
  }

  const forwardedEvent = new CustomEvent(eventType, { detail });

  // Trigger event
  return window.dispatchEvent(forwardedEvent);
}

window.addEventListener('message', message);

export default message;
