import isTrustedOrigin from './isTrustedOrigin.js';
import isIframedPage from './isIframedPage.js';

function shouldBubbleEventToParentPage() {
  const isTrusted = isTrustedOrigin(window.location.origin);
  if (!isIframedPage() || !isTrusted) {
    return false;
  }

  return true;
}

export default shouldBubbleEventToParentPage;
