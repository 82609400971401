import styles from './TestMockFooter.module.css';

function TestMockFooter() {
  return (
    <footer className={styles.TestMockFooter}>
      <center>TestMockFooter</center>
    </footer>
  );
}

export default TestMockFooter;
