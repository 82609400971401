import isIframedPage from './isIframedPage.js';

function getRootSelector() {
  const selector = isIframedPage()
    ? `[src^="${window.location.href}"]`
    : '#root-component';

  return selector;
}

export default getRootSelector;
