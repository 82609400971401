import styles from './InlineAnimatedPodcastLogo.module.css';
import classNames from 'classnames';

function InlineAnimatedPodcastLogo() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src="/images/logo-cnbc-podcasts.png" />

      <img
        className={classNames([styles.soundwave, styles.soundwaveLeft])}
        src="/images/icon-sound-left.png"
      />
      <img
        className={classNames([styles.soundwave, styles.soundwaveRight])}
        src="/images/icon-sound-right.png"
      />
    </div>
  );
}

export default InlineAnimatedPodcastLogo;
