import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from '../routes.js';
import './App.module.css';
import isIframedPage from '../utils/isIframedPage.js';

const router = createBrowserRouter(routes);

function App() {
  /* WARNING */
  // In combination with Content-Security-Policy that only allows the app
  // to be iframed from a *.cnbc.com this ensures the app only renders based
  // on parameters controlled by our marketing team
  const shouldAppRenderBail = __PROD__ && !isIframedPage();
  if (shouldAppRenderBail) {
    return 'Error: production Web.Zephr can only be rendered inside an iframe';
  }
  /* END WARNING */

  return <RouterProvider router={router} />;
}

export default App;
