function resolveAliasedURLSearchParamOverrides(urlSearchParamOverrides) {
  const { backgroundImageSrc } = urlSearchParamOverrides;

  const shouldSkipAliasResolution = !backgroundImageSrc;
  if (shouldSkipAliasResolution) {
    return urlSearchParamOverrides;
  }

  const resolvedURLSearchParamOverrides = { ...urlSearchParamOverrides };

  // Background Image Source is complex due to the following:
  // - browsers give priority to background-image over background-color
  // - anything configured as a color picker in Zephr always has a value
  // - `null` or `empty` values are explicit indicators in Web.Zephr that a property should be excluded
  // - `null` or `empty` are removed from the object returned from useSearchParamOrDefaultProps() to prevent
  //   configurable text fields set to `null` or `empty` from rendering the text `null` or `empty`
  // - backgroundImageSrc is not the complete CSS value for background-image, for non-technical ux reasons we do not ask marketing
  //   team to wrap image sources in url(...)
  //
  // Example: Marketing sets backgroundImageSrc to `null` and backgroundImageSrc is undefined because
  // properties with `null` or `empty` value are removed. In this scenario the expected outcome is background-image
  // is to be set to `none` so the background-color takes over.
  if (backgroundImageSrc === 'null' || backgroundImageSrc === 'empty') {
    resolvedURLSearchParamOverrides.backgroundImageSrc = 'none';
  } else if (backgroundImageSrc.startsWith('url(')) {
    resolvedURLSearchParamOverrides.backgroundImageSrc = backgroundImageSrc;
  } else {
    resolvedURLSearchParamOverrides.backgroundImageSrc = `url(${backgroundImageSrc})`;
  }

  return resolvedURLSearchParamOverrides;
}

export default resolveAliasedURLSearchParamOverrides;
