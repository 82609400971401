import getPostMessageTargetOrigin from './getPostMessageTargetOrigin.js';
import removeAnalyticsPrefixFromKeyNames from './removeAnalyticsPrefixFromKeyNames.js';
import analyticsZephrRuleOutcomeHandler from './analyticsZephrRuleOutcomeHandler.js';
import analyticsZephrOutcomeHistoryHandler from './analyticsZephrOutcomeHistoryHandler.js';

const eventTypes = {};
const eventLog = [];

function hasParentWindow() {
  return window.parent && window.parent != window;
}

window.$t = function (action = null, eventType = null, _options = {}) {
  const options = removeAnalyticsPrefixFromKeyNames(_options);

  // Handling calls with no args
  if (!action && !eventType) {
    console.log('$t EVENT_TYPES', eventTypes);
    console.log('$t EVENT_LOG', eventLog);
  }

  // Handling "register" calls
  else if (action == 'register' && typeof eventType == 'string') {
    eventTypes[eventType] = options;

    // Bubbling up registered events to the parent window
    if (options.remote && hasParentWindow()) {
      const postMessageTargetOrigin =
        process.env.REACT_APP_ANALYTICS_DEBUG_POSTMESSAGE_TARGET_ORIGIN ??
        getPostMessageTargetOrigin();
      const message = { sender: '$t', action, eventType, options };

      if (__DEV__) {
        console.info(
          `Web.Zephr:analytics:${action}`,
          message,
          postMessageTargetOrigin,
        );
      }

      window.parent.postMessage(message, postMessageTargetOrigin);
    }
  }

  // Handling "track" calls
  else if (action == 'track' && typeof eventType == 'string') {
    const typeOptions = eventTypes[eventType];

    const status = eventTypes[eventType] ? 'OK' : 'UNREGISTERED';
    const ts = new Date().toISOString();
    eventLog.push({ eventType, options, status, ts });

    // Bubbling up fired events to the parent window
    if (typeOptions.remote && hasParentWindow() && status == 'OK') {
      const postMessageTargetOrigin =
        process.env.REACT_APP_ANALYTICS_DEBUG_POSTMESSAGE_TARGET_ORIGIN ??
        getPostMessageTargetOrigin();
      const message = { sender: '$t', action, eventType, options };

      analyticsZephrRuleOutcomeHandler(message);
      analyticsZephrOutcomeHistoryHandler(message);

      if (__DEV__) {
        console.info(
          `Web.Zephr:analytics:${action}`,
          message,
          postMessageTargetOrigin,
        );
      }

      window.parent.postMessage(message, postMessageTargetOrigin);
    }
  }
};

export default $t;
