import { useLayoutEffect, useState } from 'react';

function useBodyHeight() {
  const [height, setHeight] = useState();

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.unobserve(document.body);
  }, []);

  return height;
}

export default useBodyHeight;
