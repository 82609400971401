import React from 'react';
import PropTypes from 'prop-types';

const IconMeeting = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M14.08 2.8H11.84V2.32C11.84 2.144 11.696 2 11.52 2C11.344 2 11.2 2.144 11.2 2.32V2.8H4.79996V2.32C4.79996 2.144 4.65596 2 4.47996 2C4.30396 2 4.15996 2.144 4.15996 2.32V2.8H1.91996C1.39036 2.8 0.959961 3.2304 0.959961 3.76V5.36V13.04C0.959961 13.5696 1.39036 14 1.91996 14H14.08C14.6096 14 15.04 13.5696 15.04 13.04V3.76C15.04 3.2304 14.6096 2.8 14.08 2.8ZM1.91996 3.44H4.15996V3.92C4.15996 4.096 4.30396 4.24 4.47996 4.24C4.65596 4.24 4.79996 4.096 4.79996 3.92V3.44H11.2V3.92C11.2 4.096 11.344 4.24 11.52 4.24C11.696 4.24 11.84 4.096 11.84 3.92V3.44H14.08C14.256 3.44 14.4 3.584 14.4 3.76V5.04H1.59996V3.76C1.59996 3.584 1.74396 3.44 1.91996 3.44ZM14.08 13.36H1.91996C1.74396 13.36 1.59996 13.216 1.59996 13.04V5.68H14.4V13.04C14.4 13.216 14.256 13.36 14.08 13.36Z"
      fill="#0053CF"
    />
    <path
      d="M9.11994 6.96802H6.87994C6.70394 6.96802 6.55994 7.11202 6.55994 7.28802C6.55994 7.46402 6.70394 7.60802 6.87994 7.60802H9.11994C9.29594 7.60802 9.43994 7.46402 9.43994 7.28802C9.43994 7.11202 9.29594 6.96802 9.11994 6.96802Z"
      fill="#0053CF"
    />
    <path
      d="M12.9599 6.96802H10.7199C10.5439 6.96802 10.3999 7.11202 10.3999 7.28802C10.3999 7.46402 10.5439 7.60802 10.7199 7.60802H12.9599C13.1359 7.60802 13.2799 7.46402 13.2799 7.28802C13.2799 7.11202 13.1359 6.96802 12.9599 6.96802Z"
      fill="#0053CF"
    />
    <path
      d="M9.11994 9.20801H6.87994C6.70394 9.20801 6.55994 9.35201 6.55994 9.52801C6.55994 9.70401 6.70394 9.84801 6.87994 9.84801H9.11994C9.29594 9.84801 9.43994 9.70401 9.43994 9.52801C9.43994 9.35201 9.29594 9.20801 9.11994 9.20801Z"
      fill="#0053CF"
    />
    <path
      d="M5.27997 6.96802H3.03997C2.86397 6.96802 2.71997 7.11202 2.71997 7.28802C2.71997 7.46402 2.86397 7.60802 3.03997 7.60802H5.27997C5.45597 7.60802 5.59997 7.46402 5.59997 7.28802C5.59997 7.11202 5.45597 6.96802 5.27997 6.96802Z"
      fill="#0053CF"
    />
    <path
      d="M5.27997 9.20801H3.03997C2.86397 9.20801 2.71997 9.35201 2.71997 9.52801C2.71997 9.70401 2.86397 9.84801 3.03997 9.84801H5.27997C5.45597 9.84801 5.59997 9.70401 5.59997 9.52801C5.59997 9.35201 5.45597 9.20801 5.27997 9.20801Z"
      fill="#0053CF"
    />
    <path
      d="M5.27997 11.448H3.03997C2.86397 11.448 2.71997 11.592 2.71997 11.768C2.71997 11.944 2.86397 12.088 3.03997 12.088H5.27997C5.45597 12.088 5.59997 11.944 5.59997 11.768C5.59997 11.592 5.45597 11.448 5.27997 11.448Z"
      fill="#0053CF"
    />
    <path
      d="M9.11994 11.448H6.87994C6.70394 11.448 6.55994 11.592 6.55994 11.768C6.55994 11.944 6.70394 12.088 6.87994 12.088H9.11994C9.29594 12.088 9.43994 11.944 9.43994 11.768C9.43994 11.592 9.29594 11.448 9.11994 11.448Z"
      fill="#0053CF"
    />
    <path
      d="M10.3999 12.088H13.2799V9.20801H10.3999V12.088ZM11.0399 9.84801H12.6399V11.448H11.0399V9.84801Z"
      fill="#0053CF"
    />
    <path
      opacity="0.2"
      d="M1.91998 3.43994H4.15998V3.91994C4.15998 4.09594 4.30398 4.23994 4.47998 4.23994C4.65598 4.23994 4.79998 4.09594 4.79998 3.91994V3.43994H11.2V3.91994C11.2 4.09594 11.344 4.23994 11.52 4.23994C11.696 4.23994 11.84 4.09594 11.84 3.91994V3.43994H14.08C14.256 3.43994 14.4 3.58394 14.4 3.75994V5.03994H1.59998V3.75994C1.59998 3.58394 1.74398 3.43994 1.91998 3.43994Z"
      fill="#0053CF"
    />
    <path
      opacity="0.2"
      d="M12.6399 9.8479H11.0399V11.4479H12.6399V9.8479Z"
      fill="#0053CF"
    />
  </svg>
);

export default IconMeeting;

IconMeeting.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconMeeting.defaultProps = {
  style: {},
};
