import outcomeEntrypointSelectorMap from './getOutcomeEntrypointSelector.json';
import deriveBaseComponentNameFromComponentName from './deriveBaseComponentNameFromComponentName.js';

function getOutcomeEntrypointSelector(componentName, product) {
  const baseComponentName =
    deriveBaseComponentNameFromComponentName(componentName);

  if (!baseComponentName) {
    if (__DEV__ || __PROD__) {
      console.error(
        `Web.Zephr:getOutcomeEntrypointSelector: "${componentName}" has an unknown base component name`,
      );
    }

    return null;
  }

  // Gates are an exception because the selector varies by
  // content type and product (e.g., video, article, article pro, etc)
  if (baseComponentName === 'Gate') {
    const [contentType] = componentName.match(/Video|Article/i) || [];

    if (!contentType) {
      if (__DEV__ || __PROD__) {
        console.error(
          `Web.Zephr:getOutcomeEntrypointSelector: gate content type for ${componentName} is unknown. Exiting early.`,
        );
      }

      return null;
    }

    const outcomeEntrypointSelector =
      outcomeEntrypointSelectorMap[baseComponentName][contentType][product];

    return outcomeEntrypointSelector;
  }

  const outcomeEntrypointSelector =
    outcomeEntrypointSelectorMap[baseComponentName];

  return outcomeEntrypointSelector;
}

export default getOutcomeEntrypointSelector;
