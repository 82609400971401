import PropTypes from 'prop-types';

function IconTools({
  fill = 'var(--primaryColor)',
  backgroundFill = 'var(--secondaryColor)',
}) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.19994 7.5199H2.55994V10.7199H3.19994V7.5199Z"
        fill={backgroundFill}
      />
      <path d="M5.76 8.16003H5.12V11.36H5.76V8.16003Z" fill={backgroundFill} />
      <path
        d="M8.32005 7.19995H7.68005V9.91995H8.32005V7.19995Z"
        fill={backgroundFill}
      />
      <path d="M10.88 8.80005H10.24V11.36H10.88V8.80005Z" fill={fill} />
      <path d="M13.44 6.87988H12.8V10.2399H13.44V6.87988Z" fill={fill} />
      <path d="M15.36 2.71997H0.640015V4.31997H15.36V2.71997Z" fill={fill} />
      <path
        d="M2.55844 11.9999H3.19844V11.3599H3.84004V6.87987H3.19844V6.23987H2.55844V6.87987H1.92004V11.3599H2.55844V11.9999ZM2.56004 7.51987H3.20004V10.7199H2.56004V7.51987Z"
        fill={fill}
      />
      <path
        d="M5.11838 12.6399H5.75838V11.9999H6.39998V7.51988H5.75838V6.87988H5.11838V7.51988H4.47998V11.9999H5.11838V12.6399ZM5.11998 8.15988H5.75998V11.3599H5.11998V8.15988Z"
        fill={fill}
      />
      <path
        d="M7.67844 11.1999H8.31844V10.5599H8.96004V6.55992H8.31844V5.91992H7.67844V6.55992H7.04004V10.5599H7.67844V11.1999ZM7.68004 7.19992H8.32004V9.91992H7.68004V7.19992Z"
        fill={fill}
      />
      <path
        d="M10.2384 12.64H10.8784V12H11.52V8.16002H10.8784V7.52002H10.2384V8.16002H9.59998V12H10.2384V12.64ZM10.24 8.80002H10.88V11.36H10.24V8.80002Z"
        fill={fill}
      />
      <path
        d="M12.7984 11.5199H13.4384V10.8799H14.08V6.23985H13.4384V5.59985H12.7984V6.23985H12.16V10.8799H12.7984V11.5199ZM12.8 6.87985H13.44V10.2399H12.8V6.87985Z"
        fill={fill}
      />
      <path
        d="M0 2.07996V13.92H16V2.07996H0ZM15.36 13.28H0.64V4.95996H15.36V13.28ZM15.36 4.31996H0.64V2.71996H15.36V4.31996Z"
        fill={fill}
      />
      <path d="M13.4543 3.19995H12.6191V3.83995H13.4543V3.19995Z" fill={fill} />
      <path d="M14.8464 3.19995H14.0112V3.83995H14.8464V3.19995Z" fill={fill} />
      <path d="M12.0624 3.19995H11.2272V3.83995H12.0624V3.19995Z" fill={fill} />
      <path d="M7.44315 3.19995H1.15515V3.83995H7.44315V3.19995Z" fill={fill} />
    </svg>
  );
}

IconTools.propTypes = {
  fill: PropTypes.string,
  backgroundFill: PropTypes.string,
};

export default IconTools;

<svg
  width="30"
  height="24"
  viewBox="0 0 30 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.7971 19.4998H5.9971V18.2998H7.2001V9.89983H5.9971V8.69983H4.7971V9.89983H3.6001V18.2998H4.7971V19.4998ZM4.8001 11.0998H6.0001V17.0998H4.8001V11.0998Z"
    fill="#047E2E"
  />
  <path
    d="M9.5969 20.6999H10.7969V19.4999H11.9999V11.0999H10.7969V9.8999H9.5969V11.0999H8.3999V19.4999H9.5969V20.6999ZM9.5999 12.2999H10.7999V18.2999H9.5999V12.2999Z"
    fill="#047E2E"
  />
  <path
    d="M14.397 17.9997H15.597V16.7997H16.8V9.29973H15.597V8.09973H14.397V9.29973H13.2V16.7997H14.397V17.9997ZM14.4 10.4997H15.6V15.5997H14.4V10.4997Z"
    fill="#047E2E"
  />
  <path
    d="M19.197 20.7H20.397V19.5H21.6V12.3H20.397V11.1H19.197V12.3H18V19.5H19.197V20.7ZM19.2 13.5H20.4V18.3H19.2V13.5Z"
    fill="#047E2E"
  />
  <path
    d="M23.997 18.5998H25.197V17.3998H26.4V8.69976H25.197V7.49976H23.997V8.69976H22.8V17.3998H23.997V18.5998ZM24 9.89976H25.2V16.1998H24V9.89976Z"
    fill="#047E2E"
  />
  <path
    d="M0 0.899902V23.0999H30V0.899902H0ZM28.8 21.8999H1.2V6.2999H28.8V21.8999ZM28.8 5.0999H1.2V2.0999H28.8V5.0999Z"
    fill="#047E2E"
  />
  <path d="M25.2269 2.99976H23.6609V4.19976H25.2269V2.99976Z" fill="#047E2E" />
  <path d="M27.837 2.99976H26.271V4.19976H27.837V2.99976Z" fill="#047E2E" />
  <path d="M22.617 2.99976H21.051V4.19976H22.617V2.99976Z" fill="#047E2E" />
  <path d="M13.956 2.99976H2.16602V4.19976H13.956V2.99976Z" fill="#047E2E" />
</svg>;
