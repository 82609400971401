import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './CallToActionButtonDownload.module.css';
import PropTypes from 'prop-types';
import Link from './Link.js';
import urlJoinParameters from '../utils/urlJoinParameters.js';

const CallToActionButtonDownload = ({ children }) => {
  const {
    callToActionButtonDownloadText,
    callToActionButtonDownloadColor,
    callToActionButtonDownloadBackgroundColor,
    callToActionButtonDownloadBorderRadius,
    callToActionButtonDownloadBorderWidth,
    callToActionButtonDownloadBorderStyle,
    callToActionButtonDownloadBorderColor,
    articleLink,
    __source,
    tpcc,
  } = useSearchParamOrDefaultProps();

  const to = urlJoinParameters(articleLink, { __source, tpcc });

  return (
    <div className={styles.callToActionButtonDownloadWrapper}>
      <Link
        to={to}
        color={callToActionButtonDownloadColor}
        backgroundColor={callToActionButtonDownloadBackgroundColor}
        borderRadius={callToActionButtonDownloadBorderRadius}
        borderWidth={callToActionButtonDownloadBorderWidth}
        borderStyle={callToActionButtonDownloadBorderStyle}
        borderColor={callToActionButtonDownloadBorderColor}
      >
        {children}
        {callToActionButtonDownloadText}
      </Link>
    </div>
  );
};

CallToActionButtonDownload.displayName = 'CallToActionButtonDownload';

CallToActionButtonDownload.defaultProps = {
  children: null,
};

CallToActionButtonDownload.propTypes = {
  children: PropTypes.node,
};

export default CallToActionButtonDownload;
