function onZephrObserveDelayedTrigger(e) {
  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrObserveDelayedTrigger', e);
  }

  const {
    detail: { selector, showDelayMilliseconds },
  } = e;

  if (!selector) {
    console.error(
      'Web.Zephr:Error: onZephrObserveDelayedTrigger event requires a selector to be passed.',
    );
  }

  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    console.error(
      `Web.Zephr:Error: onZephrObserveDelayedTrigger event target element "${selector}" does not exist.`,
    );
    return;
  }

  const customEvent = new CustomEvent('onzephrshowcomponent', {
    detail: { selector, showDelayMilliseconds },
  });

  window.dispatchEvent(customEvent);
}

window.addEventListener(
  'zephrobservedelayedtrigger',
  onZephrObserveDelayedTrigger,
);

export default onZephrObserveDelayedTrigger;
