import PropTypes from 'prop-types';

function IconStream({
  fill = 'var(--primaryColor)',
  backgroundFill = 'var(--secondaryColor)',
}) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.80007V12.1001H0.800049V3.70007H16.4V6.60007H15.2C14.538 6.60007 14 7.13807 14 7.80007Z"
        fill={backgroundFill}
      />
      <path
        d="M19.2 8.99994H14.8V14.6999H19.2V8.99994Z"
        fill={backgroundFill}
      />
      <path
        d="M18.8 6.60005H17.2V3.30005C17.2 3.08005 17.02 2.90005 16.8 2.90005H0.4C0.18 2.90005 0 3.08005 0 3.30005V14.1001C0 14.3201 0.18 14.5001 0.4 14.5001H8.2V16.3001H6.4C6.18 16.3001 6 16.4801 6 16.7001C6 16.9201 6.18 17.1001 6.4 17.1001H8.2H9H10.8C11.02 17.1001 11.2 16.9201 11.2 16.7001C11.2 16.4801 11.02 16.3001 10.8 16.3001H9V14.5001H14V15.9001C14 16.5621 14.538 17.1001 15.2 17.1001H18.8C19.462 17.1001 20 16.5621 20 15.9001V7.80005C20 7.13805 19.462 6.60005 18.8 6.60005ZM14 13.7001H0.8V12.9001H14V13.7001ZM14 7.80005V12.1001H0.8V3.70005H16.4V6.60005H15.2C14.538 6.60005 14 7.13805 14 7.80005ZM19.2 15.9001C19.2 16.1201 19.02 16.3001 18.8 16.3001H15.2C14.98 16.3001 14.8 16.1201 14.8 15.9001V15.5001H19.2V15.9001ZM19.2 14.7001H14.8V9.00005H19.2V14.7001ZM19.2 8.20005H14.8V7.80005C14.8 7.58005 14.98 7.40005 15.2 7.40005H18.8C19.02 7.40005 19.2 7.58005 19.2 7.80005V8.20005Z"
        fill={fill}
      />
      <path
        d="M16.4 12.7259C16.43 12.7259 16.46 12.7199 16.49 12.7039L17.842 12.0279C17.99 11.9539 17.99 11.7439 17.842 11.6699L16.49 10.9939C16.46 10.9799 16.43 10.9719 16.4 10.9719C16.296 10.9719 16.2 11.0559 16.2 11.1719V12.5239C16.2 12.6419 16.296 12.7259 16.4 12.7259Z"
        fill={fill}
      />
      <path
        d="M10.884 7.74206L7.77795 6.19006C7.74595 6.17406 7.71395 6.16406 7.68195 6.15606C7.67195 6.15406 7.65995 6.15406 7.64995 6.15206C7.63195 6.15006 7.61595 6.14606 7.59995 6.14606C7.59595 6.14606 7.58995 6.14806 7.58595 6.14806C7.57595 6.14806 7.56795 6.15006 7.55795 6.15006C7.53595 6.15206 7.51595 6.15606 7.49595 6.16206C7.48595 6.16406 7.47795 6.16806 7.46795 6.17006C7.44595 6.17806 7.42595 6.18806 7.40595 6.19806C7.39995 6.20206 7.39395 6.20406 7.38795 6.20806C7.36195 6.22406 7.33995 6.24206 7.31795 6.26406C7.31195 6.27006 7.30795 6.27606 7.30395 6.28006C7.28795 6.29606 7.27395 6.31406 7.26195 6.33406C7.25595 6.34206 7.25195 6.35206 7.24795 6.36006C7.23795 6.38006 7.22995 6.40006 7.22195 6.42006C7.21795 6.43006 7.21595 6.43806 7.21395 6.44806C7.20595 6.48206 7.19995 6.51406 7.19995 6.54806V9.65406C7.19995 9.68806 7.20595 9.72006 7.21195 9.75006C7.21395 9.76006 7.21795 9.76806 7.21995 9.77806C7.22595 9.80006 7.23595 9.82006 7.24595 9.83806C7.24995 9.84606 7.25595 9.85606 7.25995 9.86406C7.27195 9.88406 7.28595 9.90206 7.30195 9.91806C7.30595 9.92406 7.30995 9.93006 7.31595 9.93406C7.33795 9.95606 7.35995 9.97406 7.38595 9.99006C7.39195 9.99406 7.39795 9.99606 7.40395 10.0001C7.42395 10.0121 7.44595 10.0221 7.46795 10.0301C7.47795 10.0341 7.48595 10.0361 7.49595 10.0381C7.51595 10.0441 7.53795 10.0481 7.55795 10.0501C7.56795 10.0521 7.57595 10.0521 7.58595 10.0521C7.58995 10.0521 7.59595 10.0541 7.59995 10.0541C7.61595 10.0541 7.63395 10.0501 7.64995 10.0481C7.65995 10.0461 7.67195 10.0461 7.68195 10.0441C7.71395 10.0381 7.74595 10.0261 7.77795 10.0101L10.884 8.45806C10.994 8.40206 11.064 8.31006 11.092 8.20606C11.138 8.03406 11.068 7.83406 10.884 7.74206Z"
        fill={fill}
      />
    </svg>
  );
}

IconStream.propTypes = {
  fill: PropTypes.string,
  backgroundFill: PropTypes.string,
};

export default IconStream;
