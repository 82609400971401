import { useEffect, useRef } from 'react';
import { useScript } from 'usehooks-ts';
import config from './useFetchRecaptchaToken.config.json';
import deriveRuntimeEnvironment from '../utils/deriveRuntimeEnvironment.js';

const useFetchRecaptchaToken = () => {
  const recaptchaReadyPromiseRef = useRef(Promise.withResolvers());

  const runtimeEnv =
    deriveRuntimeEnvironment() === 'production' ? 'production' : 'default';

  const { RECAPTCHA_LIBRARY_SOURCE, RECAPTCHA_PUBLIC_SITEKEY } =
    config[runtimeEnv];

  const urlSearchParams = new URLSearchParams({
    render: RECAPTCHA_PUBLIC_SITEKEY,
  });
  const recaptchaScriptSrc = `${RECAPTCHA_LIBRARY_SOURCE}?${urlSearchParams.toString()}`;
  const status = useScript(recaptchaScriptSrc, {
    removeOnUnmount: false,
  });

  // reCAPTCHA tokens expire after two minutes. If you're protecting an action with reCAPTCHA,
  // make sure to call execute when the user takes the action rather than on page load.
  const fetchRecaptchaToken = async () => {
    try {
      await Promise.race([
        recaptchaReadyPromiseRef.current.promise,

        // Timeout after X milliseconds
        new Promise((_, reject) => {
          setTimeout(
            () =>
              reject(
                'Web.Zephr:error:useFetchRecaptchaToken: timed out waiting for recaptcha ready promise to resolve',
              ),
            30000,
          );
        }),
      ]);
    } catch (e) {
      console.error(e);
      return e;
    }

    try {
      const token = await window.grecaptcha.enterprise.execute(
        RECAPTCHA_PUBLIC_SITEKEY,
        {
          action: 'submit',
        },
      );

      return token;
    } catch (e) {
      console.error(e);

      return e;
    }
  };

  useEffect(() => {
    if (status === 'ready') {
      // Async work happens inside recaptcha library and this has to be completed
      // before we can call execute to fetch a recaptcha key
      try {
        window.grecaptcha.enterprise.ready(() => {
          recaptchaReadyPromiseRef.current.resolve(() => true);
        });
      } catch (e) {
        console.error(e);
        recaptchaReadyPromiseRef.current.reject(() => false);
      }
    }
  }, [status]);

  return fetchRecaptchaToken;
};

export default useFetchRecaptchaToken;
