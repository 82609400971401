import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './RssFeedArticleTitle.module.css';

const RssFeedArticleTitle = () => {
  const { rssFeedArticleTitle, rssFeedArticleTitleColor: color } =
    useSearchParamOrDefaultProps();

  return (
    <article className={styles.rssFeedArticleTitleWrapper} style={{ color }}>
      <div className={styles.rssFeedArticleTitle}>{rssFeedArticleTitle}</div>
    </article>
  );
};

RssFeedArticleTitle.displayName = 'rssFeedArticleTitle';

export default RssFeedArticleTitle;
