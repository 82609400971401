const scope = typeof global === 'undefined' ? window : global;

// Polyfill for withResolvers from
// https://github.com/ungap/with-resolvers/blob/main/index.js
Promise.withResolvers ||
  (Promise.withResolvers = function withResolvers() {
    var a,
      b,
      c = new this(function (resolve, reject) {
        a = resolve;
        b = reject;
      });
    return { resolve: a, reject: b, promise: c };
  });

scope.__DEV__ = process.env.NODE_ENV === 'development';
scope.__TEST__ = process.env.NODE_ENV === 'test';
scope.__PROD__ = process.env.NODE_ENV === 'production';
