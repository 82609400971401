/**
 * Splits a string around a specified target word.
 *
 * @param {string} words - The input string to be split.
 * @param {string} [targetWord='with'] - The word around which the string should be split. Default is 'with'.
 *
 * @returns {string[]} An array of strings. If the target word is found, the array will contain three elements:
 *                     1) The part of the string before the target word,
 *                     2) The target word itself, and
 *                     3) The part of the string after the target word.
 *                     If the target word is not found, the array will contain the original string as its only element.
 *
 * @example
 * const input = "CNBC INVESTING CLUB WITH JIM CRAMER";
 * const output = splitAroundTargetWord(input, 'WITH');
 * console.log(output); // ["CNBC INVESTING CLUB", "WITH", "JIM CRAMER"]
 */

function splitAroundTargetWord(words, targetWord = 'with') {
  const upperTargetWord = targetWord.toUpperCase();
  let wordsArr = words.split(' ');
  let targetIndex = wordsArr.indexOf(upperTargetWord);

  if (targetIndex === -1) {
    return [words]; // Return the original string as an array if target word is not found.
  }

  let firstPart = wordsArr.slice(0, targetIndex).join(' ');
  let secondPart = wordsArr.slice(targetIndex + 1).join(' ');

  return [firstPart, wordsArr[targetIndex], secondPart];
}

export default splitAroundTargetWord;
