import React from 'react';
import PropTypes from 'prop-types';

const IconNewsletter = ({ style }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_741_1304)">
      <path
        d="M3.04004 2.72161C3.04004 2.54561 3.18404 2.40161 3.36004 2.40161H4.72644H5.78404H10.216H11.2736H12.64C12.816 2.40161 12.96 2.54561 12.96 2.72161V3.68321V4.48641V7.81761L8.00004 11.5872L3.04004 7.81761V4.48641V3.68321V2.72161Z"
        fill="#CFD8E2"
      />
      <path
        d="M7.51995 3.68628H4.31995V6.24628H7.51995V3.68628Z"
        fill="white"
      />
      <path
        d="M6.87995 4.32628V5.60628H4.95995V4.32628H6.87995ZM7.51995 3.68628H4.31995V6.24628H7.51995V3.68628Z"
        fill="#001E5A"
      />
      <path
        d="M11.3599 4.32628H8.63995C8.46395 4.32628 8.31995 4.18228 8.31995 4.00628C8.31995 3.83028 8.46395 3.68628 8.63995 3.68628H11.3599C11.5359 3.68628 11.6799 3.83028 11.6799 4.00628C11.6799 4.18228 11.5359 4.32628 11.3599 4.32628Z"
        fill="#001E5A"
      />
      <path
        d="M11.3599 6.24645H8.63995C8.46395 6.24645 8.31995 6.10245 8.31995 5.92645C8.31995 5.75045 8.46395 5.60645 8.63995 5.60645H11.3599C11.5359 5.60645 11.6799 5.75045 11.6799 5.92645C11.6799 6.10245 11.5359 6.24645 11.3599 6.24645Z"
        fill="#001E5A"
      />
      <path
        d="M11.3599 8.16637H4.63995C4.46395 8.16637 4.31995 8.02237 4.31995 7.84637C4.31995 7.67037 4.46395 7.52637 4.63995 7.52637H11.3599C11.5359 7.52637 11.6799 7.67037 11.6799 7.84637C11.6799 8.02237 11.5359 8.16637 11.3599 8.16637Z"
        fill="#001E5A"
      />
      <path
        d="M6.87996 4.32642V5.60642H4.95996V4.32642H6.87996Z"
        fill="white"
      />
      <path
        d="M13.6 4.1744V2.7264C13.6 2.1968 13.1696 1.7664 12.64 1.7664H10.3232L8 0L5.6768 1.7664H3.36C2.8304 1.7664 2.4 2.1968 2.4 2.7264V4.1744L0 5.9984V15.04C0 15.5696 0.4304 16 0.96 16H15.04C15.2784 16 15.4976 15.912 15.6656 15.7664C15.6672 15.7648 15.6704 15.7632 15.672 15.76C15.872 15.584 16 15.328 16 15.04V5.9984L13.6 4.1744ZM13.6 4.9776L15.1504 6.1568L13.6 7.336V4.9776ZM8 0.8032L9.2656 1.7648H6.7344L8 0.8032ZM3.04 4.4912V3.688V2.7264C3.04 2.5504 3.184 2.4064 3.36 2.4064H4.7264H5.784H10.216H11.2736H12.64C12.816 2.4064 12.96 2.5504 12.96 2.7264V3.688V4.4912V7.8224L8 11.592L3.04 7.8224V4.4912ZM2.4 4.9776V7.336L0.8496 6.1568L2.4 4.9776ZM0.64 15.0352V6.8016L6.056 10.9184L0.64 15.0352ZM1.2704 15.36L6.5856 11.32L8 12.3952L9.4144 11.32L14.7296 15.36H1.2704ZM15.36 15.0352L9.944 10.9184L15.36 6.8016V15.0352Z"
        fill="#001E5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_741_1304">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconNewsletter;

IconNewsletter.propTypes = {
  style: PropTypes.shape(React.CSSProperties),
};

IconNewsletter.defaultProps = {
  style: {},
};
