import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link.js';
import { useRssFeedContext } from './RssFeedDataComponent.js';
import styles from './CallToActionRssFeedButton.module.css';
import urlJoinParameters from '../utils/urlJoinParameters.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const CallToActionRssFeedButton = ({ children }) => {
  const { link, loading, error } = useRssFeedContext();

  const {
    callToActionButtonText,
    callToActionButtonFontFamily: fontFamily,
    callToActionButtonTextColor: color,
    callToActionButtonBackgroundColor: backgroundColor,
    callToActionButtonBorderRadius: borderRadius,
    callToActionButtonBorderWidth: borderWidth,
    callToActionButtonBorderStyle: borderStyle,
    callToActionButtonBorderColor: borderColor,
    __source,
    tpcc,
  } = useSearchParamOrDefaultProps();

  const to = urlJoinParameters(link, { __source, tpcc });

  return (
    <div className={styles.callToActionRssFeedButtonWrapper}>
      {error && <div>{error}</div>}
      {loading && (
        <div className={styles.callToActionRssFeedLoadingText}>loading...</div>
      )}
      {!loading && to && (
        <Link
          to={to}
          fontFamily={fontFamily}
          color={color}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          borderWidth={borderWidth}
          borderStyle={borderStyle}
          borderColor={borderColor}
        >
          {children || callToActionButtonText}
        </Link>
      )}
    </div>
  );
};

CallToActionRssFeedButton.displayName = 'CallToActionRssFeedButton';

CallToActionRssFeedButton.defaultProps = {
  children: null,
};

CallToActionRssFeedButton.propTypes = {
  children: PropTypes.node,
};

export default CallToActionRssFeedButton;
