import Inline from './Inline.js';
import Logo from './Logo.js';
import EmailCapture from './EmailCapture.js';
import Theme from './Theme.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import Background from './Background.js';
import styles from './InlineWarrenBuffet.module.css';
import { useState } from 'react';

const InlineWarrenBuffet = () => {
  const {
    newsletterHeaderText,
    newsletterSubheaderText,
    newsletterDescriptionText,
    inlineTitleTextAlign: textAlign,
    inlineTitleFontWeight: fontWeight,
  } = useSearchParamOrDefaultProps();

  const inlineTitleStyle = {
    textAlign,
    fontWeight,
  };

  const [isSubscribed, setIsSubscribed] = useState(false);

  return (
    <Theme>
      <Inline>
        <Background>
          <div className={styles.inlineWarrenBuffetWrapper}>
            <div className={styles.inlineWarrenBuffetTitleWrapper}>
              <Logo />
              <div className={styles.inlineTitleWrapper}>
                <div className={styles.inlineTitle} style={inlineTitleStyle}>
                  <span className={styles.inlineWarrenBuffetTitleStart}>
                    {newsletterHeaderText}
                  </span>{' '}
                  <span className={styles.inlineWarrenBuffetTitleEnd}>
                    {newsletterSubheaderText}
                  </span>
                </div>
              </div>
            </div>
            {!isSubscribed && (
              <div className={styles.newsletterDescriptionWrapper}>
                <div className={styles.newsletterDescription}>
                  {newsletterDescriptionText}
                </div>
              </div>
            )}
            <EmailCapture
              showSubscribedSuccessMessage={(value) => setIsSubscribed(value)}
            />
          </div>
        </Background>
      </Inline>
    </Theme>
  );
};

InlineWarrenBuffet.displayName = 'InlineWarrenBuffet';

export default InlineWarrenBuffet;
