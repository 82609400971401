const deriveCardsFromProps = (props, count = 3) => {
  const cards = [];

  for (let i = 1; i <= count; i++) {
    if (props[`showCard${i}`] === 'true') {
      cards.push({
        key: `card-${i}`,
        showCard: props[`showCard${i}`],
        cardImageSrc: props[`cardImageSrc${i}`],
        cardImageAlt: props[`cardImageAlt${i}`],
        cardTitle: props[`cardTitle${i}`],
        cardSubtitle: props[`cardSubtitle${i}`],
        cardTitleColor: props[`cardTitleColor${i}`],
        cardSubtitleColor: props[`cardSubtitleColor${i}`],
      });
    }
  }

  return cards;
};

export default deriveCardsFromProps;
