import styles from './CheckBox.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CheckBox = ({
  isChecked,
  handleCheckboxChange,
  title,
  description,
  shouldHideDescriptionsForSmallDevices,
}) => {
  return (
    <div className={styles.checkBoxWrapper}>
      <input
        type="checkbox"
        checked={isChecked}
        className={styles.checkBox}
        onChange={handleCheckboxChange}
      />
      <div className={styles.checkBoxLabelWrapper}>
        <label className={styles.checkBoxLabelTitle}>{title}</label>
        <label
          className={classNames(styles.checkBoxLabelDescription, {
            [styles.shouldHideDescriptionsForSmallDevices]:
              shouldHideDescriptionsForSmallDevices,
          })}
        >
          {description}
        </label>
      </div>
    </div>
  );
};

CheckBox.displayName = 'CheckBox';

CheckBox.defaultProps = {
  title: '',
  description: '',
  isChecked: false,
  handleCheckboxChange: () => {},
};

CheckBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isChecked: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  shouldHideDescriptionsForSmallDevices: PropTypes.bool,
};

export default CheckBox;
