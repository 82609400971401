import PropTypes from 'prop-types';

function IconExperts({
  fill = 'var(--primaryColor)',
  backgroundFill = 'var(--secondaryColor)',
}) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 9.1439V4.7999H17.4001V9.5999H11.8V9.1439Z"
        fill={backgroundFill}
      />
      <path
        d="M9.002 4.19605C9.222 4.19605 9.4 4.01805 9.4 3.79605V2.80005C9.4 2.58005 9.222 2.40005 9.002 2.40005C8.782 2.40005 8.604 2.57805 8.604 2.80005V3.79805C8.604 4.01605 8.782 4.19605 9.002 4.19605Z"
        fill={fill}
      />
      <path
        d="M5.05998 5.21192C5.13798 5.31992 5.25998 5.37592 5.38198 5.37592C5.46398 5.37592 5.54398 5.35192 5.61598 5.29992C5.79398 5.16992 5.83198 4.91992 5.70398 4.74192L5.11998 3.93392C4.99198 3.75592 4.74198 3.71592 4.56398 3.84592C4.38598 3.97592 4.34798 4.22592 4.47598 4.40392L5.05998 5.21192Z"
        fill={fill}
      />
      <path
        d="M3.26595 7.69005L2.31995 7.38205C2.11195 7.31405 1.88795 7.42805 1.81995 7.63805C1.75195 7.84805 1.86595 8.07205 2.07595 8.14005L3.02195 8.44805C3.06195 8.46205 3.10395 8.46805 3.14395 8.46805C3.31195 8.46805 3.46795 8.36005 3.52195 8.19205C3.58795 7.98405 3.47395 7.75805 3.26595 7.69005Z"
        fill={fill}
      />
      <path
        d="M3.02006 11.5138L2.07406 11.8218C1.86606 11.8898 1.75006 12.1158 1.81806 12.3238C1.87406 12.4918 2.03006 12.5998 2.19806 12.5998C2.23806 12.5998 2.28006 12.5938 2.32006 12.5798L3.26606 12.2718C3.47406 12.2038 3.59006 11.9778 3.52206 11.7698C3.45206 11.5598 3.22806 11.4458 3.02006 11.5138Z"
        fill={fill}
      />
      <path
        d="M10.8 17.3999H7.20005C6.98005 17.3999 6.80005 17.5799 6.80005 17.7999C6.80005 18.0199 6.98005 18.1999 7.20005 18.1999H10.8C11.02 18.1999 11.2 18.0199 11.2 17.7999C11.2 17.5799 11.02 17.3999 10.8 17.3999Z"
        fill={fill}
      />
      <path
        d="M10.4 18.7999H7.59995C7.37995 18.7999 7.19995 18.9799 7.19995 19.1999C7.19995 19.4199 7.37995 19.5999 7.59995 19.5999H10.4C10.62 19.5999 10.8 19.4199 10.8 19.1999C10.8 18.9799 10.62 18.7999 10.4 18.7999Z"
        fill={fill}
      />
      <path
        d="M18.2001 10.4V3.99996H13.0001V2.79996C13.0001 1.91796 13.7181 1.19996 14.6001 1.19996C15.4821 1.19996 16.2001 1.91796 16.2001 2.79996C16.2001 3.01996 16.3801 3.19996 16.6001 3.19996C16.8201 3.19996 17.0001 3.01996 17.0001 2.79996C17.0001 1.47596 15.9241 0.399963 14.6001 0.399963C13.2761 0.399963 12.2001 1.47596 12.2001 2.79996V3.99996H11.0001V5.65196C10.3961 5.36196 9.71615 5.19996 9.00015 5.19996C6.46015 5.19996 4.40015 7.23596 4.40015 9.72996C4.40015 11.262 4.83815 11.874 5.69215 13.1C5.89015 13.362 6.08615 13.67 6.30615 13.998C6.76615 14.698 6.76615 15.486 6.76615 15.946V15.99V16.406C6.76615 16.624 6.94215 16.8 7.16015 16.8H10.8641C11.0821 16.8 11.2581 16.624 11.2581 16.406V15.99V15.946C11.2581 15.486 11.2581 14.698 11.7181 13.998C11.9381 13.648 12.1561 13.364 12.3321 13.1C13.0321 12.07 13.4501 11.472 13.5681 10.398H18.2001V10.4ZM11.8001 9.14396V4.79996H17.4001V9.59996H11.8001V9.14396ZM11.7181 12.576L11.6641 12.656C11.6021 12.75 11.5341 12.846 11.4621 12.948C11.3341 13.128 11.1901 13.332 11.0441 13.566C10.4581 14.464 10.4581 15.428 10.4581 15.946V15.99V16H7.56615V15.99V15.946C7.56615 15.426 7.56615 14.46 6.97215 13.554L6.84015 13.356C6.67015 13.1 6.50815 12.856 6.34215 12.632L6.32415 12.606C5.50615 11.434 5.20015 10.996 5.20015 9.72996C5.20015 7.67396 6.90415 5.99996 9.00015 5.99996C9.73415 5.99996 10.4181 6.20596 11.0001 6.56196V8.98796V10.4H12.7601C12.6541 11.198 12.3301 11.676 11.7181 12.576Z"
        fill={fill}
      />
      <path
        d="M14.6 5.99991C14.158 5.99991 13.8 6.35791 13.8 6.79991C13.8 7.09591 13.962 7.34991 14.2 7.48991V7.99991C14.2 8.21991 14.38 8.39991 14.6 8.39991C14.82 8.39991 15 8.21991 15 7.99991V7.48991C15.238 7.35191 15.4 7.09591 15.4 6.79991C15.4 6.35791 15.042 5.99991 14.6 5.99991Z"
        fill={fill}
      />
    </svg>
  );
}

IconExperts.propTypes = {
  fill: PropTypes.string,
  backgroundFill: PropTypes.string,
};

export default IconExperts;
