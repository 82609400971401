import React from 'react';
import './globals.js';
import ReactDOM from 'react-dom/client';
import App from './components/App.js';
import './events/onZephrClose.js';
import './events/onZephrResize.js';

async function render() {
  try {
    const rootElement = document.getElementById('root');
    const root = ReactDOM.createRoot(rootElement);

    return root.render(<App />);
  } catch (e) {
    return e;
  }
}

if (__DEV__ || __PROD__) {
  render();
}

export default render;
