import bubbleEventToParentIframe from '../utils/bubbleEventToParentPage.js';
import shouldBubbleEventToParentIframe from '../utils/shouldBubbleEventToParentPage.js';

/*
  Inspired by how AMP handles iframe resize
  https://amp.dev/documentation/components/amp-iframe#iframe-resizing
*/
function onZephrResize(e) {
  if (shouldBubbleEventToParentIframe()) {
    if (__DEV__) {
      console.info('Web.Zephr:info: bubble onZephrResize event');
    }
    return bubbleEventToParentIframe(e);
  }

  if (__DEV__) {
    console.log('Web.Zephr:event: onZephrResize', e);
  }

  const {
    detail: { selector, heightPixels: rawHeightPixels },
  } = e;
  if (!selector) {
    console.error(
      'Web.Zephr:Error: onZephrResize event requires a selector to be passed.',
    );
  }
  if (!rawHeightPixels) {
    console.error(
      'Web.Zephr:Error: onZephrResize event requires heightPixels to be passed.',
    );
  }

  const targetElement = document.querySelector(selector);

  if (!targetElement) {
    console.error(
      `Web.Zephr:Error: onZephrResize event target element "${selector}" does not exist.`,
    );
    return;
  }

  // Ensure we have a clean input that is just numbers
  const nonNumberPattern = /[^0-9.]+/;
  const heightPixels = `${rawHeightPixels}`.replace(nonNumberPattern, '');

  return (targetElement.style.height = `${heightPixels}px`);
}

window.addEventListener('onzephrresize', onZephrResize);

export default onZephrResize;
