import TestMockButtonSignInUser from './TestMockButtonSignInUser.js';
import TestMockButtonSignOutUser from './TestMockButtonSignOutUser.js';
import styles from './TestMockGlobalTopNav.module.css';

function TestMockGlobalTopNav() {
  return (
    <nav id="GlobalNavigation" className={styles.nav}>
      <center>TestMockGlobalTopNav</center>
      <TestMockButtonSignInUser />
      <TestMockButtonSignOutUser />
    </nav>
  );
}

export default TestMockGlobalTopNav;
