import styles from './GateValuePropositions.module.css';
import IconAnalysis from './IconAnalysis.js';
import IconTools from './IconTools.js';
import IconStream from './IconStream.js';
import IconExperts from './IconExperts.js';
import IconEvents from './IconEvents.js';
import IconPortfolio from './IconPortfolio.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

const valuePropositionsByTheme = {
  default: [
    { text: 'Market news & analysis', icon: <IconAnalysis /> },
    { text: 'Essential investing tools', icon: <IconAnalysis /> },
    { text: 'Stream CNBC live', icon: <IconAnalysis /> },
    { text: 'Exclusive access to experts', icon: <IconAnalysis /> },
  ],
  pro: [
    { text: 'Market news & analysis', icon: <IconAnalysis /> },
    { text: 'Essential investing tools', icon: <IconTools /> },
    { text: 'Stream CNBC live', icon: <IconStream /> },
    { text: 'Exclusive access to experts', icon: <IconExperts /> },
  ],
  investingClub: [
    { text: 'Jim Cramer Trust Portfolio', icon: <IconPortfolio /> },
    { text: 'Live Meetings & Events', icon: <IconEvents /> },
    { text: 'Access Anywhere', icon: <IconStream /> },
    { text: 'Check in Daily With Jim', icon: <IconExperts /> },
  ],
};

function GateValuePropositions() {
  const { theme } = useSearchParamOrDefaultProps();

  const valuePropositions = valuePropositionsByTheme[theme];

  return (
    <section className={styles.GateValuePropositions}>
      <ul className={styles.valuePropositionList}>
        {valuePropositions.map(({ text, icon }) => {
          return (
            <li key={text} className={styles.valuePropositionItem}>
              <span className={styles.icon}>{icon}</span>
              <span className={styles.valuePropositionText}>{text}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default GateValuePropositions;
