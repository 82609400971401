import PropTypes from 'prop-types';

function IconPlayButton({
  stroke = '#E3E3E3',
  fill = '#E3E3E3',
  maxHeight = '44px',
}) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxHeight }}
    >
      <circle cx="48" cy="48" r="45.5" stroke={stroke} strokeWidth="5" />
      <path
        d="M73.75 49.299L76 48L73.75 46.701L36.25 25.0503L34 23.7513V26.3494V69.6506V72.2487L36.25 70.9497L73.75 49.299Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="3"
      />
    </svg>
  );
}

IconPlayButton.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default IconPlayButton;
