import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleTitleUnderlineVariant.module.css';
import splitAroundTargetWord from '../utils/splitAroundTargetWord.js';
import PropTypes from 'prop-types';

const ArticleTitleUnderlineVariant = ({ title, variant, fontColor }) => {
  const result = splitAroundTargetWord(title, variant);
  const { articleTitleUnderlineVariantFontColor } =
    useSearchParamOrDefaultProps();

  // In the event of an article title (underline variant) inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleTitleUnderlineVariantFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleTitleUnderlineVariantFontColor;

  const style = {
    color,
    borderBottomColor: color,
  };

  return (
    <>
      {result.map((title, idx) => {
        if (title === variant) {
          return (
            <span
              key={idx}
              className={styles.articleTitleUnderlineVariantWrapper}
            >
              <span
                className={styles.articleTitleUnderlineVariant}
                style={style}
              >
                {title}
              </span>
            </span>
          );
        } else {
          return (
            <span key={idx} style={style}>
              {title}
            </span>
          );
        }
      })}
    </>
  );
};

ArticleTitleUnderlineVariant.displayName = 'ArticleTitleUnderlineVariant';

ArticleTitleUnderlineVariant.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  fontColor: PropTypes.string,
};

ArticleTitleUnderlineVariant.defaultProps = {
  title: '',
  variant: '',
  fontColor: '',
};

export default ArticleTitleUnderlineVariant;
