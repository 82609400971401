/* eslint-disable react/prop-types */
import { useLayoutEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import '../integration.css';
import '../integration.js';
import styles from './TestPage.module.css';
import useMockWindowBouncex from '../hooks/useMockWindowBouncex.js';
import useMockWindowZephr from '../hooks/useMockWindowZephr.js';
import TestMockGlobalBottomNav from './TestMockGlobalBottomNav.js';
import TestMockGlobalTopNav from './TestMockGlobalTopNav.js';
import TestMockFooter from './TestMockFooter.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import testGetOutcomeEntrypointGateSelectors from '../utils/testGetOutcomeEntrypointGateSelectors.js';

function TestPage() {
  const { componentName } = useParams();
  const searchParams = useSearchParams();

  const props = useSearchParamOrDefaultProps();
  const { analyticsOutcomeId } = props;

  useMockWindowBouncex();
  useMockWindowZephr(analyticsOutcomeId);

  useLayoutEffect(() => {
    const onZephrInitializeComponentEvent = new CustomEvent(
      'onzephrinitializecomponent',
      {
        detail: {
          selector: 'body',
          componentName,
          urlSearchParams: props,
        },
      },
    );

    window.dispatchEvent(onZephrInitializeComponentEvent);

    return () => {
      const testIframeEl = document.querySelector('iframe');
      if (!testIframeEl) {
        // nothing to cleanup
        return;
      }

      return testIframeEl.remove();
    };
  }, [componentName]);

  const isMobileOrTablet = window.matchMedia('(max-width: 767px)').matches;
  const isExitTriggerOnMobileOrTablet =
    isMobileOrTablet && props.trigger === 'exit';

  // add scrollable height for exit trigger on mobile
  const articleStyles = {
    height: isExitTriggerOnMobileOrTablet ? '100vh' : '100%',
    maxWidth: '70vw',
    margin: '0 auto',
  };

  function shouldRenderMockArticle() {
    if (componentName.startsWith('Page')) {
      return false;
    }

    return true;
  }

  return (
    <>
      <TestMockGlobalTopNav />
      <TestMockGlobalBottomNav />
      {shouldRenderMockArticle() ? (
        <div className={styles.pageWrapper}>
          <i>Parent page</i>
          <h1>Test Page</h1>
          <p>
            This page renders <b>{componentName}</b> in an iframe similar to
            production
          </p>
          <p>
            Show campaign based on trigger: <b>{props.trigger}</b>
          </p>

          <div className="Article" style={articleStyles}>
            <center>
              <i>mock article</i>
            </center>

            <div className="group">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ultrices neque ornare aenean euismod elementum nisi quis.
              </p>
              <p>
                Diam vulputate ut pharetra sit amet aliquam id. Lectus nulla at
                volutpat diam ut venenatis tellus in metus. Praesent elementum
                facilisis leo vel fringilla.
              </p>
              <p>
                Vestibulum sed arcu non odio euismod lacinia at quis risus.
                Senectus et netus et malesuada fames.
              </p>
            </div>

            <div className="group">
              <p>
                Morbi blandit cursus risus at ultrices mi tempus. Ac orci
                phasellus egestas tellus rutrum tellus. Euismod in pellentesque
                massa placerat duis ultricies lacus sed turpis.
              </p>
              <p>
                Pharetra pharetra massa massa ultricies. A cras semper auctor
                neque vitae tempus quam. Aliquet sagittis id consectetur purus.
              </p>
              <p>
                Sit amet nulla facilisi morbi tempus iaculis. Amet luctus
                venenatis lectus magna fringilla urna porttitor rhoncus dolor.
                Sit amet venenatis urna cursus eget nunc scelerisque viverra.
              </p>
              <p>
                Vestibulum lectus mauris ultrices eros in cursus turpis. Augue
                lacus viverra vitae congue eu consequat ac felis donec. Sit amet
                venenatis urna cursus eget nunc scelerisque viverra. Elementum
                tempus egestas sed sed risus pretium quam vulputate.
              </p>
              <p>
                Pellentesque dignissim enim sit amet venenatis. Dignissim enim
                sit amet venenatis urna. Aliquam id diam maecenas ultricies mi
                eget mauris pharetra et. Vitae elementum curabitur vitae nunc
                sed velit dignissim sodales ut.
              </p>
              <p>
                Lobortis feugiat vivamus at augue eget arcu. Purus ut faucibus
                pulvinar elementum integer enim neque volutpat ac. Massa sed
                elementum tempus egestas sed sed. Ultrices gravida dictum fusce
                ut placerat orci nulla pellentesque dignissim.
              </p>
              <p>
                Et malesuada fames ac turpis egestas. Turpis egestas pretium
                aenean pharetra magna ac placerat. Morbi leo urna molestie at.
                Porttitor massa id neque aliquam vestibulum morbi blandit.
              </p>
              <p>
                Suspendisse interdum consectetur libero id. Sit amet est
                placerat in egestas erat imperdiet. Neque aliquam vestibulum
                morbi blandit cursus risus at.
              </p>
            </div>
          </div>

          <div
            id="TestOutcomeEntrypointGate"
            className={testGetOutcomeEntrypointGateSelectors()}
          />
        </div>
      ) : (
        <main className={`zephr-page ${styles.zephrPage}`} />
      )}
      <TestMockFooter />
    </>
  );
}

export default TestPage;
