import styles from './Overlay.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GlobalAnalytics from './GlobalAnalytics.js';
import CloseButton from './CloseButton.js';
import useComponentCampaignDefaultProps from '../hooks/useComponentCampaignDefaultProps.js';

function Overlay({ children }) {
  const OverlayClass = classNames(styles.overlayDefaults, styles.overlay);
  const { minWidth, maxWidth, minHeight, maxHeight } =
    useComponentCampaignDefaultProps();

  const style = {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  };

  return (
    <>
      <GlobalAnalytics />
      <section
        id="root-component"
        style={style}
        className={OverlayClass}
        data-testid="Overlay"
      >
        <aside className={styles.overlayCreative}>
          <div className={styles.overlayWrapper}>
            <CloseButton />
            {children}
          </div>
        </aside>
      </section>
    </>
  );
}

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  children: PropTypes.node,
};

Overlay.defaultProps = {
  children: null,
};

export default Overlay;
