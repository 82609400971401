import secondLevelDomains from './isTrustedOrigin.secondLevelDomains.json';

function isTrustedOrigin(origin) {
  const shouldTrustOrigin =
    secondLevelDomains.some((secondLevelDomain) => {
      const topLevelDomain = 'com'; // all of our domains are off of .com (e.g., cnbc.com, nbcnews.com, etc)
      const secondAndTopLevelDomainPattern = new RegExp(
        `${secondLevelDomain}\.${topLevelDomain}`,
        'i', // case insensitive
      );
      const shouldTrustOrigin = origin.match(secondAndTopLevelDomainPattern);

      return shouldTrustOrigin;
    }) || origin === 'http://localhost:3000';

  return shouldTrustOrigin;
}

export default isTrustedOrigin;
