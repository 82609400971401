import BottomBar from './BottomBar.js';
import Logo from './Logo.js';
import ArticleTitle from './ArticleTitle.js';
import ButtonCallToAction from './ButtonCallToAction.js';
import Theme from './Theme.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';

/**
 * A component for displaying a BottomBar with Pro Page targeting.
 * @component
 * @returns {JSX.Element} The rendered JSX element.
 */

const BottomBarProTargeting = () => {
  const { articleLink } = useSearchParamOrDefaultProps();

  return (
    <Theme>
      <BottomBar>
        <Logo />
        <ArticleTitle />
        <ButtonCallToAction href={articleLink} />
      </BottomBar>
    </Theme>
  );
};

BottomBarProTargeting.displayName = 'BottomBarProTargeting';

export default BottomBarProTargeting;
