import Inline from './Inline.js';
import Theme from './Theme.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './InlineAnimatedPodcast.module.css';
import ButtonCallToAction from './ButtonCallToAction.js';
import Background from './Background.js';
import Logo from './Logo.js';
import InlineAnimatedPodcastLogo from './InlineAnimatedPodcastLogo.js';

const InlineAnimatedPodcast = () => {
  const {
    headerText,
    inlineTitleTextAlign: textAlign,
    inlineTitleFontWeight: fontWeight,
    animatedImageSrc,
    articleLink,
  } = useSearchParamOrDefaultProps();

  const inlineTitleStyle = {
    textAlign,
    fontWeight,
  };

  return (
    <Theme>
      <Inline>
        <Background>
          <div className={styles.wrapper}>
            <header style={inlineTitleStyle} className={styles.headerText}>
              {headerText}
            </header>
            <div className={styles.main}>
              {animatedImageSrc ? (
                <img className={styles.center} src={animatedImageSrc} />
              ) : (
                <InlineAnimatedPodcastLogo />
              )}

              <div className={styles.logoWrapper}>
                <Logo />
              </div>

              <div className={styles.callToActionButtonWrapper}>
                <ButtonCallToAction href={articleLink} />
              </div>
            </div>
          </div>
        </Background>
      </Inline>
    </Theme>
  );
};

InlineAnimatedPodcast.displayName = 'InlineAnimatedPodcast';

export default InlineAnimatedPodcast;
