import camelCaseToKebabCase from '../utils/camelCaseToKebabCase.js';

function deriveRootLayoutCssTextFromProps(props) {
  const {
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    position,
    bottom,
    top,
    left,
    right,
    display,
  } = props;

  const style = {
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    position,
    bottom,
    top,
    left,
    right,
    display,
  };

  const cssTextProperties = ['border: none'];
  for (const cssProperty in style) {
    const cssPropertyValue = style[cssProperty];
    cssTextProperties.push(
      `${camelCaseToKebabCase(cssProperty)}: ${cssPropertyValue}`,
    );
  }

  const cssText = cssTextProperties.join(';');

  return cssText;
}

export default deriveRootLayoutCssTextFromProps;
