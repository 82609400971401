import React from 'react';
import PropTypes from 'prop-types';
import styles from './ValuePropositions.module.css';

const ValuePropositions = ({ items }) => {
  if (!items || !Array.isArray(items)) {
    return null;
  }

  return (
    <div className={styles.valueProps}>
      <ul className={styles.valuePropsList}>
        {items &&
          items.map((item, index) => (
            <li key={index}>
              <span>{item.icon}</span>
              <span className={styles.valuePropText}>{item.text}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

ValuePropositions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

export default ValuePropositions;
