import { useFetch } from 'usehooks-ts';

function StatusPage() {
  const assetManifestUrl = `${origin}/asset-manifest.json`;
  const { error, data } = useFetch(assetManifestUrl);

  if (error) {
    return `Error loading asset-manifest.json: ${JSON.stringify(error)}`;
  }

  return (
    <pre>
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
}

export default StatusPage;
