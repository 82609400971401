import getRootPageOrigin from './getRootPageOrigin.js';
import rootPageOriginToIframeOriginMap from './deriveIframeOriginFromRootPageOrigin.json';

function deriveIframeOriginFromRootPageOrigin() {
  const rootPageOrigin = getRootPageOrigin();

  const isPhoenixSandboxOriginPattern = /https:\/\/[0-9]+-sandbox\.cnbc\.com$/i;
  if (rootPageOrigin.match(isPhoenixSandboxOriginPattern)) {
    const iframeOrigin =
      rootPageOriginToIframeOriginMap['https://zephr-templates-stage.cnbc.com'];

    return iframeOrigin;
  }

  const iframeOrigin = rootPageOriginToIframeOriginMap[rootPageOrigin];

  return iframeOrigin;
}

export default deriveIframeOriginFromRootPageOrigin;
