import useFetch from 'use-http';
import Link from './Link.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';
import styles from './RssArticleLinks.module.css';

const RssArticleLinks = ({ WrapperComponent, ItemComponent }) => {
  const { rssFeedUrl, rssFeedArticleLinkColor: color } =
    useSearchParamOrDefaultProps();

  const {
    loading,
    error,
    data: results,
  } = useFetch(rssFeedUrl, { retries: 3 }, []);

  const headlines = (results?.data && results.data) || '';

  if (error) return null;
  if (loading) return null;

  return (
    <WrapperComponent className={styles.rssArticleLinksUl}>
      {headlines.map(({ title, link }) => (
        <ItemComponent key={title} style={{ color }}>
          <Link to={link} color={color} target="_blank">
            {title}
          </Link>
        </ItemComponent>
      ))}
    </WrapperComponent>
  );
};

RssArticleLinks.displayName = 'RssArticleLinks';

RssArticleLinks.defaultProps = {
  WrapperComponent: 'ul',
  ItemComponent: 'li',
};

RssArticleLinks.propTypes = {
  WrapperComponent: PropTypes.elementType,
  ItemComponent: PropTypes.elementType,
};

export default RssArticleLinks;
