/**
 * `BottomBarStockAtNightEmailCapture` is a functional React component that renders an HTML form with a text input,
 * a submit button, and a terms of service checkbox.
 * @returns {JSX.Element} The rendered JSX element.
 */

import styles from './BottomBarStockAtNightEmailCapture.module.css';
import { useState, useEffect } from 'react';
import { validateEmail } from '../utils/validateEmail.js';
import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import PropTypes from 'prop-types';
import getRootSelector from '../utils/getRootSelector.js';
import BottomBarStockAtNightTermsOfService from './BottomBarStockAtNightTermsOfService.js';
import useFetchRecaptchaToken from '../hooks/useFetchRecaptchaToken.js';
import BottomBarStockAtNightEmailCaptureConfig from './BottomBarStockAtNightEmailCapture.config.json';
import $t from '../utils/analyticsEventBus.js';
import deriveRuntimeEnvironment from '../utils/deriveRuntimeEnvironment.js';
import IconArrowSubmitEmail from './IconArrowSubmitEmail.js';
import EmailCaptureSuccess from './EmailCaptureSuccess.js';

const defaultData = {
  brand: 'cnbc',
  pid: '33', // Partner id
};

const BottomBarStockAtNightEmailCapture = ({
  defaultEmail,
  onEmailChange,
  newsletterSubscriptions,
  showSubscribedSuccessMessage,
}) => {
  const {
    newsletters,
    emailCaptureSource,
    emailCaptureLabel,
    emailCaptureErrorText,
    emailCaptureDelayCloseMilliseconds,
    callToActionButtonDownloadText,
    tos = 'true',
    tosCheckBox = 'true',
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
    emailCapturePlaceholder,
    callToActionButtonText,
    callToActionButtonTextColor,
    callToActionButtonBackgroundColor,
    callToActionButtonFontFamily,
    callToActionButtonLoadingText,
    emailCaptureSuccessSignUpText,
  } = useSearchParamOrDefaultProps();

  const callToActionStyle = {
    color: callToActionButtonTextColor,
    backgroundColor: callToActionButtonBackgroundColor,
    fontFamily: callToActionButtonFontFamily,
  };

  const fetchRecaptchaToken = useFetchRecaptchaToken();

  const runtimeEnv = deriveRuntimeEnvironment();

  const [email, setEmail] = useState(defaultEmail);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [uiError, setUiError] = useState('');

  const analyticsParamOptions = {
    analyticsPageId,
    analyticsOutcomeId,
    analyticsTrackingId,
    analyticsOutcomeType,
    analyticsOutcomeDetail,
    analyticsOutcomeSlug,
  };

  useEffect(() => {
    $t('register', 'phx_zephr_email_submit', {
      allowDuplicate: true,
      remote: true,
    });
  }, []);

  const triggerAnalyticsEmailSignUpEvent = () => {
    const action = callToActionButtonText;

    $t('track', 'phx_zephr_email_submit', {
      ...analyticsParamOptions,
      action,
    });
  };

  const onClickCloseEvent = new CustomEvent('onzephrclose', {
    detail: {
      selector: getRootSelector(),
    },
  });

  const delayClose = Number(emailCaptureDelayCloseMilliseconds);

  const handleEmailInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (onEmailChange) {
      onEmailChange(newEmail);
    }
  };

  const isTosRequiredAndUnchecked =
    !isChecked && tosCheckBox === 'true' && tos === 'true';

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid = e.target.checkValidity();

    if (isTosRequiredAndUnchecked) {
      setUiError('Please agree to the terms of service.');
      return;
    }

    if (!isValid) {
      e.target.reportValidity();
      return;
    }

    if (!validateEmail(email)) {
      setUiError('Please enter a valid email address.');
      return;
    }

    if (uiError) setUiError('');

    setIsLoading(true);

    const finalSubscriptions = {
      [newsletters]: true,
      ...newsletterSubscriptions,
    };

    const data = {
      ...defaultData,
      email: email,
      source: emailCaptureSource,
      subscriptions: finalSubscriptions,
    };

    const { CNBC_REGISTER_BASE_URL, EMAIL_CAPTURE_ENDPOINT } =
      BottomBarStockAtNightEmailCaptureConfig[runtimeEnv];

    try {
      const recaptchaToken = await fetchRecaptchaToken();
      const url = `${CNBC_REGISTER_BASE_URL}${EMAIL_CAPTURE_ENDPOINT}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'recaptcha-token': recaptchaToken,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      if (response.ok) {
        triggerAnalyticsEmailSignUpEvent();

        setIsSubscribed(true);
        showSubscribedSuccessMessage(true);
        setIsLoading(false);

        // Some overlays have a delayed close after the user subscribes
        if (!callToActionButtonDownloadText) {
          setTimeout(() => window.dispatchEvent(onClickCloseEvent), delayClose);
        }
      } else {
        const errorMessage =
          responseData?.user_error_message ?? emailCaptureErrorText;
        setUiError(errorMessage ?? responseError.message);
        setIsLoading(false);
      }
    } catch (error) {
      setUiError(error.message ?? emailCaptureErrorText);
      setIsLoading(false);
    }
  };

  const isButtonDisabled = isLoading;

  if (isSubscribed) {
    return (
      <div className={styles.wrapper}>
        {callToActionButtonDownloadText ? (
          <div className={styles.emailCaptureContainer}>
            <EmailCaptureSuccess />
          </div>
        ) : (
          <div className={styles.successMessage}>
            {emailCaptureSuccessSignUpText}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleFormSubmit} className={styles.form}>
        <div className={styles.label}>{emailCaptureLabel}</div>
        <div className={styles.fieldset}>
          <input
            type="email"
            value={email}
            onChange={handleEmailInputChange}
            placeholder={emailCapturePlaceholder}
            aria-label={emailCapturePlaceholder}
            className={styles.input}
            required
          />

          <button
            type="submit"
            disabled={isButtonDisabled}
            aria-disabled={isButtonDisabled}
            className={styles.button}
            aria-label={callToActionButtonText}
            style={callToActionStyle}
          >
            <IconArrowSubmitEmail />
            {isLoading ? callToActionButtonLoadingText : callToActionButtonText}
          </button>
        </div>

        {tosCheckBox && (
          <BottomBarStockAtNightTermsOfService
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        )}

        {uiError && <div className={styles.error}>{uiError}</div>}
      </form>
    </div>
  );
};

BottomBarStockAtNightEmailCapture.displayName =
  'BottomBarStockAtNightEmailCapture';

BottomBarStockAtNightEmailCapture.defaultProps = {
  defaultEmail: '',
  newsletterSubscriptions: {},
  onEmailChange: () => {},
  showSubscribedSuccessMessage: () => {},
};

BottomBarStockAtNightEmailCapture.propTypes = {
  defaultEmail: PropTypes.string,
  newsletterSubscriptions: PropTypes.object,
  onEmailChange: PropTypes.func,
  showSubscribedSuccessMessage: PropTypes.func,
};

export default BottomBarStockAtNightEmailCapture;
