import useSearchParamOrDefaultProps from '../hooks/useSearchParamOrDefaultProps.js';
import styles from './ArticleTitle.module.css';
import PropTypes from 'prop-types';

const ArticleTitle = ({ children, fontColor }) => {
  const {
    articleTitle,
    articleTitleTextAlign: textAlign,
    articleTitleFontWeight: fontWeight,
    articleTitleFontColor,
    articleTitleFontFamily: fontFamily,
  } = useSearchParamOrDefaultProps();

  // In the event of an article title inside of another element with default font color props,
  // set the color value to the externally loaded fontColor prop, otherwise seek out the
  // articleTitleFontColor prop in the defaults or URL.
  // If neither exist, default to the value in the CSS.

  const color = fontColor || articleTitleFontColor;

  const style = {
    textAlign,
    fontWeight,
    color,
    fontFamily,
  };

  return (
    <article className={styles.articleTitleWrapper}>
      <div className={styles.articleTitle} style={style}>
        {children ? children : articleTitle}
      </div>
    </article>
  );
};

ArticleTitle.displayName = 'ArticleTitle';

ArticleTitle.propTypes = {
  children: PropTypes.node,
  fontColor: PropTypes.string,
};

ArticleTitle.defaultProps = {
  children: null,
  fontColor: '',
};

export default ArticleTitle;
